<template>
  <!----------------------//v1.0.1.4  Εμφάνιση στο ίδιο σημείο με το NewQuoteWizard.vue-------------------------->
  <div class="flex flex-col">
    <div class="flex flex-row p-4">
      <div class="flex-col w-3/4 h-full">
          <div class="px-2 py-2 divide-y divide-y-2 divide-[#d1182b]">
            <p class="px-2 mb-1 text-gray-900 font-semibold text-left"> 
            </p>
            <slot></slot>
            <!-- <div class="flex flex-col w-full max-h-screen overflow-y-scroll mt-8"> -->
            <!-- <span class="font-semibold text-2xl">Screening questions</span> --> 
          </div> 
      </div> 
    </div>
     
  </div>
</template>

<style scoped>
.tlabel {
  text-align: left;
  vertical-align: middle;
  font-weight: bolder;
}
.tvalue {
  text-align: right;
  vertical-align: middle;
}
/* table, th, td {
  border: 1px solid black;
} */
</style>

<script>
import apiClient from "@/apiClient";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import CustomCard from "@/components/CustomCard.vue";

export default {
  name: "StepOne",

  props: ["policyDetailsProp"],

  emits: ["onChange"],

  inject: ["kendoForm"],

  components: {
    "k-checkbox": Checkbox,
    "k-radiogroup": RadioGroup,
    "custom-card": CustomCard,
  },

  data() {
    return {
      questions: [],
      gettingQuestions: false,

      radioGroupItems: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
      rawHtml: `<br>`,
    };
  },

  async mounted() { 
    this.gettingQuestions = true;
 
  },

  computed: {
    // showQuestions() {
    //   return !this.gettingQuestions; //&& this.questions.length > 0
    // },
  },

  methods: {
    // async getQuestions() {
    //   const token = this.kendoForm.values.apiToken;
    //   const quote_id = this.$props.policyDetailsProp.quote_id;

    //   const questions = await apiClient.getScreeningQuestions(
    //     token,
    //     quote_id,
    //     "ASUA"
    //   );

    //   if (questions != null && Array.isArray(questions))
    //     this.questions = questions.map((x) => ({ ...x, accepted: false }));
    // },

    checkFormValidation() {
      if (this.questions.filter((x) => x.accepted == false).length > 0)
        //|| this.questions.length == 0)
        this.$emit("onChange", { stepValid: false });
      else this.$emit("onChange", { stepValid: true });
    },
  },
};
</script>
>
