<template>
    <div>
        <k-form-element :horizontal="true">
        <fieldset class="k-form-fieldset">
            <div class="flex flex-col content-center items-center justify-center">
                <div>
                    <span class="font-semibold">Yes/no questions</span>
                    <!-- 1st division -->

                    <table>
                        <tbody>
                            <tr v-for="question in booleanQuestions" v-bind:key="question.coverage_id">
                                <td style="width: 200px">
                                    {{question.DisplayName}}
                                </td>
                                <td style="width: 200px">
                                    <k-form-field
                                        :name="createBooleanName(question)"
                                        :component="'myTemplate'"
                                        :horizontal="true"
                                    >
                                        <template v-slot:myTemplate="{props}">
                                            <k-form-checkbox
                                                v-bind="props"
                                                @change="props.onChange"
                                                @blur="props.onBlur"
                                                @focus="props.onFocus"
                                            />
                                        </template>
                                    </k-form-field> 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <span class="font-semibold">Selection questions</span>
                    <!-- 1st division -->

                    <table>
                        <tbody>
                            <tr v-for="question in selectQuestions" v-bind:key="question.coverage_id">
                                <td style="width: 200px">
                                    {{question.DisplayName}}
                                </td>
                                <td style="width: 200px">
                                    <k-form-field
                                        :name="createOptionName(question.DisplayName)"
                                        :component="'myTemplate'"
                                        :horizontal="true"
                                        :data-items="question.ValidValues"
                                        :default-item="{Description: 'None', coverage_id: 0}"
                                        :text-field="'Description'"
                                        :data-item-key="'coverage_id'"
                                    >
                                        <template v-slot:myTemplate="{props}">
                                            <k-dropdown-list
                                                v-bind="props"
                                                @change="props.onChange"
                                                @blur="props.onBlur"
                                                @focus="props.onFocus"
                                            />
                                        </template>
                                    </k-form-field> 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- <div class="mt-8">
                    <span class="font-semibold">Selection  questions</span>
                    <div class="justify-center my-4 mb-8" v-for="question in selectQuestions" v-bind:key="question.DisplayName">
                        <k-form-field
                            :id="createOptionName(question.DisplayName)"
                            :name="createOptionName(question.DisplayName)"
                            
                            :question="question.DisplayName"
                            :data-items="question.radioButtonValues"
                            :layout="'horizontal'"
                            :component="'myTemplate'"
                            :validator="radioGroupValidator"
                        >
                            <template v-slot:myTemplate="{props}">
                                <div class="justify-center text-center">
                                    <span>{{props.question}}</span>
                                    <custom-radio-group
                                        v-bind="props"
                                        @change="props.onChange"
                                        @blur="props.onBlur"
                                        @focus="props.onFocus"
                                    />
                                </div>
                            </template>
                        </k-form-field>
                    </div>
                </div>                 -->


            </div>
        </fieldset>
        <!-- {{ kendoForm.values }} -->
    </k-form-element>
    </div>
</template>

<script>

import FormCheckbox from "./FormCheckbox.vue";
import { Field, FormElement  } from "@progress/kendo-vue-form";

import apiClient from "@/apiClient";
import CustomRadioGroup from "./CustomRadioGroup.vue"

import slugify from 'slugify'
import dummyExtras from "./dummyExtras";

import { DropDownList } from "@progress/kendo-vue-dropdowns";

export default {
    name: 'StepThree',

    components: {
        'k-form-element' : FormElement,
        'k-form-field' : Field,
        'k-dropdown-list' : DropDownList,
        'k-form-checkbox' : FormCheckbox,
        CustomRadioGroup
    },

    inject: {
        kendoForm: { default: {} },  
    },

    data: function() {
        return {
            questions: []
        }
    },

    computed: {
        booleanQuestions() {
            return this.questions.filter(x => x.CoverType == "Boolean")
        },

        selectQuestions() {
            let questions = this.questions.filter(x => x.CoverType == "Selection").map(function (item) {
                return {
                    ...item,
                    radioButtonValues: item.ValidValues.map(
                        function (value) {
                            return { label : value.Description, value : value.coverage_id }
                        }
                    )
                }
            })

            questions.forEach(function (item) {
                item.radioButtonValues.push({label: 'None', value: 0});
            })

            return questions
            
        },

        isStepValid: () => true
    },

    props: [
        'activeStepNumber',
        'myStepNumber',
        'lastChangedStepNumber'
    ],


    watch: {
        activeStepNumber: function(val) {
            if (val == this.myStepNumber) {
                this.stepActivated()
                this.onChange()
            }
        }
    },

    mounted() {
        // this.questions = dummyExtras;
    },

    methods: {
        async getAdditionalCoverages() {
            const additionalCoverages = await apiClient.getAdditionalCoverages(
                this.kendoForm.values.apiToken,
                "ASUA",
                this.kendoForm.values.travelStartDate,
                this.kendoForm.values.travelEndDate,
                this.kendoForm.values.residenceCountry.label,
                this.kendoForm.values.destination.value,
                this.kendoForm.values.travelCategory,
                this.kendoForm.values.travelType,  
              //v1.0.1.5 Προσθήκη insuredCategory value στην function getAdditionalCoverages()
                this.kendoForm.values.insuredCategory,
              //v1.0.1.5 Προσθήκη insuredCategory value στην function getAdditionalCoverages()
            )

            // console.log(this.kendoForm.values.travelCategory)
            this.questions = additionalCoverages
        },

        stepActivated() {
            
            if (this.lastChangedStepNumber < this.myStepNumber) {
                this.stepReset()
            }


            if (this.questions.length == 0)
                this.getAdditionalCoverages()
        },

        stepReset() {
            this.questions = []

            
            const fieldNames = Object.keys(this.kendoForm.values).filter(x => x.startsWith("extra_coverage_"))

            fieldNames.forEach(name => {
                delete this.kendoForm.values[name];
            })

        },

        onChange(stepActuallyChanged = true) {
            this.$emit('onChange', { stepNumber: this.myStepNumber, stepValid: this.isStepValid, stepActuallyChanged: stepActuallyChanged });
        },

        createOptionName(text) {
            return 'extra_coverage_selection.' + slugify(text, {
                lower : true,
                strict : true
            })
        },

        createBooleanName(question) {

            return `extra_coverage_boolean.${question.coverage_id}.` + slugify(question.DisplayName, {
                lower : true,
                strict : true
            })
        }


    }
}
</script>