<template>
  <!--------------------//v1.0.0.2 Aύξηση του width της Σύνοψης----------------------->
  <custom-card title="Σύνοψη" :extraClass="'w-1/4 h-full'">
    <!--------------------//v1.0.0.2 Aύξηση του width της Σύνοψης----------------------->
    <div :extraClass="'  h-full'">
      <table>
        <body>
          <!----------------------//v1.0.0.3  Φτιάξιμο του στησίματος των στοιχείων της καρτέλας Σύνοψη(στοίχηση) tailwind----------------------->
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Είδος κατοικίας</td>
            <td class="tvalue pt-2 w-1/2 h-full">
              {{ getValue("ResidenceType").value }}
            </td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Όροφος</td>
            <td class="tvalue pt-2 w-1/2 h-full">
              {{ getValue("Floor").value }}
            </td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Τύπος οροφής</td>
            <td class="tvalue pt-2 w-96 h-full">
              {{ getValue("roofType").value }}
            </td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Ποιότητα κατασκευής</td>
            <td class="tvalue pt-2 w-1/2 h-full">
              {{ getValue("cnstrctnData").value }}
            </td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Εμβαδόν</td>
            <td class="tvalue pt-2 w-1/2 h-full">{{ getValue("emvadon") }}</td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Ταχυδρομικός Κωδικός</td>
            <td class="tvalue pt-2 w-1/2 h-full">{{ getValue("ZipCode") }}</td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Έτος κατασκευής</td>
            <td class="tvalue pt-2 w-1/2 h-full">
              {{ getValue("ConstructionYear") }}
            </td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Μέτρα Ασφαλείας</td>
            <td class="tvalue pt-2 w-1/2 h-full">
              {{ getValue("MetraAsfaleias") }}
            </td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Ενυπόθηκο</td>
            <td class="tvalue pt-2 w-1/2 h-full">
              {{ getValue("Enypothiko") }}
            </td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Κατηγορία πακέτου</td>
            <td class="tvalue pt-2 w-1/2 h-full">
              {{ getValue("Periexomeno").value }}
            </td>
          </tr>

          <hr class="my-4" />
          <!-- <tr>
              <td class="tlabel px-2 pt-2">Αναχώριση</td>
              <td class="tvalue pt-2">{{ getValue("travelStartDate") }}</td>
            </tr>
            <tr>
              <td class="tlabel px-2 pt-2">Επιστροφή</td>
              <td class="tvalue pt-2">{{ getValue("travelEndDate") }}</td>
            </tr>
            <tr>
              <td class="tlabel px-2 pt-2">Κατοικία</td>
              <td class="tvalue pt-2">{{ getValue("residenceCountry") }}</td>
            </tr>
            <tr>
              <td class="tlabel px-2 pt-2">Προορισμός</td>
              <td class="tvalue w-96 pt-2">{{ getValue("destination") }}</td>
            </tr>

            <div v-if="ageBands.length > 0">
              <hr class="" />

              <tr>
                <td class="font-bold p-4" colspan="2">Ταξιδιώτες</td>
              </tr>

              <tr v-for="ageBand in ageBands" v-bind:key="ageBand">
                <td class="tlabel w-40 px-2 pt-2 shrink-0">{{ ageBand.name }}</td>
                <td class="tvalue w-96">{{ ageBand.people }}</td>
              </tr>
            </div>

            <div v-if="extraCoverages.length > 0">
              <hr class="my-4" />

              <tr>
                <td class="font-bold text-center p-4" colspan="2">Extras</td>
              </tr>

              <tr
                v-for="extraCoverage in extraCoverages"
                v-bind:key="extraCoverage"
              >
                <td class="tlabel w-40 py-2 shrink-0">
                  {{ extraCoverage.name }}
                </td>
                <td class="tvalue w-40 py-2">{{ extraCoverage.value }}</td>
              </tr>
            </div> -->
        </body>
      </table>
    </div>
  </custom-card>
  <!----------------------//v1.0.0.3  Φτιάξιμο του στησίματος των στοιχείων της καρτέλας Σύνοψη(στοίχηση) tailwind----------------------->
</template>

<style scoped>
/*--------------------//v1.0.0.3  Φτιάξιμο του στησίματος των στοιχείων της καρτέλας Σύνοψη(στοίχηση)----------------------->*/
.tlabel {
  text-align: left;
  vertical-align: top;
  font-weight: bolder;
}
.tvalue {
  text-align: right;
  vertical-align: top;
}

/*--------------------//v1.0.0.3  Φτιάξιμο του στησίματος των στοιχείων της καρτέλας Σύνοψη(στοίχηση)----------------------->*/
</style>

<script>
import CustomCard from "@/components/CustomCard.vue";

export default {
  name: "NewPropertyQuoteSummary",

  components: {
    CustomCard,
  },

  inject: ["kendoForm"],

  props: ["formValues"],

  methods: {
    getValue(name) {
      const val = this.kendoForm.values[name];

      if (val == null || val == "") return "-";

      //v1.0.0.4  NewQuoteSummary.vue : Αλλαγή στο format της ημερομηνίας
      if (val instanceof Date)
        return val.toLocaleDateString("el-GR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      //v1.0.0.4  NewQuoteSummary.vue : Αλλαγή στο format της ημερομηνίας

      if (val.value && val.label) return val.label;

      return val;
    },
  },

  computed: {},
};
</script>
