<template>
<div class="flex items-center h-screen p-4 justify-center" >
  <div class="h-[400px] flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md">
    <div class="px-4 pb-6 text-white bg-gray-200 md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly">
      <div class="mb-3 text-4xl font-bold tracking-wider text-center">
        <div><img src="kualogo.png"/></div>
        <!--
          <div class="flex flex-col">
          <img src="lloyds.png"/>
        </div>
        -->
      </div>
      <div class="bg-[#d1182b]">
        <p class="mt-6 font-normal text-left text-white md:mt-0 p-2">
          Για οποιαδήποτε διευκρίνηση σχετικά με την χρήση της εφαρμογής, παρακαλώ επικοινωνήστε μαζί μας στο <a href="mailto:it-support@gkaravias.gr">it-support@gkaravias.gr</a> ή στο 210 36 40 618. 
        </p>
        <!-- <p class="mt-6 text-sm text-center text-white">
          Read our <a href="#" class="underline">terms< /a> and <a href="#" class="underline">conditions</a>
        </p> -->
      </div>
    </div>
    <div class="p-5 bg-gray-100 md:flex-1">
      <h3 class="mt-8 mb-4 text-2xl font-semibold text-gray-700 px-24">KUA Portal Login</h3>

      <!-- email / password prompt -->

      <div v-if="waitingFor2FA == false" action="#" class="flex flex-col space-y-5">
        <div class="flex flex-col space-y-1">
          <div class="flex items-center bg-white rounded shadow-md mb-4">
            <span class="px-3">
              <!-- <svg class="fill-current text-gray-500 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z"/></svg> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/><circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/></svg>
            </span>
            <input class="w-full h-12 focus:outline-none" type="text" placeholder="Username" v-model="username">
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex items-center bg-white rounded shadow-md mb-4">
            <span class="px-3">
              <!-- <svg class="fill-current text-gray-500 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M4 8V6a6 6 0 1 1 12 0h-3v2h4a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"/></svg> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 9.9-1"></path></svg>
            </span>
            
            <!-- //v1.0.4.4  Δυνατότητα να συνδεθεί με την χρήση του πλήκτρου Enter -->
            <input v-if="showPassword" class="w-full h-12 focus:outline-none" :type="passwordField" placeholder="Password" v-model="password" @keyup.enter="doLogin">
            <input v-else class="w-full h-12 focus:outline-none" :type="passwordField" placeholder="Password" v-model="password" @keyup.enter="doLogin">
            <!-- <button v-if="passwordField == 'password'" class="bg-red-500 hover:bg-red-700 text-white font-semibold hover:text-white py-2 px-4 border  hover:border-transparent rounded" type="password" @click="switchVisibility">
              <span class="k-icon k-i-eye "></span></button> -->
              <button v-if="passwordField == 'password'" class="py-2 px-4" type="password" @click="switchVisibility">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>
</button>
          <button v-else class="py-2 px-4" type="password" @click="switchVisibility"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
  </svg>
  </button>

          </div>
        </div>
        <!--
        <div class="flex items-center space-x-2">
          <input
            type="checkbox"
            id="remember"
            class="w-4 h-4 transition duration-300 rounded focus:ring-2 focus:ring-offset-0 focus:outline-none focus:ring-blue-200"
            v-model="rememberMe"
          />
          <label for="remember" class="text-sm font-semibold text-gray-500">Remember me</label>
        </div>
        -->
        <div>
          <!-- <button
            class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-red-500 rounded-md shadow hover:bg-red-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
            @click="doLogin"
            v-bind:disabled="loginButtonDisabled">
            Σύνδεση
          </button> -->

          <spinner-button 
            ref="loginButton"
            buttonClass="w-60 px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-[#d1182b] rounded-md shadow hover:bg-red-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
            idleText="Σύνδεση"
            @click="doLogin"
            v-bind:disabled="loginButtonDisabled"/>
        </div>
        
        <router-link to="/forgot" class="text-sm text-red-600 hover:underline focus:text-red-800">Επαναφορά κωδικού</router-link>
        <!-- <router-link to="/register" class="text-sm text-red-600 hover:underline focus:text-red-800">Νέος χρήστης</router-link> -->
        <!--
        <p class="flex flex-col items-center justify-center mt-10 text-center">
          <span>Don't have an account?</span>
          <a href="#" class="underline">Contact us!</a>
        </p>
       -->
      </div>

      <!-- 2FA prompt -->

      <div v-if="waitingFor2FA == true" action="#" class="flex flex-col space-y-5">
        
        <div class="flex flex-col space-y-1">
          <p class="flex flex-col items-center justify-center my-4 text-center text-bold">Εισάγετε τον κωδικό που δείχνει η εφαρμογή authenticator σας</p>  
          <p class="flex flex-col items-center justify-center mt-20 text-center">Δεν χρησιμοποιείτε authenticator?   
          <ul>
            <li>
                <svg v-if="mfaRequested && !mfaSent" class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <svg v-if="mfaSent" class="inline mr-2 w-4 h-4 text-gray-200 dark:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#d1182b" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                  <polyline points="20 6 9 17 4 12">

                  </polyline>
                </svg>
              <router-link v-if="!mfaSent" to="/request-otp-mail" class="text-sm text-red-600" :class="{ 'line-through' : mfaSent, 'hover:underline focus:text-red-800' : !mfaSent }" @click.prevent="requestMfa('email')"> 
                Αποστολή PIN μέσω <span class="font-bold">email</span> 
              </router-link>

              <span v-else class="text-sm text-red-600">Το PIN έχει αποσταλεί με email</span>
            </li>
          </ul>
          </p>
          <!--
          <div class="flex items-center justify-center mt-10 text-center">
            <button class="w-24 mx-4 px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-red-500 rounded-md shadow hover:bg-red-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
                    @click="requestMfa('email')"
                    v-bind:disabled="mfaEmailButtonDisabled">
              Email
            </button>
            <button class="w-24 mx-4 px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-red-300 rounded-md shadow" disabled>
              SMS
            </button>
          </div>
        -->

        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex items-center bg-white rounded shadow-md mb-4">
            <span class="px-3">
              <svg class="fill-current text-gray-500 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M4 8V6a6 6 0 1 1 12 0h-3v2h4a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"/></svg>
            </span>
            <!-- //v1.0.4.4  Δυνατότητα να συνδεθεί με την χρήση του πλήκτρου Enter -->
            <input class="w-full h-12 focus:outline-none" type="text" :placeholder="mfaCodePlaceholder" v-model="mfaCode" @keyup.enter="submit2fa">
          </div>
        </div>
        
        <div>
          <!-- <button class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-red-500 rounded-md shadow hover:bg-red-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
            @click="submit2fa"
            v-bind:disabled="submit2faButtonDisabled">
            Είσοδος
          </button> -->

          <spinner-button
            ref="submitMfaButton"
            buttonClass="w-60 px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-red-500 rounded-md shadow hover:bg-red-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
            idleText="Είσοδος"
            @click="submit2fa"
            v-bind:disabled="submit2faButtonDisabled"
          />
        </div>
        
        <!--
        <p class="flex flex-col items-center justify-center mt-10 text-center">
          <span>Having an issue?</span>
        <a href="#" class="underline">Contact us!</a>
        
          </p>
      -->
      </div>
      
    </div>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import SpinnerButton from './SpinnerButton.vue';
 

export default {
  name: 'LoginForm',
  inject:['$appConfig'],

  components: {
    SpinnerButton,
  
},

  data() {
    return {
      loginButtonDisabled: false,
      mfaEmailButtonDisabled: false,
      submit2faButtonDisabled: false,
      waitingFor2FA: false,
      username: null,
      password: null,
      mfaCode: null,
      mfaEmail : null,
      mfaTarget: null,
      mfaRequested: null,
      mfaSent: null,
      loginToken: null,
      rememberMe: false,
      mfaCodePlaceholder: "Enter your PIN",
      passwordField: "password",
    }
  },

  methods: {
    switchVisibility(){
      this.passwordField = this.passwordField === "password" ? "text" : "password";
    },
    ...mapActions(['logIn']),

    async doLogin() {
      if (this.canLogin == false){
        return
      }



      //console.log(`login with username ${this.email} and password ${this.password}`)

      //this.loginButtonDisabled = true;

      this.$refs.loginButton.setStateActive();

      let url = this.checkCredentialsUrl.href
      
      let body = {
        username: this.username,
        password: this.password
      }


      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      };

      try {
        const response = await fetch(url, requestOptions);
        this.$refs.loginButton.setStateIdle();
        //const data = await response.json();
        if (response.status === 200) {
          this.waitingFor2FA = true;
        }
        else {
          this.$swal.fire('Σφάλμα', 'Τα στοιχεία εισόδου δεν είναι σωστά.', 'error');
        }
      } catch (error) {
        this.$refs.loginButton.setStateIdle();
        console.log(error)
      }
      
      
      this.loginButtonDisabled = false;
    },

    async requestMfa(target) {

      if (this.mfaSent == true)
        return;

      let url = this.requestOtpMailUrl.href
      
      let body = {
        username: this.username,
        password: this.password
      }

      this.mfaSent = false;
      this.mfaRequested = true;

      switch (target) {
        case 'email':
          this.mfaEmailButtonDisabled = true;

          setTimeout(() => {this.mfaEmailButtonDisabled = false; }, "5000")

          // this.mfaTarget = this.mfaEmail;
          // body.type = "email"
          break;

        case 'phone':
          break;

        default:

      }
      
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      };

      try {
        const response = await fetch(url, requestOptions);

        
        
        if (response.status != 200) {
          this.mfaEmailButtonDisabled = false;
          this.mfaRequested = false;
          this.$swal.fire('Σφάλμα', 'Δεν ήταν δυνατή η αποστολή του email', 'error');
          
          return;
        }

      
        // this.mfaCodePlaceholder = data;
        // this.mfaCode = String(data);


        this.mfaSent = true        
      } catch (error) {
        this.$swal.fire('Σφάλμα', 'Server error', 'error');
      }

    },

    async submit2fa() {
      

      let url = this.loginUrl.href
      let body = {
        username: this.username,
        password: this.password,
        otp: this.mfaCode
      }


      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      };

      
      try {
        this.submit2faButtonDisabled = true;

        this.$refs.submitMfaButton.setStateActive();
       
        const response = await fetch(url, requestOptions);
        
        const data = await response.json();
        
        this.submit2faButtonDisabled = false;
        this.$refs.submitMfaButton.setStateIdle();
        
       
        if (response.status === 200) {
          this.$swal.fire({
              title: 'Επιτυχία',
              text: `Για λόγους ασφαλείας, μετά από ${ Math.round(this.$appConfig.UI_TIMEOUT_SECONDS / 60)} λεπτά θα αποσυνδεθείτε αυτόματα.`, 
              icon: 'success'
          }).then(response => {
            this.logIn({ 
              user: this.username, 
              token : data.accessToken,
              userFullName : data.user.fullName,
              userAgentCode: data.user.activeAgentCode,
              passwordChangeRequired: data.user.passwordChangeRequired,
              permaStorage : this.rememberMe ? true : false,
              timeoutSeconds : this.$appConfig.UI_TIMEOUT_SECONDS
            })
             
            
          });
        }
        else {
          this.$swal.fire('Σφάλμα', 'Ο κωδικός δεν είναι σωστός', 'error');
        }
      } catch (error) {
        this.$refs.submitMfaButton.setStateIdle();
        
        
        console.log(error)
      }
    }
  },

  computed: {
    canLogin () {
      return (false == (this.username == null || this.username.length == 0 || this.password == null || this.password.length == 0))
    },

    checkCredentialsUrl() {
      return new URL(this.$appConfig.API_CHECK_CREDENTIALS_URL, this.$appConfig.API_BASE_URL)
    },

    requestOtpMailUrl() {
      return new URL(this.$appConfig.API_REQUEST_OTP_MAIL_URL, this.$appConfig.API_BASE_URL)
    },

    loginUrl() {
      return new URL(this.$appConfig.API_LOGIN_URL, this.$appConfig.API_BASE_URL)
    },

    request2faUrl() {
      return new URL(this.$appConfig.API_REQUEST_2FA_URL, this.$appConfig.API_BASE_URL)
    },

    verify2faUrl() {
      return new URL(this.$appConfig.API_VERIFY_2FA_URL, this.$appConfig.API_BASE_URL)
    },
    
  },
}
</script>