<template>
  <k-form-element @change="onChange">
    <fieldset class="k-form-fieldset">
      <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->
      <div class="flex flex-col justify-center">
        <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->

        <!-- 1st row -->
        <!-- <div class="flex flex-row justify-center my-4">
          <div class="flex flex-col justify-center text-center mx-10">
            <div class="font-semibold">Ασφαλιστική εταιρία</div>
            
              <div class="my-4">
                <div class="my-2">
                <k-form-field
                  :id="'insuranceCompany'"
                  :name="'insuranceCompany'"
                  :data-items="insuranceCompanies"
                  :layout="'horizontal'"
                  :component="'myTemplate'"
                  :validator="radioGroupValidator"
                >
                  <template v-slot:myTemplate="{ props }">
                    <k-form-radiogroup
                      v-bind="props"
                      @change="props.onChange"
                      @blur="props.onBlur"
                      @focus="props.onFocus"
                    />
                  </template>
                </k-form-field>
              </div>
              </div>
            
          </div>

          <div class="flex flex-col justify-center text-center"> -->
        <!-- <div class="flex flex-row justify-center"> -->

        <!-- 1st row -->
        <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->
        <div class="flex flex-row justify-center my-4">
          <div class="flex flex-col justify-center text-center mx-10"></div>
          <div class="flex flex-col justify-center text-center mx-10">
            <div class="font-semibold mt-0">Ασφαλιστική Εταιρία</div>
            <div class="my-2">
              <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->
              <k-form-field
                :id="'insuranceCompany'"
                :name="'insuranceCompany'"
                :data-items="insuranceCompanies"
                :layout="'horizontal'"
                :component="'myTemplate'"
                :validator="radioGroupValidator"
              >
                <template v-slot:myTemplate="{ props }">
                  <k-form-radiogroup
                    v-bind="props"
                    @change="props.onChange"
                    @blur="props.onBlur"
                    @focus="props.onFocus"
                  />
                </template>
              </k-form-field>
              <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->
            </div>
          </div>
          <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->

          <!-- <select class="w-3 h-3" placeholder="ειδος " v-bind="null">
            <option v-for="item in value" :value="item">{{ item }}</option>
          </select> -->

          <!-- //v1.0.0.5	StepOne.vue : Προσθήκη στοιχείου Κατηγορία Ταξιδιωτών και dropdown menu -->
          <div class="flex flex-col justify-center text-center mx-10">
            <div class="font-semibold pb-4">Κατηγορία Ταξιδιωτών</div>
            <div class="my-2 flex flex-row">
              <k-form-field
                :id="'insuredCategory'"
                :name="'insuredCategory'"
                :data-items="insTest"
                :layout="'horizontal'"
                :component="'myTemplate'"
                :validator="requiredValidator"
              >
                <template v-slot:myTemplate="{ props }">
                  <k-dropdownlist
                    :style="{ width: '230px' }"
                    v-bind="props"
                    @change="onChange($event)"
                  />
                </template>
              </k-form-field>
              <!-- //v1.0.0.5	StepOne.vue : Προσθήκη στοιχείου Κατηγορία Ταξιδιωτών και dropdown menu -->

              <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών -->
              <div class="py-1 pl-1">
                <a class="btn" cursor @click="toggleDialog">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                  </svg>
                </a>
              </div>
              <div>
                <k-dialog
                  v-if="visibleDialog"
                  :title="'Κλείσιμο'"
                  @close="toggleDialog"
                >
                  <table id="categ" class="">
                    <tr>
                      <th>Insured Category</th>
                      <th>Description</th>
                      <th>Available for packages</th>
                    </tr>
                    <tr>
                      <td>Individual</td>
                      <td>
                        An insured person (either an adult or a child) who is
                        named on the validation certificate.
                      </td>
                      <td>All packages</td>
                    </tr>
                    <tr>
                      <td>Couple (If applicable)</td>
                      <td>
                        Two adults who have been permanently living together for
                        at least six months and reside at the same address.
                      </td>
                      <td>
                        Essential Annual Multi-Trip<br />
                        Gold Annual Multi-Trip<br />
                        Gold Single Trip<br />
                        Silver Annual Multi-Trip Silver Single Trip
                      </td>
                    </tr>
                    <tr>
                      <td>Family</td>
                      <td>
                        Two adults plus up to 5 of their dependent children
                        under 18 years of age.
                      </td>
                      <td>All packages</td>
                    </tr>
                    <tr>
                      <td>Single Parent Family</td>
                      <td>
                        (If applicable) One adult plus up to 5 of their
                        dependent children under 18 years of age.
                      </td>
                      <td>
                        Essential Annual Multi-Trip Essential Single Trip<br />
                        Gold Single Trip<br />
                        Long Stay Standard<br />
                        Long Stay Study Abroad<br />
                        Silver Single Trip Value Single Trip
                      </td>
                    </tr>
                  </table>
                </k-dialog>
              </div>
              <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών -->
              <!-- <span v-else>Please select a travel category first</span> -->
            </div>
          </div>
        </div>

        <!--2nd row -->

        <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->
        <div class="flex flex-row justify-center mr-28">
          <div class="flex flex-col justify-start text-center">
            <div class="font-semibold">Είδος ταξιδιού</div>
            <div class="my-2">
              <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->
              <k-form-field
                :id="'travelCategory'"
                :name="'travelCategory'"
                :data-items="travelCategories"
                :layout="'horizontal'"
                :component="'myTemplate'"
                :validator="radioGroupValidator"
                @change="travelCategoryChanged"
              >
                <template v-slot:myTemplate="{ props }">
                  <k-form-radiogroup
                    v-bind="props"
                    @change="props.onChange"
                    @blur="props.onBlur"
                    @focus="props.onFocus"
                  />
                </template>
              </k-form-field>
              <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->
            </div>
          </div>
          <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->

          <!-- <select class="w-3 h-3" placeholder="ειδος " v-bind="null">
            <option v-for="item in value" :value="item">{{ item }}</option>
          </select> -->

          <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->
          <div class="flex flex-col justify-center text-center ml-28">
            <div class="font-semibold">Τύπος ταξιδιού</div>
            <div class="my-2">
              <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->
              <k-form-field
                v-if="possibleTravelTypes.length > 0"
                :id="'travelType'"
                :name="'travelType'"
                :data-items="possibleTravelTypes"
                :layout="'horizontal'"
                :component="'myTemplate'"
                :validator="radioGroupValidator"
              >
                <template v-slot:myTemplate="{ props }">
                  <k-form-radiogroup
                    v-bind="props"
                    @change="props.onChange"
                    @blur="props.onBlur"
                    @focus="props.onFocus"
                  />
                </template>
              </k-form-field>

              <!-- <span v-else>Please select a travel category first</span> -->

              <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->
            </div>
          </div>
        </div>
        <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->

        <!-- 3rd row -->
        <!-- <div class="flex flex-row justify-center my-4">
                    <div class="flex flex-col justify-center text-center">
                        <div class="font-semibold">Τύπος ταξιδιού</div>
                        <div class="my-4">
                            <div class="my-2">
                                <k-form-field
                                    v-if="possibleTravelTypes.length > 0"
                                    :id="'travelType'"
                                    :name="'travelType'"
                                    
                                    :data-items="possibleTravelTypes"
                                    :layout="'horizontal'"
                                    :component="'myTemplate'"
                                    :validator="radioGroupValidator"
                                >
                                    <template v-slot:myTemplate="{props}">     
                                        <k-form-radiogroup
                                            v-bind="props"
                                            @change="props.onChange"
                                            @blur="props.onBlur"
                                            @focus="props.onFocus"
                                        />
                                    </template>
                                </k-form-field>
                                <span v-else>Please select a travel category first</span>
                            </div>
                        </div>
                    </div>
                    
                </div> -->

        <div v-if="showAnyDisclaimer" class="flex flex-row justify-center my-4">
          <div class="flex flex-col justify-center text-center">
            <div class="font-semibold">DISCLAIMER</div>
            <div class="my-4">
              <div v-if="showBusinessTravelDisclaimer" class="my-2">
                <ul>
                  <li>
                    The coverage concerns only non-manual work (white collars)
                    which takes place exclusively and only in an office. Any
                    work with heavy tools or power tools, any work includes
                    chemical, toxic and any form of flammable liquids or gases,
                    or is related with flame, any work with sharp objects, any
                    work in construction site, shipyards, cargo holdings and
                    ship’s sheaths is excluded absolutely.
                  </li>
                  <li>
                    Any trip must not exceed 6 months (182 days) continuous
                    duration.
                  </li>
                </ul>
              </div>

              <div v-if="showLeisureTravelDisclaimer" class="my-2">
                <ul>
                  <li>
                    The coverage applies exclusively to and from the
                    destination, touring while staying at the country you are
                    visiting.
                  </li>
                  <li>Any activity during the trip is excluded.</li>
                </ul>
              </div>
            </div>
            <div>
              <k-checkbox
                v-model="disclaimerAcknowledged"
                @change="onChange"
                label="I understand and accept this disclaimer"
              />
            </div>
          </div>
        </div>
        <!-- <span>{{this.insuredCategory.label}}</span> -->
        <!-- <span>{{kendoForm.values.insuredCategory.label}}</span> -->
        <!-- <span>{{kendoForm.values}}</span>  -->

        <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->
      </div>
      <!-----//v1.0.2.7 Στοίχιση Ασφαλιστική Εταιρία, Κατηγορία Ταξιδιωτών, Είδος ταξιδιού, Τύπος Ταξιδιού----->
    </fieldset>
  </k-form-element>
</template>

<style scoped>
.supagrid {
  /* Define the element as a grid container */
  display: grid;
  /* Auto-fit as many items on a row as possible without going under 180px */
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  /* A little spacing between articles */
  grid-gap: 1em;
}

/* <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών --> */

.popup-content {
  padding: 30px;
  color: #787878;
  background-color: #fcf7f8;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

#categ {
  font-family: Arial, Helvetica, sans-serif;
  border: 2px solid white;
  width: 100%;
}

#categ td,
#customers th {
  border: 2px solid white;
  padding: 8px;
  text-align: left;
}

#categ tr:nth-child(even) {
  background-color: #f8b8aa;
}
#categ tr:nth-child(odd) {
  background-color: #ffded7;
}

/* .k-dialog {
  background-color: red;
  color: black;
}
.myClass kendo-dialog-titlebar{
        background-color: red;
} */

#categ th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ff6358;
  color: white;
  border: 2px solid white;
}

/* <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών --> */
</style>

<script>
import FormRadioGroup from "./FormRadioGroup.vue";
import { Field, FormElement } from "@progress/kendo-vue-form";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { radioGroupValidator } from "./validators";

//v1.0.0.5	StepOne.vue : Προσθήκη στοιχείου Κατηγορία Ταξιδιωτών και dropdown menu
import { DropDownList } from "@progress/kendo-vue-dropdowns";
//v1.0.0.5	StepOne.vue : Προσθήκη στοιχείου Κατηγορία Ταξιδιωτών και dropdown menu

/* <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών --> */
import { Popup } from "@progress/kendo-vue-popup";
import { Button } from "@progress/kendo-vue-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
/* <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών --> */

export default {
  name: "StepOne",

  props: {
    show: Boolean,
  },
  components: {
    "k-form-radiogroup": FormRadioGroup,
    "k-form-element": FormElement,
    "k-form-field": Field,
    "k-checkbox": Checkbox,

    //v1.0.0.5	StepOne.vue : Προσθήκη στοιχείου Κατηγορία Ταξιδιωτών και dropdown menu
    "k-dropdownlist": DropDownList,
    //v1.0.0.5	StepOne.vue : Προσθήκη στοιχείου Κατηγορία Ταξιδιωτών και dropdown menu

    /* <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών --> */
    Popup: Popup,
    kbutton: Button,
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    /* <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών --> */
  },

  inject: {
    kendoForm: { default: {} },
  },

  data: function () {
    return {
      /* <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών --> */
      visibleDialog: false,
      /* <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών --> */

      radioGroupValidator,
      disclaimerAcknowledged: false,
      show: false,
      insuranceCompanies: [
        {
          disabled: true,
          label: "LLOYD's",
          value: "lloyds",
        },
        {
          label: "Collinson",
          value: "collinson",
        },
      ],
      //v1.0.0.10 StepOne.vue: Προσθήκη insTest array για dropDown List

      insTest: ["Individual", "Couple", "Family", "Single Parent Family"],

      //v1.0.0.10 StepOne.vue: Προσθήκη insTest array για dropDown List

      travelCategories: [
        {
          label: "Business",
          value: "Business",
        },
        {
          label: "Leisure",
          value: "Leisure",
        },
        {
          label: "Long stay",
          value: "Long Stay",
        },
      ],

      possibleTravelTypesPerCategory: [
        {
          travelCategory: "Business",
          types: [
            { label: "Annual", value: "Annual" },
            { label: "Single", value: "Single" },
          ],
        },

        {
          travelCategory: "Leisure",
          types: [
            { label: "Annual", value: "Annual" },
            { label: "Single", value: "Single" },
          ],
        },

        {
          travelCategory: "Long Stay",
          types: [
            { label: "Standard", value: "Standard" },
            { label: "Study", value: "Study" },
          ],
        },
      ],

      possibleTravelTypes: [],

      minimumTravelEndDate: new Date(),
      maximumTravelStartDate: new Date(),
    };
  },

  computed: {
    buttonText: function () {
      return this.show ? "Hide" : "Show";
    },

    showBusinessTravelDisclaimer() {
      return this.kendoForm.values.travelCategory == "Business";
    },

    showLeisureTravelDisclaimer() {
      return this.kendoForm.values.travelCategory == "Leisure";
    },

    showAnyDisclaimer() {
      return (
        this.showBusinessTravelDisclaimer || this.showLeisureTravelDisclaimer
      );
    },

    isStepValid() {
      const disclaimerOk =
        this.showAnyDisclaimer == false || this.disclaimerAcknowledged == true;

      // console.log(`disclaimer status: ${disclaimerOk}`)
      // console.log(`disclaimer status: ${disclaimerOk}`)

      // console.log(`disclaimer status: ${disclaimerOk}`)

      const errors = this.kendoForm.errors;
      const values = this.kendoForm.values;

      // console.log(`category: ${values.travelCategory} -- type ${values.travelType}`)

      return (
        errors.insuranceCompany === "" &&
        errors.travelCategory === "" &&
        errors.travelType === "" &&
        values.travelCategory !== "" &&
        values.travelCategory != null &&
        values.travelType !== "" &&
        values.travelType != null &&
        //v1.0.0.6  StepOne.vue : Να μην περνάει στο επόμενο αν δεν εχουν επιλεχθει όλα τα στοιχεία
        values.insuredCategory != null &&
        //v1.0.0.6  StepOne.vue : Να μην περνάει στο επόμενο αν δεν εχουν επιλεχθει όλα τα στοιχεία

        disclaimerOk
      );
    },

    today() {
      return new Date();
    },

    // possibleTravelTypes() {
    //     return
    // }
  },

  mounted: function () {
    this.show = true;
    // console.log("step one mounted");
    this.$emit("onChange", { stepValid: this.isStepValid });
  },

  props: ["activeStepNumber", "myStepNumber", "lastChangedStepNumber"],

  watch: {
    activeStepNumber: function (val) {
      if (val == this.myStepNumber) {
        this.stepActivated();
        this.onChange(false);
      }
    },
  },

  emits: ["onChange"],

  methods: {
    /* <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών --> */

    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },
    /* <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών --> */

    getValue(name) {
      const val = this.kendoForm.values[name];

      if (val.value && val.label) return val.label;

      return val;
    },
    checkForVatInput(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    travelCategoryChanged(evt) {
      let travelCategory = evt.value;
      // console.log(travelCategory);
      switch (travelCategory) {
        case "Business":
          this.possibleTravelTypes = this.possibleTravelTypesPerCategory.find(
            (x) => x.travelCategory == "Business"
          ).types;
          break;

        case "Leisure":
          this.possibleTravelTypes = this.possibleTravelTypesPerCategory.find(
            (x) => x.travelCategory == "Leisure"
          ).types;
          break;

        case "Long Stay":
          this.possibleTravelTypes = this.possibleTravelTypesPerCategory.find(
            (x) => x.travelCategory == "Long Stay"
          ).types;
          break;

        default:
          this.possibleTravelTypes = [];
      }

      this.kendoForm.values.travelType = null;
      this.disclaimerAcknowledged = false;
    },

    async travelStartDateChanged(evt) {
      // console.log(evt.value)
      this.minimumTravelEndDate = evt.value;
    },

    async travelEndDateChanged(evt) {
      // console.log(evt.value)
      this.maximumTravelStartDate = evt.value;
    },

    stepActivated() {},

    onChange(stepActuallyChanged = true) {
      // console.log('firing onchange from #' + this.myStepNumber + ' with ' + this.isStepValid)
      this.$emit("onChange", {
        stepNumber: this.myStepNumber,
        stepValid: this.isStepValid,
        stepActuallyChanged: stepActuallyChanged,
      });
    },
  },
};
</script>
