<template>
  <div v-if="test == false" class="grid grid-cols-6 place-items-start">
    <!-- Grid Container -->

    <label class="MainForm_Label">Όνομα</label>
    <k-input v-model="customer.name" class="MainForm_Field" />

    <label class="MainForm_Label">Επώνυμο</label>
    <k-input v-model="customer.surname" class="MainForm_Field" />

    <label class="MainForm_Label">Όνομα πατρός</label>
    <k-input v-model="customer.fathersname" class="MainForm_Field" />

    <label class="MainForm_Label">Α.Φ.Μ.</label>
    <k-input
      v-model="customer.vatNumber"
      @keydown="verifyNumericInput"
      class="MainForm_Field"
    />

    <label class="MainForm_Label">E-Mail</label>
    <k-input v-model="customer.email" class="MainForm_Field" />

    <label class="MainForm_Label">Αριθμός κιν. τηλεφώνου</label>
    <k-input v-model="customer.phone" class="MainForm_Field" />

    <label class="MainForm_Label">Οδός</label>
    <k-input v-model="customer.address" class="MainForm_Field" />
    <label class="MainForm_Label">Αριθμός</label>
    <k-input v-model="customer.addressNumber" class="MainForm_Field" />

    <label class="MainForm_Label">Ταχ. Κώδικας</label>
    <k-input
      v-model="customer.postalCode"
      class="MainForm_Field"
      :disabled="true"
    />
    <label v-if="this.policyDetails.Enypothiko == 'Ναι'" class="MainForm_Label"
      >Τράπεζα</label
    >
    <k-dropdownlist
      v-if="this.policyDetails.Enypothiko == 'Ναι'"
      v-model="customer.bank"
      :default-item="'Παρακαλώ επιλέξτε τράπεζα'"
      class="MainForm_Field"
      :data-items="banks"
    />
    <label class="MainForm_Label">Ημερομηνία έναρξης συμβολαίου</label>
    <k-date-picker
      class="MainForm_Field"
      :format="'dd/MM/yyyy'"
      :default-Show="false"
      v-model="customer.contractstartdate"
    >
    </k-date-picker>
  </div>
  <!-- <span v-show="test" v-bind="cid"></span> -->
  <!--  
          <div class="flex flex-col justify-center content-center text-center container  ">
            <div class="flex flex-row mt-2"> 
              <k-input v-model="customer.name" class="w-60" label="Όνομα" /> 
            </div>
            <div class="flex flex-row mt-2"> 
              <k-input v-model="customer.surname" class="w-60" label="Επώνυμο" /> 
            </div>
            <div class="flex flex-row mt-2"> 
              <k-input v-model="customer.fathersname" class="w-60" label="Όνομα πατρός" /> 
            </div>
            <div class="flex flex-row mt-2"> 
              <k-input v-model="customer.vatNumber" class="w-60" label="Α.Φ.Μ." /> 
            </div> 
            <div class="flex flex-row mt-2">
              <k-input v-model="customer.email" class="w-60" label="E-Mail" />
            </div> 
            <div class="flex flex-row mt-2">
              <k-input v-model="customer.telephone" class="w-60"/>
            </div> 
            <div class="flex flex-row mt-2">
              <k-input v-model="customer.address" class="w-60" />
            </div> 
            <div class="flex flex-row mt-2">
              <k-input v-model="customer.postalCode" class="w-60" :disabled="true" />
            </div>
          </div> 
      </div> -->

  <div class="w-full text-center content-center justify-center">
    <br />
    <span class="k-form-separator mt-8"></span>
    <div class="flex flex-col k-form-buttons">
      <span v-if="test == false">
        <spinner-button
          ref="sendRequestButton"
          buttonClass="w-120 px-2 py-1 text-white transition-colors duration-300 bg-[#d1182b] enabled:bg-[#d1182b] rounded-md shadow enabled:hover:bg-red-500 focus:outline-none"
          idleText="Αποστολή ειδοποίησης πληρωμής"
          @idleClick="submitButtonClick"
          :disabled="disableSubmitButton"
        />
      </span>
      <span v-if="test == true">
        <spinner-button
          @click="getPdf"
          ref="sendRequestButton"
          buttonClass="w-120 px-2 py-1 text-white transition-colors duration-300 bg-[#d1182b] enabled:bg-[#d1182b] rounded-md shadow enabled:hover:bg-red-500 focus:outline-none"
          idleText="Λήψη Συμβολαιου"
        />
        <p v-show="false">{{ this.cid }}</p>
      </span>
    </div>
  </div>
</template>

<!--//v1.0.3.7  Aλλαγές στη μορφοποίηση της σύνοψης-->
<style scoped>
.tlabel {
  text-align: left;
  vertical-align: middle;
  font-weight: bolder;
}
.tvalue {
  text-align: right;
  vertical-align: middle;
}
.MainForm_Label {
  grid-column-start: 3;
  font-weight: 600;
  color: #718096;
  margin-top: 0.5rem;
  padding: 0px;
}
.MainForm_Field {
  grid-column-end: 5;
  width: 15rem;
}
</style>

<!--//v1.0.3.7  Aλλαγές στη μορφοποίηση της σύνοψης-->

<script>
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import CustomCard from "@/components/CustomCard.vue";
import { Button } from "@progress/kendo-vue-buttons";
import SpinnerButton from "@/components/SpinnerButton.vue";
import { provide, ref } from "vue";

export default {
  name: "NewPolicyRequestForm",

  components: {
    "k-drop-down-list": DropDownList,
    "k-input": Input,
    "k-date-picker": DatePicker,
    "k-button": Button,
    CustomCard,
    SpinnerButton,
    "k-dropdownlist": DropDownList,
  },

  inject: ["$appConfig", "kendoForm"],

  props: ["policyDetailsProp"],

  data: function () {
    return {
      value: null,
      legalForms: ["Φυσικό Πρόσωπο", "Εταιρεία"],
      test: false,
      policyDetails: {
        frb_initial_quotes: null,
        package_name: null,
        total_premium: null,
      },
      banks: [
        "Εθνική Τράπεζα",
        "Τράπεζα Πειραιώς",
        "Alpha Bank",
        "Eurobank",
        "Attica Bank",
        "Παγκρήτια Τράπεζα",
        "Συνεταιριστική Τράπεζα Θεσσαλίας",
        "Συνεταιριστική Τράπεζα Καρδίτσας",
        "Συνεταιριστική Τράπεζα Ηπείρου",
        "Συνεταιριστική Τράπεζα Χανίων",
        "Optima Bank",
        "Aegean Baltic Bank",
        "CITIBANK",
        "HSBC",
        "Τράπεζα της Ελλάδος",
        "Άλλο",
      ],
      customer: {
        // legalForm: null,
        name: null,
        surname: null,
        fathersname: null,
        vatNumber: null,
        email: null,
        phone: null,
        address: null,
        addressNumber: null,
        contractstartdate: null,
        bank: null,
        postalCode: this.$props.policyDetailsProp.ZipCode.label,
      },
      cid: null,
      filename: null,
    };
  },

  mounted() {
    //  console.log(this.policyDetailsProp);

    this.policyDetails = this.$props.policyDetailsProp;
  },

  methods: {
    getPdf() {
      const apiUpdatePayload = {
        username: "elipatr",
        password: "111111",
        finalized_quoteID: this.cid,
      };
      const targetUrl = this.requestPolicyPdfUrl.href;
      this.axios
        .post(targetUrl, apiUpdatePayload)
        .then((response) => {
          this.$refs.sendRequestButton.setStateIdle();
          if (response.data.ErrorCode)
            this.$swal.fire("Σφάλμα", response.data.ErrorDescription, "error");
          // this.cid = response.data.ContractId;
          else
            try {
              this.cid = response.data.finalized_quoteID;
              // console.log(response);
              // console.log(response.data.pdf_base64);
              const base64String = response.data.pdf_base64;
              this.filename = response.data.filename;

              this.$swal.fire("Επιτυχία", "Το αίτημα καταχωρήθηκε", "success");
              this.downloadPDF(base64String, this.filename);
              this.$router.push("/");
            } catch (error) {
              console.error("Error creating PDF:", error);
              // Handle error (e.g., show an error message)
            }
          // console.log(response);
          // .then(() => this.$router.push("/property/policy-requests"));
          // .then(() => this.$router.push("/"));
        })
        .catch((error) => {
          this.$refs.sendRequestButton.setStateIdle();
          this.$swal.fire(
            "Σφάλμα",
            "Το αίτημα δεν μπορεί να καταχωρηθεί",
            "error"
          );
        });
    },
    downloadPDF(base64String, fileName) {
      // Decode the base64 string
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Convert byteArray to Blob
      const blob = new Blob([byteArray], { type: "application/pdf" });

      // Create a link element and trigger download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    verifyNumericInput(evt) {
      if (evt.keyCode <= 32) return;

      if (evt.keyCode == 46) return;

      let numbers = "0123456789".split("");

      if (numbers.includes(evt.key) == false) evt.preventDefault();
    },
    submitButtonClick() {
      this.$refs.sendRequestButton.setStateActive();

      // kua api update offer
      const policyholderDetails = {
        name: this.customer.name,
        surname: this.customer.surname,
        fathersname: this.customer.fathersname,
        VATNumber: this.customer.vatNumber,
        email: this.customer.email,
        address: this.customer.address,
        postcode: this.customer.postalCode,
        telephone: this.customer.phone,
        address: this.customer.address,
        addressNumber: this.customer.addressNumber,
        contractstartdate: this.customer.contractstartdate,
        bank: this.customer.bank ?? null,
      };
      // console.log(this.policyDetails);
      const apiUpdatePayload = {
        username: "elipatr",
        password: "111111",
        InitialQuoteID: this.policyDetails.frb_initial_quotes,
        name: this.customer.name,
        surname: this.customer.surname,
        fathersName: this.customer.fathersname,
        vat_number: this.customer.vatNumber,
        email: this.customer.email,
        phone: this.customer.phone,
        street: this.customer.address,
        street_number: this.customer.addressNumber,
        contract_start_date:
          this.customer.contractstartdate.toLocaleDateString("de-DE"),
        bank: this.customer.bank ?? null,
      };

      const targetUrl = this.requestTravelPolicyIssueUrl.href;
      const contractId = null;
      // console.log(apiUpdatePayload);
      this.axios
        .post(targetUrl, apiUpdatePayload)
        .then((response) => {
          this.$refs.sendRequestButton.setStateIdle();
          if (response.data.ErrorCode != null) {
            // console.log("error");
            this.$swal.fire("Σφάλμα", response.data.ErrorDescription, "error");
          } else {
            this.cid = response.data.finalized_quoteID;
            // console.log("not error");
            this.test = true;
            // console.log(this.test);
            this.$swal.fire("Επιτυχία", "Το αίτημα καταχωρήθηκε", "success");
            // console.log(response);
            // .then(() => this.$router.push("/property/policy-requests"));
          }
        })
        .catch((error) => {
          this.$refs.sendRequestButton.setStateIdle();
          this.$swal.fire(
            "Σφάλμα",
            "Το αίτημα δεν μπορεί να καταχωρηθεί",
            "error"
          );
        });
      // console.log(this.cid);
      const targetIssueUrl = this.requestGetIssueUrl.href;
      const apiIssuePayload = {
        username: "elipatr",
        password: "111111",
        finalized_quoteID: this.cid,
      };

      // console.log(this.cid);
      // console.log(apiIssuePayload);
      // this.axios
      //   .post(targetIssueUrl, apiIssuePayload)
      //   .then((response) => {
      //     this.$refs.sendRequestButton.setStateIdle();
      // if (response.data.ErrorCode)
      //   this.$swal.fire("Σφάλμα", response.data.ErrorDescription, "error");
      // // this.cid = response.data.ContractId;
      // else this.cid = response.data.finalized_quoteID;
      // console.log(response);

      // console.log(this.cid);
      // console.log(this.test);
      // this.$swal.fire("Επιτυχία", "Το αίτημα καταχωρήθηκε", "success");
      // .then(() => this.$router.push("/property/policy-requests"));
      // })
      // .catch((error) => {
      //   this.$refs.sendRequestButton.setStateIdle();
      //   this.$swal.fire(
      //     "Σφάλμα",
      //     "Το αίτημα δεν μπορεί να καταχωρηθεί",
      //     "error"
      //   );
      // });
    },
  },

  computed: {
    requestTravelPolicyIssueUrl() {
      return new URL(
        "/frb/finalize_quote_rik",
        this.$appConfig.KUA_API_BASE_URL
      );
    },
    requestGetIssueUrl() {
      return new URL(
        "/frb/mark_finalized_quote_as_paid",
        this.$appConfig.KUA_API_BASE_URL
      );
    },
    requestPolicyPdfUrl() {
      return new URL("/frb/get_contract_pdf", this.$appConfig.KUA_API_BASE_URL);
    },

    customerDataFilled() {
      return (
        this.customer.address?.length > 3 &&
        this.customer.postalCode?.length > 2 &&
        this.customer.phone?.length > 9 &&
        this.customer.email?.length > 5 &&
        this.customer.vatNumber?.length > 8 &&
        this.customer.name?.length > 2 &&
        this.customer.surname?.length > 2 &&
        this.customer.fathersname?.length > 2 &&
        this.customer.addressNumber?.length > 0 &&
        this.customer.contractstartdate != null
      );
    },

    // insuredDataFilled() {
    //   const travellerDataFilled = (traveller) =>
    //     traveller.surname?.length > 1 &&
    //     traveller.address?.length > 1 &&
    //     traveller.postalCode?.length > 1 &&
    //     traveller.phone?.length > 1 &&
    //     traveller.email?.length > 1 &&
    //     traveller.vatNumber?.length > 1 &&
    //     traveller.name.length > 1 &&
    //     traveller.surname.length > 1 &&
    //     traveller.fathersname.length > 1 &&
    //     traveller.addressNumber.length > 1;

    //   const travellerChecks = this.customer.map((x) => travellerDataFilled(x));

    //   if (travellerChecks.filter((x) => x == false).length > 0) return false;

    //   return true;
    // },

    disableSubmitButton() {
      if (this.customerDataFilled == false) return true;

      // if (this.insuredDataFilled == false) return true;
      // console.log(this.customerDataFilled);
      return false;
    },
  },

  watch: {
    "customer.legalForm": function () {
      this.customer.name = null;
      this.customer.vatNumber = null;
    },
  },
};
</script>
