<template>
  <k-dialog
    v-if="changePasswordDialogHidden == false"
    :title="'Αλλαγή κωδικού πρόσβασης'"
    :closeIcon="false"
  >
    <div class="flex flex-col">
      <div class="text-left">
        <span
          >Για λόγους ασφαλείας, παρακαλώ αλλάξτε τον κωδικό πρόσβασης
          σας.</span
        ><br /><br />
        <span
          >Ο νέος σας κωδικός θα πρέπει να αποτελείται τουλάχιστον από 6
          χαρακτήρες,</span
        ><br />
        <span
          >ενώ δεν είναι υποχρεωτική η χρήση συμβόλων η άλλων ειδικών
          χαρακτήρων.</span
        >
      </div>
      <div class="flex flex-row justify-center items-center text-center mt-8">
        <label
          class="block uppercase tracking-wide text-grey-darker text-xs font-bold m-2 whitespace-nowrap"
          for="newPasswordInput"
          >ΝΕΟΣ ΚΩΔΙΚΟΣ</label
        >
        <k-input id="newPasswordInput" v-model="newPassword"> </k-input>
      </div>
      <div
        v-if="changePasswordRequestResponse !== null"
        class="font-semibold text-red-500 mt-2"
      >
        Σφάλμα: {{ changePasswordRequestResponse }}
      </div>
      <k-dialog-actions-bar
        ref="dialogBar"
        class="mt-8 k-justify-content-center"
      >
        <spinner-button
          ref="sendChangePasswordRequestButton"
          buttonClass="w-60 px-2 py-1 text-white transition-colors duration-300 bg-red-400 enabled:bg-red-500 rounded-md shadow enabled:hover:bg-red-600 focus:outline-none"
          idleText="ΑΠΟΘΗΚΕΥΣΗ ΝΕΟΥ ΚΩΔΙΚΟΥ"
          @idleClick="sendChangePasswordRequest"
        />
      </k-dialog-actions-bar>
    </div>
  </k-dialog>

  <div
    class="flex flex-col justify-center items-center text-center h-full m-2 mb-96 pt-24"
  >
    <custom-card title="Καλωσορίσατε!">
      <div class="text-left">
        <!-- <div class="mt-2">
          <span>Η χρήση του portal αυτού απευθύνεται στις εξής κατηγορίες χρηστών:</span>
        </div>

        <div class="mt-2">
          <ul class="list-disc list-inside">
            <li>Χρήστες που ανήκουν στο backoffice της ΝΝ</li>
            <li>Χρήστες από γραφεία ασφαλίσεων στο δίκτυο της ΝΝ (Unit Managers, Agency Managers, γραμματείες)</li>
            <li>Ασφαλιστές που ανήκουν στο δίκτυο της ΝΝ</li>
          </ul>
        </div>

        <div class="mt-4">
          <span>Από το μενού της εφαρμογής μπορείτε να βρείτε τις εξής λειτουργίες:</span>
          
          <div class="mt-2">
            <ul class="list-disc list-inside">
              <li>Καταχώριση μεταφοράς χαρτοφυλακίου</li>
              <li>Αναζήτηση ιστορικού μεταφορών χαρτοφυλακίου</li>
              <li>Έκδοση αναφορών (παραγωγής, ληξιαρίων, ...)</li>
              <li>Κατέβασμα αιτήσεων ασφάλισης</li>
            </ul>
          </div>
        </div> 

        <div class="mt-2">
          <span>Οι επιλογές που έχετε διαθέσιμες είναι ανάλογες με την κατηγορία χρήστη στην οποία ανήκετε.</span>
        </div>
      -->
        <div class="mt-6">
          <p>
            Για λόγους ασφαλείας, η χρήση της εφαρμογής απαιτεί εκτός των
            στοιχείων εισόδου (username/password) και την χρήση ενός
            δευτερεύοντος κωδικού. Ο κωδικός αυτός μπορεί να αποστέλλεται μέσω
            email, κάθε φορά που θα ζητηθεί, στην διεύθυνση email που είναι
            ορισμένη στις ρυθμίσεις λογαριασμού ή να δημιουργείται από μια
            εφαρμογή authenticator στο κινητό σας τηλέφωνο. <br />
          </p>
          <p class="mt-4">
            Για την δική σας διευκόλυνση, προτείνεται η χρήση εφαρμογής
            authenticator, στην οποία μπορείτε να προσθέσετε τις απαραίτητες
            ρυθμίσεις για να δημιουργεί τον επιπλέον κωδικό σκανάροντας το QR
            code που εμφανίζεται στην οθόνη των ρυθμίσεων λογαριασμού. <br />
            <br />
            Για οποιαδήποτε διευκρίνηση σχετικά με την χρήση της εφαρμογής,
            μπορείτε να απευθυνθείτε στο
            <a href="mailto:it-support@gkaravias.gr">it-support@gkaravias.gr</a>
          </p>
        </div>
      </div>
    </custom-card>
  </div>
  <div class="justify-left items-center text-center h-full">
    <custom-card>
      <div class="text-left ">
        <!-- <div class="mt-2">
          <span>Η χρήση του portal αυτού απευθύνεται στις εξής κατηγορίες χρηστών:</span>
        </div>

        <div class="mt-2">
          <ul class="list-disc list-inside">
            <li>Χρήστες που ανήκουν στο backoffice της ΝΝ</li>
            <li>Χρήστες από γραφεία ασφαλίσεων στο δίκτυο της ΝΝ (Unit Managers, Agency Managers, γραμματείες)</li>
            <li>Ασφαλιστές που ανήκουν στο δίκτυο της ΝΝ</li>
          </ul>
        </div>

        <div class="mt-4">
          <span>Από το μενού της εφαρμογής μπορείτε να βρείτε τις εξής λειτουργίες:</span>
          
          <div class="mt-2">
            <ul class="list-disc list-inside">
              <li>Καταχώριση μεταφοράς χαρτοφυλακίου</li>
              <li>Αναζήτηση ιστορικού μεταφορών χαρτοφυλακίου</li>
              <li>Έκδοση αναφορών (παραγωγής, ληξιαρίων, ...)</li>
              <li>Κατέβασμα αιτήσεων ασφάλισης</li>
            </ul>
          </div>
        </div> 

        <div class="mt-2">
          <span>Οι επιλογές που έχετε διαθέσιμες είναι ανάλογες με την κατηγορία χρήστη στην οποία ανήκετε.</span>
        </div>
      -->
        <!-- <div class="flex flex-row col-span-3 mt-6"> -->
        <div class="grid grid-cols-3">
          <!-- <span>ΚΑΡΑΒΙΑΣ ΑΣΦΑΛΙΣΤΙΚΟΙ ΠΡΑΚΤΟΡΕΣ ΙΚΕ <br /></span> -->
          <span>{{ companyName }} <br /></span>
          <span>ΕΥΡΙΠΙΔΟΥ 12, 10559, ΑΘΗΝΑ <br /></span>
          <span>ΤΗΛ.2103640618 - ΦΑΞ. 2103643503 <br /></span>
          <span>ΑΦΜ. 801090210 - ΔΟΥ Α ΑΘΗΝΩΝ <br /></span>
          <span>ΑΡ.ΓΕΜΗ 148848003000 <br /></span>
        </div>
      </div>
    </custom-card>
  </div>
</template>

<script>
// @ is an alias to /src
import CustomCard from "@/components/CustomCard.vue";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import SpinnerButton from "@/components/SpinnerButton.vue";
import { Input } from "@progress/kendo-vue-inputs";

export default {
  name: "HomeView",
  inject: ["$appConfig"],
  components: {
    CustomCard,
    "k-dialog": Dialog,
    "k-dialog-actions-bar": DialogActionsBar,
    "k-button": Button,
    SpinnerButton,
    "k-input": Input,
  },

  data() {
    return {
      changePasswordDialogHidden: true,
      newPassword: null,
      changePasswordRequestResponse: null,
      companyName: this.$appConfig.APP_COMPANY_NAME
    };
  },

  mounted() {
    if (this.$store.state.passwordChangeRequired) {
      this.openChangePasswordDialog();
    }
  },

  methods: {
    openChangePasswordDialog() {
      this.changePasswordDialogHidden = false;
    },

    closeChangePasswordDialog() {
      this.changePasswordDialogHidden = true;
    },

    sendChangePasswordRequest() {
      const url = this.changePasswordUrl.href;

      this.$refs.sendChangePasswordRequestButton.setStateActive();

      this.axios
        .post(
          url,
          { newPassword: this.newPassword ?? "" },
          {
            headers: { Authorization: "Bearer " + this.$store.state.token },
          }
        )
        .then(() => {
          this.$refs.sendChangePasswordRequestButton.setStateIdle();
          this.closeChangePasswordDialog();
          this.$store.state.passwordChangeRequired = false;
        })
        .catch((error) => {
          this.$refs.sendChangePasswordRequestButton.setStateIdle();

          if (error.response.status == 400) {
            this.changePasswordRequestResponse = error.response.data.message;
          } else
            this.changePasswordDialogHidden =
              "Το αίτημα δεν έγινε δεκτό, παρακαλώ προσπαθήστε ξανά.";

          if(error.response.status == 500){
            this.changePasswordRequestResponse = "Ο κωδικός πρόσβασης δεν πληρεί τα απαιτούμενα κριτήρια";
            this.changePasswordDialogHidden = false;
          }
        });
    },
  },

  computed: {
    changePasswordUrl() {
      return new URL(
        this.$appConfig.API_CHANGE_PASSWORD_URL,
        this.$appConfig.API_BASE_URL
      );
    },
  },

  watch: {
    // newPassword() {
    //   if (this.changePasswordRequestResponse !== null)
    //     this.changePasswordRequestResponse = null;
    // }
  },
};
</script>
