<template>
  <div>
    <new-policy-request-wizard v-bind:policy-details-prop="quote" />
  </div>
</template>

<script>
import NewPolicyRequestWizard from "@/components/property/NewPolicyRequest/NewPolicyRequestWizard.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewPropertyPolicyRequestView",

  data: function () {
    return {
      quote: null,
    };
  },

  components: {
    NewPolicyRequestWizard,
  },

  methods: {
    ...mapGetters(["getCurrentPropertyQuote"]),
    ...mapActions(["clearCurrentPropertyQuoteForPolicyCreation"]),
  },

  created() {
    // console.log("NewPropertyPolicyRequestView 1");

    // console.log(this.getCurrentPropertyQuote());
    this.quote = this.getCurrentPropertyQuote();
    //this.clearCurrentPropertyQuoteForPolicyCreation()
    // console.log("NewPropertyPolicyRequestView");

    // console.log(this.quote);
  },
};
</script>
