<template>
  <div class="bg-auto bg-no-repeat bg-center justify-center w-full" style="background-image: url('kuaoffice.jpg')">
    <login-form />
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'LoginView',
  components: {
    LoginForm
  }
}
</script>
