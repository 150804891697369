<template>
    <new-quote-wizard />
</template>

<script>

import NewQuoteWizard from '@/components/travel/NewQuoteWizard.vue';

export default {
    name: 'NewTravelQuoteView',

    components: {
        'new-quote-wizard' : NewQuoteWizard
    }
}
</script>