<template>
  <div class="flex flex-col w-full h-full">
    <div class="flex flex-row w-full">
      <custom-card
        title="Κριτήρια αναζήτησης μεταφορών"
        class="mt-2"
        extraClass="w-full"
      >
        <div class="flex flex-col w-full">
          <div class="tab__content" v-show="showFilters">
            <div class="flex flex-row mt-8 justify-center">
              <div class="flex flex-col mx-2 w-60">
                <label
                  class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                  for="startDateInput"
                  >ΑΠΟ ΗΜΕΡΟΜΗΝΙΑ</label
                >
                <k-date-picker
                  id="startDateInput"
                  :max="maxStartDateFilterValue"
                  :format="'dd/MM/yyyy'"
                  :value="startDateFilter"
                  @change="startDateChanged"
                >
                </k-date-picker>
              </div>

              <div class="flex flex-col mx-2 w-60">
                <label
                  class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                  for="endDateInput"
                  >ΕΩΣ ΗΜΕΡΟΜΗΝΙΑ</label
                >
                <k-date-picker
                  id="endDateInput"
                  :min="minEndDateFilterValue"
                  :format="'dd/MM/yyyy'"
                  :value="endDateFilter"
                  @change="endDateChanged"
                >
                </k-date-picker>
              </div>
            </div>

            <div class="flex flex-row justify-center mt-4">
              <div class="flex flex-col mx-2 w-60">
                <label
                  class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                  for="contractStatusInput"
                  >ΤΥΠΟΣ ΜΕΤΑΦΟΡΑΣ</label
                >
                <k-drop-down-list
                  id="contractStatusInput"
                  :filterable="true"
                  :data-items="transferTypes"
                  :text-field="'text'"
                  :data-item-key="'value'"
                  v-model="transferTypeFilter"
                >
                </k-drop-down-list>
              </div>

              <div class="flex flex-col mx-2 w-60">
                <label
                  class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                  for="agentCodeInput"
                  >ΚΩΔΙΚΟΣ ΣΥΝΕΡΓΑΤΗ</label
                >
                <k-input id="agentCodeInput" v-model="agentCodeFilter">
                </k-input>
                <transition name="expand">
                  <k-checkbox
                    class="expand"
                    v-if="agentCodeFilter?.length > 0"
                    :label="'Όλοι οι κωδικοί συνεργάτη'"
                    v-model="allAgentCodesFilter"
                    :default-checked="false"
                  />
                </transition>
              </div>
            </div>

            <!-- <k-button class="my-8 mx-2 px-2 w-40" :theme-color="'primary'" @click="loadData">ΑΝΑΖΗΤΗΣΗ</k-button> -->
            <spinner-button
              ref="searchButton"
              buttonClass="w-40 px-2 py-1 text-white transition-colors duration-300 bg-red-500 rounded-md shadow enabled:hover:bg-red-600 focus:outline-none"
              idleText="ΑΝΑΖΗΤΗΣΗ"
              @idleClick="loadData"
            />

            <k-button
              class="my-8 mx-2 px-2 w-40"
              :theme-color="'secondary'"
              @click="clearFilters"
              >ΚΑΘΑΡΙΣΜΑ</k-button
            >

            <!-- <k-button class="my-8 mx-2 px-2 w-40" :theme-color="'success'" @click="exportExcel" :disabled="this.gridData.length > 0 == false">ΕΞΑΓΩΓΗ (XLS)</k-button> -->
            <spinner-button
              ref="exportExcelButton"
              buttonClass="w-40 px-2 py-1 text-white transition-colors duration-300 bg-green-500 rounded-md shadow enabled:hover:bg-green-600 focus:outline-none"
              idleText="ΕΞΑΓΩΓΗ"
              @idleClick="exportExcel"
            />
          </div>
        </div>
      </custom-card>
    </div>

    <div class="flex flex-row overflow-auto m-2">
      <k-grid
        ref="grid"
        :data-items="processedGridData"
        :columns="gridColumns"
        :sortable="true"
        :sort="gridSort"
        :filterable="true"
        :filter="gridFilter"
        @sortchange="gridSortChangeHandler"
        @filterchange="gridFilterChangeHandler"
      >
      </k-grid>
    </div>

    <!-- <div>
        <Grid
            class="mt-2 px-4 py-4 w-full"
            ref="grid"
            :data-items="processedGridData"

            :sortable="true"
            :sort="gridSort"

            :filterable="true"
            :filter="gridFilter"

            :pageable="true"
            :skip="gridSkip"
            :take="gridTake"
            :total="gridTotal"

            @sortchange="gridSortChangeHandler"
            @filterchange="gridFilterChangeHandler"
            @pagechange="gridPageChangeHandler"
            
            :columns="gridColumns"
        />
    </div> -->
  </div>
</template>

<style scoped>
.expand {
  animation: slideDown 0.2s linear;
  overflow: hidden;
}

.expand-leave-active.expand-leave-to {
  transition: height 0.2s linear;
  height: 0;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: 20px;
  }
}
</style>

<script>
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { Button } from "@progress/kendo-vue-buttons";
import { process } from "@progress/kendo-data-query";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { toRaw } from "vue";

import "@progress/kendo-theme-default/dist/all.css";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Input } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";

import TransitionCollapseHeight from "@/components/TransitionCollapseHeight";
import CustomCard from "@/components/CustomCard.vue";
import SpinnerButton from "@/components/SpinnerButton.vue";

export default {
  name: "PortfolioTransfersView",
  inject: ["$appConfig"],

  components: {
    "k-grid": Grid,
    "grid-toolbar": GridToolbar,
    "k-button": Button,
    dropdownlist: DropDownList,
    "k-drop-down-list": DropDownList,
    "k-date-picker": DatePicker,
    "k-input": Input,
    "k-multi-select": MultiSelect,
    "k-checkbox": Checkbox,
    tch: TransitionCollapseHeight,
    CustomCard,
    SpinnerButton,
  },

  data() {
    return {
      showFilters: true,

      transferTypes: [
        { value: "COMMISSION", text: "Commission" },
        { value: "SERVICE", text: "Service" },
        { value: "FULL", text: "Commision & Service" },
      ],

      startDateFilter: null,
      endDateFilter: null,
      transferTypeFilter: null,
      agentCodeFilter: null,
      allAgentCodesFilter: false,

      gridData: [],
      gridColumns: [
        {
          field: "requestedAt",
          editable: false,
          type: "date",
          format: "{0:dd/MM/yyyy}",
        //   filter: "date",
          title: "Ημερομηνία μεταφοράς",
          hidden: false,
        },
        {
          field: "createTime",
          editable: false,
          type: "date",
          format: "{0:HH:mm:ss}",
          // filter: "date",
          title: "Ώρα μεταφοράς",
          hidden: false,
        },
        { field: "contract", editable: false, title: "Αρ. Συμβολαίου" },
        { field: "transferType", editable: false, title: "Είδος μεταφοράς" },
        { field: "oldNode", editable: false, title: "Προηγούμενος συνεργάτης" },
        { field: "newNode", editable: false, title: "Νέος συνεργάτης" },
        { field: "status", editable: false, title: "Κατάσταση" },
        { field: "requestedBy", editable: false, title: "Χρήστης" },
      ],

      gridSort: [
        { field: "requestedAt", dir: "desc" },
        { field: "createTime", dir: "desc" },
      ],
      gridFilter: null,

      gridSkip: null,
      gridTake: null,
    };
  },

  computed: {
    minEndDateFilterValue() {
      if (this.startDateFilter) return this.startDateFilter;
    },

    maxStartDateFilterValue() {
      if (this.endDateFilter) return this.endDateFilter;
    },

    getTransferRequestsUrl() {
      return new URL(
        this.$appConfig.API_GET_CONTRACTS_TRANSFER_REQUESTS_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    gridTotal() {
      return this.gridData?.length ?? 0;
    },

    processedGridData() {
      return process(this.gridData, {
        sort: this.gridSort,
        filter: this.gridFilter,
        take: this.gridTake,
        skip: this.gridSkip,
      });
    },
  },

  mounted() {},

  methods: {
    clearFilters() {
      this.agentCodeFilter = null;
      this.transferTypeFilter = null;
      this.startDateFilter = null;
      this.endDateFilter = null;

      this.resetGrid();
    },

    resetGrid() {
      this.gridData = [];
    },

    loadData() {
      this.resetGrid();

      this.$refs.searchButton.setStateActive();

      const url = this.getTransferRequestsUrl.href;

      let params = {};

      const tryAdd = (paramsObject, filterName, filterValue) => {
        if (filterValue == null) return paramsObject;

        paramsObject[filterName] = filterValue;
        return paramsObject;
      };

      let setEndOfDay = (date) => {
        if (date) {
          date.setHours(23);
          date.setMinutes(59);
          date.setSeconds(59);

          return date;
        }

        return date;
      };

      let processedEndDateFilter = setEndOfDay(this.endDateFilter);

      params = tryAdd(params, "startDate", this.startDateFilter);
      params = tryAdd(params, "endDate", processedEndDateFilter);

      params = tryAdd(params, "agentCode", this.agentCodeFilter);
      params = tryAdd(
        params,
        "transferType",
        this.transferTypeFilter?.value ?? null
      );

      params = tryAdd(params, "allAgentCodes", this.allAgentCodesFilter);

      this.axios
        .get(url, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
          params: params,
        })
        .then((response) => {
          this.gridData = response.data;
          this.$refs.searchButton.setStateIdle();
        })
        .catch((error) => {
          this.$refs.searchButton.setStateIdle();
        });
    },

    startDateChanged(event) {
      this.startDateFilter = event.value;
    },

    endDateChanged(event) {
      this.endDateFilter = event.value;
    },

    gridSortChangeHandler(event) {
      this.gridSort = event.sort;
    },
    gridFilterChangeHandler(event) {
      this.gridFilter = event.filter;
      // console.log(this.gridFilter)
    },
    gridPageChangeHandler(event) {
      this.gridTake = event.page.take;
      this.gridSkip = event.page.skip;
    },

    exportExcel() {
      this.$refs.exportExcelButton.setStateActive();
      // console.log('export start')

      saveExcel({
        columns: this.gridColumns,
        data: this.gridData,
        fileName: "kua-nn-portal-transfer-requests",
      });

      this.$refs.exportExcelButton.setStateIdle();
      // console.log('export done')
    },
  },
};
</script>
