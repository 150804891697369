<template>
    <k-form-element @change="onChange">
        <fieldset class="k-form-fieldset">
            <div class="flex flex-col justify-center">
                <!-- 1st row -->
                <div class="flex flex-row justify-center my-4">
                    <div class="flex flex-col justify-center text-center">
                        <div class="font-semibold">API Config</div>
                        <div class="flex flex-row justify-center my-4">
                            <div>
                                <k-form-field
                                    :name="'apiToken'"
                                    :label="'Token'"
                                    :component="'myTemplate'"
                                    :required="true"
                                    :validator="requiredValidator"
                                >
                                    <template v-slot:myTemplate="{props}">     
                                        <k-input 
                                            class="w-[300px]"
                                            :type="'text'"
                                            v-bind="props"
                                        />
                                    </template>
                                </k-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </k-form-element>
</template>


<script>

import { Field, FormElement } from "@progress/kendo-vue-form";
import { Input } from '@progress/kendo-vue-inputs'

import { requiredValidator } from './validators'

import apiClient from "@/apiClient";

export default {

    data: function () {
        return {
            requiredValidator
        }
    },

    props: [ 'myStepNumber', 'activeStepNumber' ],

    async mounted() {
        const token = await apiClient.login(null, null)

        this.kendoForm.values.apiToken = token
    },

    emits: [
        'onChange'
    ],

    inject: [
        'kendoForm'
    ],

    components: {
        'k-form-element' : FormElement,
        'k-form-field' : Field,
        'k-input' : Input
    },

    computed: {
        isStepValid() {
            const errors = this.kendoForm.errors;
            return errors.apiToken === '';
        },
    },

    methods: {
        onChange() {
            this.$emit('onChange', { stepValid: this.isStepValid });
        },
    },
}
</script>