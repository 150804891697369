<template>
  <k-form-element @change="onChange">
    <fieldset class="k-form-fieldset">
      <div class="grid MainForm_Grid">
        <label class="MainForm_Label">Είδος κατοικίας</label>
        <k-form-field
          class="MainForm_Field"
          :id="'ResidenceType'"
          :name="'ResidenceType'"
          :data-items="residencetype"
          :text-field="'value'"
          :data-item-key="'id'"
          :layout="'horizontal'"
          :component="'myTemplate'"
          :validator="requiredValidator"
        >
          <template v-slot:myTemplate="{ props }">
            <k-dropdownlist v-bind="props" @change="onChange($event)" />
          </template>
        </k-form-field>
        <label class="MainForm_Label">Όροφος</label>
        <k-form-field
          class="MainForm_Field"
          :id="'Floor'"
          :name="'Floor'"
          :data-items="floor"
          :text-field="'value'"
          :data-item-key="'id'"
          :layout="'horizontal'"
          :component="'myTemplate'"
          :validator="requiredValidator"
        >
          <template v-slot:myTemplate="{ props }">
            <k-dropdownlist v-bind="props" @change="onChange($event)" />
          </template>
        </k-form-field>
        <a class="btn MainForm_SVG" cursor @click="toggleDialog">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
          </svg>
        </a>

        <label class="MainForm_Label">Τύπος οροφής</label>
        <k-form-field
          class="MainForm_Field"
          :id="'roofType'"
          :name="'roofType'"
          :data-items="rooftype"
          :text-field="'value'"
          :data-item-key="'id'"
          :layout="'horizontal'"
          :component="'myTemplate'"
          :validator="requiredValidator"
        >
          <template v-slot:myTemplate="{ props }">
            <k-dropdownlist v-bind="props" @change="onChange($event)" />
          </template>
        </k-form-field>
        <a class="btn MainForm_SVG" cursor @click="toggleDialog">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
          </svg>
        </a>

        <label class="MainForm_Label">Ποιότητα κατασκευής</label>
        <k-form-field
          class="MainForm_Field"
          :id="'cnstrctnData'"
          :name="'cnstrctnData'"
          :data-items="cnstrctndata"
          :text-field="'value'"
          :data-item-key="'id'"
          :layout="'horizontal'"
          :component="'myTemplate'"
          :validator="requiredValidator"
        >
          <template v-slot:myTemplate="{ props }">
            <k-dropdownlist v-bind="props" @change="onChange($event)" />
          </template>
        </k-form-field>
        <a class="btn MainForm_SVG" cursor @click="toggleDialog">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
          </svg>
        </a>

        <label class="MainForm_Label">Έτος κατασκευής</label>
        <k-form-field
          class="MainForm_Field"
          :id="'ConstructionYear'"
          :name="'ConstructionYear'"
          :data-items="calculateConstructionYear"
          :layout="'horizontal'"
          :component="'myTemplate'"
          :validator="requiredValidator"
        >
          <template v-slot:myTemplate="{ props }">
            <k-dropdownlist v-bind="props" @change="onChange($event)" />
          </template>
        </k-form-field>
        <span class="MainForm_SVG"></span>

        <label class="MainForm_Label">Ταχυδρομικός Κωδικός</label>
        <k-form-field
          class="MainForm_Field"
          :id="'ZipCode'"
          :name="'ZipCode'"
          :data-items="listofZipCodes"
          :layout="'horizontal'"
          :component="'myTemplate'"
          :text-field="'label'"
          :data-item-key="'value'"
          :validator="requiredValidator"
        >
          <template v-slot:myTemplate="{ props }">
            <k-dropdownlist v-bind="props" @change="onChange($event)" />
          </template>
        </k-form-field>
        <span class="MainForm_SVG"></span>

        <label class="MainForm_Label">Εμβαδόν</label>
        <k-form-field
          class="MainForm_Field"
          :id="'emvadon'"
          :name="'emvadon'"
          :type="'text'"
          :layout="'horizontal'"
          :component="'myTemplate'"
        >
          <template v-slot:myTemplate="{ props }">
            <k-input v-bind="props" @keydown="verifyNumericInput" />
          </template>
        </k-form-field>
        <span class="MainForm_SVG"></span>
        <label class="MainForm_Label">Κατηγορία πακέτου</label>
        <k-form-field
          class="MainForm_Field"
          :id="'Periexomeno'"
          :name="'Periexomeno'"
          :data-items="periexomeno"
          :layout="'horizontal'"
          :component="'myTemplate'"
          :text-field="'value'"
          :data-item-key="'id'"
          :validator="radioGroupValidator"
        >
          <template v-slot:myTemplate="{ props }">
            <k-dropdownlist v-bind="props" @change="onChange($event)" />
          </template>
        </k-form-field>
        <span class="MainForm_SVG"></span>
        <label class="MainForm_Label">Μέτρα Ασφαλείας</label>
        <k-form-field
          class="MainForm_Field"
          :id="'MetraAsfaleias'"
          :name="'MetraAsfaleias'"
          :data-items="metraasfaleias"
          :layout="'horizontal'"
          :component="'myTemplate'"
          :validator="radioGroupValidator"
        >
          <template v-slot:myTemplate="{ props }">
            <k-form-radiogroup
              v-bind="props"
              @change="props.onChange"
              @blur="props.onBlur"
              @focus="props.onFocus"
            />
          </template>
        </k-form-field>
        <span class="MainForm_SVG"></span>

        <label class="MainForm_Label">Ενυπόθηκο</label>
        <k-form-field
          class="MainForm_Field"
          :id="'Enypothiko'"
          :name="'Enypothiko'"
          :data-items="enypothiko"
          :layout="'horizontal'"
          :component="'myTemplate'"
          :validator="radioGroupValidator"
        >
          <template v-slot:myTemplate="{ props }">
            <k-form-radiogroup
              v-bind="props"
              @change="props.onChange"
              @blur="props.onBlur"
              @focus="props.onFocus"
            />
          </template>
        </k-form-field>

        <span class="MainForm_SVG"></span>
      </div>

      <div class="flex flex-col justify-center">
        <!-- 1st row -->
        <div class="flex flex-row justify-center my-4"></div>

        <!-- :data-items="constructionyear" -->

        <!--2nd row -->

        <div v-if="showAnyDisclaimer" class="flex flex-row justify-center my-4">
          <div class="flex flex-col justify-center text-center">
            <div class="font-semibold">DISCLAIMER</div>
            <div class="my-4">
              <div v-if="showBusinessTravelDisclaimer" class="my-2">
                <ul>
                  <li>
                    The coverage concerns only non-manual work (white collars)
                    which takes place exclusively and only in an office. Any
                    work with heavy tools or power tools, any work includes
                    chemical, toxic and any form of flammable liquids or gases,
                    or is related with flame, any work with sharp objects, any
                    work in construction site, shipyards, cargo holdings and
                    ship’s sheaths is excluded absolutely.
                  </li>
                  <li>
                    Any trip must not exceed 6 months (182 days) continuous
                    duration.
                  </li>
                </ul>
              </div>

              <div v-if="showLeisureTravelDisclaimer" class="my-2">
                <ul>
                  <li>
                    The coverage applies exclusively to and from the
                    destination, touring while staying at the country you are
                    visiting.
                  </li>
                  <li>Any activity during the trip is excluded.</li>
                </ul>
              </div>
            </div>
            <div>
              <k-checkbox
                v-model="disclaimerAcknowledged"
                @change="onChange"
                label="I understand and accept this disclaimer"
              />
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </k-form-element>
</template>

<style scoped>
.supagrid {
  /* Define the element as a grid container */
  display: grid;
  /* Auto-fit as many items on a row as possible without going under 180px */
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  /* A little spacing between articles */
  grid-gap: 1em;
}

/* <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών --> */

.popup-content {
  padding: 30px;
  color: #787878;
  background-color: #fcf7f8;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

#categ {
  font-family: Arial, Helvetica, sans-serif;
  border: 2px solid white;
  width: 100%;
}

#categ td,
#customers th {
  border: 2px solid white;
  padding: 8px;
  text-align: left;
}

#categ tr:nth-child(even) {
  background-color: #f8b8aa;
}
#categ tr:nth-child(odd) {
  background-color: #ffded7;
}

/* .k-dialog {
  background-color: red;
  color: black;
}
.myClass kendo-dialog-titlebar{
        background-color: red;
} */

#categ th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ff6358;
  color: white;
  border: 2px solid white;
}
.MainForm_Label {
  grid-column-start: 3;
  grid-column-end: 5;
  font-weight: 600;
  color: #718096;
  margin-top: 0.5rem;
  padding-left: 1px;
  font-size: 1rem; /* 18px */
  line-height: 1.25rem; /* 28px */
  padding-top: 2px;
  padding-bottom: 2px;
}
.MainForm_Field {
  grid-column-start: 5;
  grid-column-end: 7;
  /* grid-column: span 2 / span 2;   */
  /* width: 15rem;   */
  padding-top: 4px;
}
.MainForm_SVG {
  grid-column-start: 7;
  grid-column-end: 9;
  padding-top: 0.25rem; /* 4px */
  padding-left: 0.25rem; /* 4px */
}
.MainForm_Grid {
  place-items: start;
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
/* <!-- //v1.0.0.7 Προσθήκη dialog popup με εικονίδιο δίπλα από το dropdownmenu του Κατηγορία Ταξιδιωτών --> */
</style>

<script>
import FormRadioGroup from "./FormRadioGroup.vue";
import { Field, FormElement } from "@progress/kendo-vue-form";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { radioGroupValidator } from "./validators";
import { requiredValidator } from "./validators";

import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Popup } from "@progress/kendo-vue-popup";
import { Button } from "@progress/kendo-vue-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";

import { Input } from "@progress/kendo-vue-inputs";

import apiClient from "@/apiClient";
const constructionyears_Array = [];

export default {
  name: "StepOne",

  props: {
    show: Boolean,
  },
  components: {
    "k-form-radiogroup": FormRadioGroup,
    "k-form-element": FormElement,
    "k-form-field": Field,
    "k-checkbox": Checkbox,
    "k-dropdownlist": DropDownList,
    Popup: Popup,
    kbutton: Button,
    "k-dialog": Dialog,
    "k-input": Input,
    "dialog-actions-bar": DialogActionsBar,
  },

  inject: {
    kendoForm: { default: {} },
  },

  data: function () {
    return {
      
      visibleDialog: false,
      requiredValidator,

      radioGroupValidator,
      disclaimerAcknowledged: false,
      show: false,
      floor: [
        { value: "Υπόγειο", id: "Υπόγειο" },
        { value: "Ημιυπόγειο", id: "Ημιυπόγειο" },
        { value: "Ισόγειο", id: "Ισόγειο" },
        { value: "Ημιώροφος", id: "Ημιώροφος" },
        { value: "Πρώτος όροφος", id: "Πρώτος όροφος" },
        { value: "Δεύτερος όροφος", id: "Δεύτερος όροφος" },
        { value: "Τρίτος όροφος", id: "Τρίτος όροφος" },
        { value: "Τέταρτος όροφος", id: "Τέταρτος όροφος" },
        { value: "Πέμπτος όροφος", id: "Πέμπτος όροφος" },
        { value: "Έκτος όροφος", id: "Έκτος όροφος" },
        { value: "Έβδομος όροφος", id: "Έβδομος όροφος" },
        { value: "Όγδοος όροφος", id: "Όγδοος όροφος" },
        { value: "Ένατος όροφος", id: "Ένατος όροφος" },
        { value: "Δέκατος όροφος", id: "Δέκατος όροφος" },
        { value: "Μονοκατοικία", id: "Μονοκατοικία" },
      ],
      cnstrctndata: [
        { value: "Συνήθης", id: "Συνήθης" },
        { value: "Εξαιρετική", id: "Εξαιρετική" },
        { value: "Πολυτελής", id: "Πολυτελής" },
      ],
      rooftype: [
        { value: "Ταράτσα", id: "Ταράτσα" },
        {
          value: "Κεραμοσκεπή (σκελετός μπετόν αρμέ)",
          id: "Κεραμοσκεπή (σκελετός μπετόν αρμέ)",
        },
        {
          value: "Κεραμοσκεπή (σκελετός ξύλινος)",
          id: "Κεραμοσκεπή (σκελετός ξύλινος)",
        },
      ],
      residencetype: [
        { value: "Κύρια Κατοικία", id: "Κύρια Κατοικία" },
        { value: "Εξοχική κατοικία", id: "Εξοχική κατοικία" },
        { value: "Ενοικιαζόμενη κατοικία", id: "Ενοικιαζόμενη κατοικία" },
      ],

      constructionyears_Items: constructionyears_Array.slice(),
      test: this.getAdditionalFields(),
      listofZipCodes: [],
      metraasfaleias: [
        {
          label: "Ναι",
          value: "Ναι",
        },
        {
          label: "Όχι",
          value: "Όχι",
        },
      ],
      enypothiko: [
        {
          label: "Ναι",
          value: "Ναι",
        },
        {
          label: "Όχι",
          value: "Όχι",
        },
      ],
      periexomeno: [
        {
          value: "Κάλυψη Κτηρίου",
          id: "Κάλυψη Κτηρίου",
        },
        {
          value: "Κάλυψη κτηρίου και περιεχομένου",
          id: "Κάλυψη κτηρίου και περιεχομένου",
        },
      ],
    };
  },

  computed: {
    buttonText: function () {
      return this.show ? "Hide" : "Show";
    },

    showBusinessTravelDisclaimer() {
      return this.kendoForm.values.travelCategory == "Business";
    },

    showLeisureTravelDisclaimer() {
      return this.kendoForm.values.travelCategory == "Leisure";
    },

    showAnyDisclaimer() {
      return (
        this.showBusinessTravelDisclaimer || this.showLeisureTravelDisclaimer
      );
    },

    isStepValid() {
      const disclaimerOk =
        this.showAnyDisclaimer == false || this.disclaimerAcknowledged == true;

      const errors = this.kendoForm.errors;
      const values = this.kendoForm.values;

      // console.log(`category: ${values.travelCategory} -- type ${values.travelType}`)

      return (
        // errors.insuranceCompany === "" &&
        // errors.travelCategory === "" &&
        // errors.travelType === "" &&
        // values.travelCategory !== "" &&
        // values.travelCategory != null &&
        // values.travelType !== "" &&
        // values.travelType != null &&
        //v1.0.0.6  StepOne.vue : Να μην περνάει στο επόμενο αν δεν εχουν επιλεχθει όλα τα στοιχεία
        values.ResidenceType != null &&
        values.roofType != null &&
        values.cnstrctnData != null &&
        values.ConstructionYear != null &&
        values.MetraAsfaleias != null &&
        values.Enypothiko != null &&
        values.Floor != null &&
        values.Periexomeno != null &&
        // //v1.0.0.6  StepOne.vue : Να μην περνάει στο επόμενο αν δεν εχουν επιλεχθει όλα τα στοιχεία

        disclaimerOk
      );
    },

    calculateConstructionYear() {
      let currentYear = new Date().getFullYear();
      let constructionyears = [];
      let limitYear = 1960;
      let addedYear = currentYear;
      while (addedYear >= limitYear) {
        constructionyears.push(addedYear);
        addedYear--;
      }

      this.constructionyears_Array = constructionyears;
      return this.constructionyears_Array;
    },
    today() {
      return new Date();
    },
  },

  mounted: function () {
    this.show = true;
    this.$emit("onChange", { stepValid: this.isStepValid });
  },

  props: ["activeStepNumber", "myStepNumber", "lastChangedStepNumber"],

  watch: {
    activeStepNumber: function (val) {
      if (val == this.myStepNumber) {
        this.stepActivated();
        this.onChange(false);
      }
    },
  },

  emits: ["onChange"],

  methods: {
    filterChange(event) {
      this.constructionyears_Items = this.filterData(event.filter);
    },
    filterData(filter) {
      const data = this.constructionyears_Array.slice();

      return filterBy(data, filter);
    },

    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },

    getValue(name) {
      const val = this.kendoForm.values[name];

      if (val.value && val.label) return val.label;

      return val;
    },

    verifyNumericInput(evt) {
      if (evt.keyCode <= 32) return;

      if (evt.keyCode == 46) return;

      let numbers = "0123456789".split("");

      if (numbers.includes(evt.key) == false) evt.preventDefault();
    },

    stepActivated() {
      // console.log("response");

      if (this.listofZipCodes.length == 0) this.getAdditionalFields();
      // this.getAdditionalFields();
    },
    async getAdditionalFields() {
      // const token = this.kendoForm.values.apiToken;
      // console.log("i am here");
      const response = await apiClient.FRB_GetZipCodes(null, null);

      // console.log(response);

      this.listofZipCodes = response.ZipCodes.map(function (x) {
        return { value: x.ZipCode, label: x.ZipCode };
      });
    },
    onChange(stepActuallyChanged = true) {
      // console.log('firing onchange from #' + this.myStepNumber + ' with ' + this.isStepValid)
      this.$emit("onChange", {
        stepNumber: this.myStepNumber,
        stepValid: this.isStepValid,
        stepActuallyChanged: stepActuallyChanged,
      });
    },
  },
};
</script>
