<template>
  <div class="overflow-y-scroll max-h-screen">
    <!-- <k-stepper class="my-4" :value="step" :items="steps" @change="stepperChange" @click.prevent  />  -->

    <custom-card>
      <k-form
        ref="form"
        class="form-element"
        :initial-values="initialValues"
        :horizontal="true"
      >
        <step-one
          v-if="step === 0"
          @onChange="stepContentChanged"
          :token="token"
          :policy-details-prop="policyDetailsProp"
        />
        <step-two v-if="step === 1" :policy-details-prop="policyDetailsProp" />
      </k-form>

      <!-- <span class="k-form-separator mt-8"></span> -->

      <!--------------------//v1.0.3.4  To κουμπί στοιχισμένο στο κέντρο κάτω από τα screnning questions----------------------->
      <div class="text-center k-form-buttons justify-center mt-0 mb-10 mr-96">
      <!--------------------//v1.0.3.4  To κουμπί στοιχισμένο στο κέντρο κάτω από τα screnning questions----------------------->
        <span>
          <k-button
            class="w-20"
            :theme-color="'primary'"
            @click="nextSubmitButtonClick"
            :disabled="disableNextStep"
            v-if="isLastStep == false"
          >
            {{ isLastStep ? "Αποστολή" : "Επόμενο" }}
          </k-button>
        </span>
      </div>
      
    </custom-card>
  </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Stepper } from "@progress/kendo-vue-layout";
import { Form } from "@progress/kendo-vue-form";

import StepOne from "@/components/travel/NewPolicyRequest/NewPolicyRequestStepOne.vue";
import StepTwo from "@/components/travel/NewPolicyRequest/NewPolicyRequestStepTwo.vue";

export default {
  components: {
    "k-button": Button,
    "k-stepper": Stepper,
    "k-form": Form,

    StepOne,
    StepTwo,
  },

  props: ["policyDetailsProp"],

  data: function () {
    return {
      step: 0,

      initialValues: {
        apiToken: "1a784a71a160ff4ab12ac7d323293522",
      },

      disableNextStep: true,
    };
  },

  mounted() {
    // console.log(this.policyDetailsProp)
  },

  computed: {
    token() {
      return this.initialValues.apiToken;
    },

    steps() {
      return [
        {
          label: "Screening",
          componentName: "StepOne",
        },
        {
          label: "Αναλυτικά στοιχεία",
          componentName: "StepOne",
        },
      ];
    },

    isLastStep() {
      return this.step == this.steps.length - 1;
    },
  },

  methods: {
    stepperChange(event) {
      const nextStep = event.value;

      if (nextStep > this.step && this.disableNextStep) return;

      this.step = nextStep;
    },

    nextSubmitButtonClick() {
      this.formErrors = this.$refs.form.form.errors;
      if (this.isLastStep) {
        this.showErrors = false;
        const formValues = this.$refs.form.form.values;
        for (const property in formValues) {
          if (formValues[property] === null || formValues[property] === "") {
            this.showErrors = true;
          }
        }
        if (!this.showErrors) {
          alert(JSON.stringify(this.$refs.form.form.values, null, 2));
        }
      } else {
        this.step += 1;
        this.disableNextStep = true;
      }
    },

    stepContentChanged(event) {
      // console.log('caught it')
      this.disableNextStep = !event.stepValid;
    },
  },
};
</script>
