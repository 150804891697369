<template>
    <new-property-quote-wizard />
</template>

<script>

import NewPropertyQuoteWizard from '@/components/property/NewPropertyQuoteWizard.vue';

export default {
    name: 'NewPropertyQuoteView',

    components: {
        'new-property-quote-wizard' : NewPropertyQuoteWizard
    }
}
</script>