<template>
    <div>

      <TreeView
        :animate="false"
        :checkIndeterminateField="checkIndeterminateField"
        :checkboxes="true"
        :expand-icons="true"
        @expandchange="onExpandChange"
        @checkchange="onCheckChange"
        :dataItems="dataItems"
      />
      <div :style="{ marginTop: '5px' }">
        <i>Press SPACE to check/uncheck the active item</i>
        <div class="example-config">Checked IDs: {{ check.ids.join(',') }}</div>
      </div>
    </div>
  </template>
  
  <script>
  import {
    TreeView,
    processTreeViewItems,
    handleTreeViewCheckChange,
  } from '@progress/kendo-vue-treeview';
  
  import { Checkbox } from '@progress/kendo-vue-inputs';
  
  export default {
    components: {
      TreeView,
      kcheckbox: Checkbox,
    },
    inject: [ '$appConfig' ],
    data() {
      return {
        checkIndeterminateField: 'checkUnknown',
        check: {
          idField: 'code',
          applyCheckIndeterminate: true,
          checkIndeterminateField: 'checkUnknown',
          ids: [],
        },
        // tree: [
        //   {
        //     text: 'Furniture',
        //     expanded: true,
        //     items: [
        //       { text: 'Tables & Chairs' },
        //       { text: 'Sofas' },
        //       { text: 'Occasional Furniture' },
        //     ],
        //   },
        //   {
        //     text: 'Decor',
        //     expanded: true,
        //     items: [
        //       { text: 'Bed Linen' },
        //       { text: 'Curtains & Blinds' },
        //       {
        //         text: 'Carpets',
        //         expanded: true,
        //         items: [{ text: 'Yellow' }, { text: 'Blue' }, { text: 'Red' }],
        //       },
        //     ],
        //   },
        // ],
        tree: []
      };
    },
    computed: {
      dataItems() {
        let processedTree = this.tree//.map(x => { return { ...x, expanded: false } })
        return processTreeViewItems(
            processedTree, 
            { check: this.check }
        );
      },
    },
    methods: {
        onCheckChange(event) {
            // console.log('check')
            this.check = handleTreeViewCheckChange(event, this.check, this.tree, {
                singleMode: false,
                checkChildren: true,
                checkParents: true,
            });
        },
        onExpandChange(event) {
            // console.log('expand')
            event.item.expanded = ! (event.item.expanded ?? false);
        },
    },

    mounted() {
        const url = new URL(this.$appConfig.API_GET_NETWORK_URL, this.$appConfig.API_BASE_URL).href;

        this.axios.get(url)
            .then(response => this.tree = response.data)
            .catch(error => console.log(error))

        // this.tree = [
        //     { 
        //         code: '052',
        //         text: '052',
        //         items: [
        //             {
        //                 code: '05200',
        //                 text: '05200',
        //             },
        //             {
        //                 code: '05201',
        //                 text: '05201'
        //             }
        //         ]
        //     }
        // ]
    }
  };
  </script>
  