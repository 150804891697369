<template>
  <div class="text-white table-wrp" @keydown.esc="closeMe">
    <table class="table-auto relative" v-if="iseeyou == false">
      <thead class="top-0 sticky border-b bg-gray-700">
        <tr class="bg-[#D01E2F]">
          <th class="w-1/3"></th>
          <template
            v-for="header in policies.horizontalHeaders"
            v-bind:key="header"
          >
            <th colspan="2" class="text-center text-xl bg-[#D01E2F]">
              {{ header }}
            </th>
          </template>
        </tr>

        <tr class="bg-[#D01E2F]">
          <th class="w-1/3"></th>
          <template
            v-for="(value, name, index) in policies.horizontalHeadersTemp"
            v-bind:key="index"
          >
            <th colspan="2" class="text-center text-xl bg-[#D01E2F]">
              {{ value.total_premium }}
            </th>
          </template>
        </tr>

        <tr class="border-b bg-[#D01E2F] b-2">
          <th>Κάλυψη</th>
          <template
            v-for="header in policies.horizontalHeaders"
            v-bind:key="header"
          >
            <th class="text-sm w-60">Ασφαλιζόμενο Κεφάλαιο</th>
            <th class="text-sm w-60">Απαλλαγή</th>
            <th class="text-sm w-60" v-show="iseeyou">Περιγραφή κάλυψης</th>
          </template>
        </tr>
      </thead>
      <!-- //v1.0.0.9 Στο βήμα 3 στη Σύγκριση καλύψεων στο popup έγινε προσθήκη διαχωριστικής γραμμής ανάμεσα στα insured και excess και διαχωρισμός τους με τα υπόλοιπα με μία συνεχόμενη γραμμή -->
      <tbody class="overflow-scroll divide-y">
        <tr
          v-for="(header, rowid) in policies.verticalHeaders"
          v-bind:key="rowid"
          class="border-b border-t"
        >
          <th scope="row" class="text-left border-r border-l bg-[#D01E2F]">
            <h3 class="py-3 whitespace-normal">
              <button class="row-start-1" @click="showCoverages">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </button>
              {{ header }}
            </h3>
          </th>
          <template
            v-for="cell in policies.valuesByRowCol[rowid]"
            v-bind:key="cell"
          >
            <td class="pl-4 border-r-2 border-dashed">
              <span class="block text-sm divide-0">
                {{ cell.sums_insured }}
              </span>
            </td>
            <td class="pr-4 border-r-2 border-solid">
              <span class="block text-sm">
                {{ cell.excess }}
              </span>
            </td>
            <td class="pr-4 border-r-2 border-solid" v-show="iseeyou">
              <span class="block text-sm">
                {{ cell.description }}
              </span>
            </td>
            <!-- textbox για περιγραφη καλυψεων -->
          </template>
        </tr>
      </tbody>

      <!-- //v1.0.0.9 Στο βήμα 3 στη Σύγκριση καλύψεων στο popup έγινε προσθήκη διαχωριστικής γραμμής ανάμεσα στα insured και excess και διαχωρισμός τους με τα υπόλοιπα με μία συνεχόμενη γραμμή -->
    </table>
    <table v-if="iseeyou == true" class="table-auto relative">
      <thead class="top-0 sticky border-b bg-gray-700">
        <tr class="bg-[#D01E2F]">
          <th class="w-1/3"></th>

          <template
            v-for="header in policies.horizontalHeaders"
            v-bind:key="header"
          >
            <th colspan="3" class="text-center text-xl bg-[#D01E2F]">
              {{ header }}
            </th>
          </template>
        </tr>

        <tr class="bg-[#D01E2F]">
          <th class="w-1/3"></th>
          <template
            v-for="(value, name, index) in policies.horizontalHeadersTemp"
            v-bind:key="index"
          >
            <th colspan="3" class="text-center text-xl bg-[#D01E2F]">
              {{ value.total_premium }}
            </th>
          </template>
        </tr>

        <tr class="border-b bg-[#D01E2F] b-2">
          <th>Καλυπτόμενος Κίνδυνος</th>
          <template
            v-for="header in policies.horizontalHeaders"
            v-bind:key="header"
          >
            <th class="text-sm w-60">Ασφαλιζόμενο Κεφάλαιο</th>
            <th class="text-sm w-60">Απαλλαγή</th>
            <th class="text-sm w-60" v-show="iseeyou">Περιγραφή κάλυψης</th>
          </template>
        </tr>
      </thead>
      <!-- //v1.0.0.9 Στο βήμα 3 στη Σύγκριση καλύψεων στο popup έγινε προσθήκη διαχωριστικής γραμμής ανάμεσα στα insured και excess και διαχωρισμός τους με τα υπόλοιπα με μία συνεχόμενη γραμμή -->
      <tbody class="overflow-scroll divide-y">
        <tr
          v-for="(header, rowid) in policies.verticalHeaders"
          v-bind:key="rowid"
          class="border-b border-t"
        >
          <th scope="row" class="text-left border-r border-l bg-[#D01E2F]">
            <h3 class="py-3 whitespace-normal">
              <button class="row-start-1" @click="showCoverages">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                  />
                </svg>
              </button>
              {{ header }}
            </h3>
          </th>
          <template
            v-for="cell in policies.valuesByRowCol[rowid]"
            v-bind:key="cell"
          >
            <td class="pl-4 border-r-2 border-dashed">
              <span class="block text-sm divide-0">
                {{ cell.sums_insured }}
              </span>
            </td>
            <td class="pr-4 border-r-2 border-solid">
              <span class="block text-sm">
                {{ cell.excess }}
              </span>
            </td>
            <td class="pr-4 border-r-2 border-solid" v-show="iseeyou">
              <span class="block text-sm">
                {{ cell.description }}
              </span>
            </td>
            <!-- textbox για περιγραφη καλυψεων -->
          </template>
        </tr>
      </tbody>

      <!-- //v1.0.0.9 Στο βήμα 3 στη Σύγκριση καλύψεων στο popup έγινε προσθήκη διαχωριστικής γραμμής ανάμεσα στα insured και excess και διαχωρισμός τους με τα υπόλοιπα με μία συνεχόμενη γραμμή -->
    </table>
  </div>
</template>

<style scoped>
.table-wrp {
  justify-content: center;
  max-height: 75vh;
  overflow-y: auto;
  display: block;
}
</style>

<script>
import CustomCard from "../CustomCard.vue";
import CustomCardGrid from "@/components/CustomCardGrid.vue";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
export default {
  name: "PolicyComparator",
  data() {
    return {
      iseeyou: false,
    };
  },
  components: {
    CustomCard,
    "k-dialog": Dialog,
    CustomCardGrid,
  },

  props: ["policies"],

  emits: ["requestClose", "addRemoveLine"],

  methods: {
    showCoverages() {
      console.log(this.iseeyou);
      this.iseeyou = !this.iseeyou;
      console.log(this.iseeyou);
    },
    closeMe() {
      // console.log('requesting close')
      this.$emit("requestClose");
    },
  },
};
</script>
