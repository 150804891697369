<template>
  <div class="bg-auto bg-no-repeat bg-center" style="background-image: url('kuaoffice.jpg')">
    <register-form />
  </div>
</template>

<script>
// @ is an alias to /src
import RegisterForm from '@/components/RegisterForm.vue'
export default {
  name: 'RegisterView',
  components: {
    RegisterForm 
  }
}
</script>
