<template>
  <div class="flex flex-col w-full h-full">
    <div class="flex flex-row w-full">
      <!-- </div>

        <div class="flex flex-row w-full"> -->
      <custom-card title="Στοιχεία μεταφοράς" class="mt-2" extraClass="w-full">
        <div class="flex flex-row w-full justify-evenly">
          <!-- Start of search terms box -->
          <div class="basis-1/8 mx-4 items-center justify-center">
            <div class="flex flex-col space-y-1 py-4">
              <p class="font-bold">ΑΠΟ ΣΥΝΕΡΓΑΤΗ</p>
              <div class="flex flex-col space-y-1">
                <div
                  class="flex items-center bg-white rounded shadow-md mb-4 whitespace-nowrap"
                >
                  <label for="agentCodeInput" class="mx-2"
                    >Κωδ. Συνεργάτη</label
                  >

                  <input
                    id="agentCodeInput"
                    class="h-8 focus:outline-none mx-2"
                    type="text"
                    placeholder=""
                    v-model="agentCode"
                    v-on:keyup.enter="verify"
                    v-on:blur="verify"
                    :disabled="searchEnabled == false"
                  />

                  <div>
                    <svg
                      v-if="verifiedAgent && !verifiedAgent.isDisabled"
                      class="inline mr-2 w-4 h-4 text-gray-200 dark:text-gray-600 stroke-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>

                    <svg
                      v-if="verifiedAgent && verifiedAgent.isDisabled"
                      class="group relative inline-block mr-2 w-4 h-4 text-gray-200 dark:text-gray-600 stroke-yellow-600"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <title>Ο κωδικός συνεργάτη είναι ανενεργός</title>
                      <path
                        d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                      ></path>
                      <line x1="12" y1="9" x2="12" y2="13"></line>
                      <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="flex flex-col space-y-1">
                <div
                  class="flex items-center bg-white rounded shadow-md mb-4 whitespace-nowrap"
                >
                  <input
                    class="h-8 focus:outline-none px-2 text-center w-full"
                    type="text"
                    placeholder="-"
                    v-bind:value="verifiedAgent?.fullName"
                    disabled
                  />
                </div>
              </div>

              <div class="flex flex-col space-y-1">
                <div
                  class="flex items-center bg-white rounded shadow-md mb-4 whitespace-nowrap"
                >
                  <label for="contractTypeInput" class="mx-2"
                    >Είδος κωδικού</label
                  >
                  <k-dropdownlist
                    id="contractTypeInput"
                    class="h-8"
                    :style="{ width: '230px' }"
                    :data-items="contractTypes"
                    :text-field="'text'"
                    :data-item-key="'value'"
                    :default-item="{
                      value: null,
                      text: 'Επιλέξτε είδος κωδικού',
                    }"
                    :value="contractType"
                    @change="newContractTypeSelected"
                    :disabled="searchEnabled == false"
                  />
                </div>
              </div>

              <div class="flex flex-col space-y-1 items-center">
                <!-- <k-button v-if="searchEnabled" theme-color="primary" @click="loadData" :disabled="canLoadData == false">
                                    ΑΝΑΖΗΤΗΣΗ
                                </k-button>


                                <k-button v-else theme-color="primary" @click="newSearch">
                                    NEA ΑΝΑΖΗΤΗΣΗ
                                </k-button> -->

                <spinner-button
                  ref="searchButton"
                  buttonClass="w-full px-2 py-1 text-white transition-colors duration-300 bg-red-400 enabled:bg-red-500 rounded-md shadow enabled:hover:bg-red-600 focus:outline-none"
                  idleText="ΑΝΑΖΗΤΗΣΗ ΣΥΜΒΟΛΑΙΩΝ"
                  finishedText="ΝΕΑ ΑΝΑΖΗΤΗΣΗ"
                  @idleClick="loadData"
                  @finishedClick="clearForms"
                  v-bind:disabled="canLoadData == false"
                />
              </div>
            </div>
          </div>
          <!-- End of search terms box-->

          <!-- <div class="basis-1/8 mx-4"></div> -->

          <!-- Start of transfer parameters box -->
          <div
            class="basis-1/8 mx-4 m-fadeOut"
            :class="{ 'm-fadeIn': gridData?.length > 0 }"
          >
            <div class="flex flex-col space-y-1 py-4">
              <p class="font-bold">ΣΕ ΣΥΝΕΡΓΑΤΗ</p>
              <div class="flex flex-col space-y-1">
                <div
                  class="flex items-center bg-white rounded shadow-md mb-4 whitespace-nowrap"
                >
                  <label for="newAgentCodeInput" class="mx-2"
                    >Κωδ. Συνεργάτη
                  </label>
                  <input
                    id="newAgentCodeInput"
                    class="h-8 focus:outline-none mx-2"
                    type="text"
                    placeholder=""
                    v-model="newAgentCode"
                    v-on:keyup.enter="verify"
                    v-on:blur="verify"
                  />
                  <div>
                    <svg
                      v-if="verifiedNewAgent && !verifiedNewAgent.isDisabled"
                      class="inline mr-2 w-4 h-4 text-gray-200 dark:text-gray-600 stroke-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>

                    <svg
                      v-if="verifiedNewAgent && verifiedNewAgent.isDisabled"
                      class="group relative inline-block mr-2 w-4 h-4 text-gray-200 dark:text-gray-600 stroke-yellow-600"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <title>Ο κωδικός συνεργάτη είναι ανενεργός</title>
                      <path
                        d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                      ></path>
                      <line x1="12" y1="9" x2="12" y2="13"></line>
                      <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="flex flex-col space-y-1">
                <div
                  class="flex items-center bg-white rounded shadow-md mb-4 whitespace-nowrap"
                >
                  <input
                    class="h-8 focus:outline-none mx-2 text-center w-full"
                    type="text"
                    placeholder="-"
                    v-bind:value="verifiedNewAgent?.fullName"
                    disabled
                  />
                </div>
              </div>

              <div class="flex flex-col space-y-1">
                <div
                  class="flex items-center bg-white rounded shadow-md mb-4 whitespace-nowrap"
                >
                  <input
                    class="h-8 focus:outline-none mx-2 text-center w-full"
                    type="text"
                    placeholder="Κανένα επιλεγμένο συμβόλαιο"
                    v-bind:value="totalSelectedRowsText"
                    disabled
                  />
                </div>
              </div>

              <div class="flex flex-col space-y-1 items-center">
                <!-- <k-button theme-color="primary" @click="verifySendRequest" :disabled="canSendRequest == false">ΚΑΤΑΧΩΡΗΣΗ ΜΕΤΑΦΟΡΑΣ</k-button> -->
                <spinner-button
                  ref="sendRequestButton"
                  buttonClass="w-full px-2 py-1 text-white transition-colors duration-300 bg-red-400 enabled:bg-red-500 rounded-md shadow enabled:hover:bg-red-600 focus:outline-none"
                  idleText="ΚΑΤΑΧΩΡΗΣΗ ΜΕΤΑΦΟΡΑΣ"
                  @idleClick="verifySendRequest"
                  v-bind:disabled="canSendRequest == false"
                />
              </div>
            </div>
          </div>

          <!-- End of transfer parameters box -->

          <!-- <div class="basis-1/8 mx-4"></div> -->
          <!-- Start of help box -->
          <!-- <div class="basis-1/8 mx-4">
                        <div class="flex flex-col space-y-1 py-4">
                            <p class="font-bold">Οδηγίες</p>
                            <div class="flex flex-col space-y-1 text-left">
                                    Για να καταχωρήσετε μια μεταφορά συμβολαίων, ακολουθήστε τα εξής βήματα:
                                    <ul class="list-inside list-decimal mt-2">
                                        <li>Επιλογή τρέχοντος (κωδικού) συνεργάτη & τύπου κωδικού.</li>
                                        <li>Επιλογή ενός ή και περισσότερων συμβολαίων προς μεταφορά.</li>
                                        <li>Επιλογή νέου (κωδικού) συνεργάτη & καταχώρηση μεταφοράς.</li>
                                    </ul>
                            </div>
                        </div>
                    </div> -->
        </div>
      </custom-card>

      <!-- <custom-card title="Οδηγίες" class="mt-2">
                <div class="text-left">
                    Για να καταχωρήσετε μια μεταφορά συμβολαίων, ακολουθήστε τα εξής βήματα:
                    <ul class="list-inside list-decimal">
                        <li>Επιλογή τρέχοντος (κωδικού) συνεργάτη & τύπου κωδικού (commission, service, ή και τα δύο).</li>
                        <li>Επιλογή ενός ή και περισσότερων συμβολαίων προς μεταφορά.</li>
                        <li>Επιλογή νέου (κωδικού) συνεργάτη & καταχώρηση μεταφοράς.</li>
                    </ul>
                </div>
            </custom-card> -->
    </div>

    <div class="flex flex-row overflow-auto m-2">
      <k-grid
        ref="grid"
        :data-items="processedGridData"
        :selected-field="selectedField"
        :columns="gridColumns"
        :sortable="true"
        :sort="gridSort"
        :filterable="true"
        :filter="gridFilter"
        @sortchange="gridSortChangeHandler"
        @filterchange="gridFilterChangeHandler"
        @selectionchange="onSelectionChange"
        @headerselectionchange="onHeaderSelectionChange"
        @rowclick="onRowClick"
      >
      </k-grid>
    </div>
  </div>
</template>

<script>
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { Button } from "@progress/kendo-vue-buttons";
import { process } from "@progress/kendo-data-query";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { toRaw } from "vue";

import CustomCard from "@/components/CustomCard.vue";
import SpinnerButton from "@/components/SpinnerButton.vue";

import "@progress/kendo-theme-default/dist/all.css";

export default {
  name: "PortfolioTransferView",
  inject: ["$appConfig"],
  components: {
    "k-grid": Grid,
    "k-grid-toolbar": GridToolbar,
    "k-button": Button,
    "k-dialog": Dialog,
    "k-dialog-actions-bar": DialogActionsBar,
    "k-dropdownlist": DropDownList,
    CustomCard,
    SpinnerButton,
  },

  mounted() {},

  data() {
    return {
      // transferTypes: [ 'PRODUCTION', 'SERVICE', 'BOTH' ],
      // contractTypes: [ 'PRODUCTION_ONLY', 'SERVICE_ONLY', 'BOTH' ],
      searchEnabled: true,

      contractTypes: [
        { value: "COMMISSION", text: "Commission" },
        { value: "SERVICE", text: "Service" },
        { value: "FULL", text: "Commission & Service" },
      ],

      agentCode: null,
      contractType: null,
      verifiedAgent: null,

      agentPortfolio: null,

      gridData: [],
      staticGridColumns: [
        //{ title: 'Actions', cell: 'myTemplate', filterable: false, width: '100' },
        { field: "numAtCard", editable: false, title: "Αρ. Συμβολαίου" },
        {
          field: "nnAgentCode",
          editable: false,
          title: "Συνεργάτης προμήθειας",
        },
        { field: "cardName", editable: false, title: "Επωνυμία συμβαλλόμενου" },
        {
          field: "nnServiceAgentCode",
          editable: false,
          title: "Συνεργάτης service",
        },
        { field: "branch", editable: false, title: "Κλάδος" },
        // { field: 'insuranceCompany', editable: false, title: 'Ασφαλιστική εταιρία' },
      ],

      selectedField: "selected",
      gridSort: null,
      gridFilter: null,
      gridTake: null,
      gridSkip: null,

      transferType: null,
      newAgentCode: null,
      verifiedNewAgent: null,
    };
  },

  methods: {
    async getAgent(code) {
      if (code?.length > 5 == false) return null;

      const url = this.getAgentUrl.href;

      try {
        const response = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
          params: { agentCode: code },
        });

        return response.data;
      } catch {
        return { fullName: "ΑΓΝΩΣΤΟΣ ΚΩΔΙΚΟΣ", isDisabled: true };
      }
    },

    async verify(event) {
      switch (event.target.id) {
        case "agentCodeInput":
          const agent = await this.getAgent(this.agentCode);
          this.verifiedAgent = agent;
          break;

        case "newAgentCodeInput":
          const newAgent = await this.getAgent(this.newAgentCode);

          // if (this.newAgent?.isDisabled == false)
          this.verifiedNewAgent = newAgent;
          break;

        default:
          this.verifiedAgent.fullName = "agent";
        // console.log('Event?')
      }
    },

    newSearch() {
      this.searchEnabled = true;
      this.agentCode = null;
      this.verifiedAgent = null;
      this.value = null;
      this.gridData = [];
      this.contractType = null;
    },

    clearForms() {
      this.newSearch();
      this.newAgentCode = null;
      this.verifiedNewAgent = null;
      this.$refs.searchButton.setStateIdle();
    },

    gridSortChangeHandler(event) {
      this.gridSort = event.sort;
    },
    gridFilterChangeHandler(event) {
      this.gridFilter = event.filter;
    },
    gridPageChangeHandler(event) {
      this.gridTake = event.page.take;
      this.gridSkip = event.page.skip;
    },
    loadData() {
      // validate

      this.$refs.searchButton.setStateActive();

      const url = this.getDataUrl.href;

      this.axios
        .get(url, {
          params: {
            agentCode: this.agentCode,
            contractType: this.contractType.value,
            // notInTransit: true,
          },
        })
        .then((response) => {
          this.gridData = response.data.map((item) => {
            return { ...item, selected: false };
          });
          this.searchEnabled = false;

          this.$refs.searchButton.setStateFinished();

          // if (this.gridData.length > 0)
          //     this.$refs.searchButton.setStateFinished()
          // else
          //     this.$refs.searchButton.setStateIdle()
        })
        .catch((error) => {
          // this.$refs.searchButton.setStateIdle()

          this.$refs.searchButton.setStateFinished();
        });
    },

    newContractTypeSelected(evt) {
      this.contractType = evt.value;
    },

    newTransferTypeSelected(evt) {
      this.transferType = evt.value;
    },

    onHeaderSelectionChange(event) {
      // console.log(event)

      let checked = event.event.target.checked;

      this.gridData = this.gridData.map((item) => {
        return { ...item, selected: checked };
      });
    },

    onSelectionChange(event) {
      if (event.dataItem)
        event.dataItem[this.selectedField] =
          !event.dataItem[this.selectedField];
    },

    onRowClick(event) {
      if (event.dataItem)
        event.dataItem[this.selectedField] =
          !event.dataItem[this.selectedField];
    },

    gridSortChangeHandler(event) {
      this.gridSort = event.sort;
    },

    gridFilterChangeHandler(event) {
      this.gridFilter = event.filter;
    },

    verifySendRequest() {
      this.$swal
        .fire({
          title: "Να καταχωρηθεί το αίτημα μεταφοράς;",
          showDenyButton: true,
          confirmButtonText: `Ναι`,
          denyButtonText: `Όχι`,
          icon: "question",
        })
        .then((result) => {
          if (result.isConfirmed) this.sendRequest();
        });
    },

    sendRequest() {
      this.$refs.sendRequestButton.setStateActive();

      const url = this.sendRequestUrl.href;

      let transferType = null;
      switch (this.contractType.value) {
        case "COMMISSION":
          transferType = "COMMISSION";
          break;

        case "SERVICE":
          transferType = "SERVICE";
          break;

        case "FULL":
          transferType = "FULL";
          break;

        default:
          transferType = "N/A";
      }

      const body = {
        oldCode: this.agentCode,
        newCode: this.newAgentCode,
        transferType: transferType,
        contracts: this.selectedGridRows.map((x) => x.numAtCard),
      };

      this.axios
        .post(url, body, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then(() => {
          this.$refs.sendRequestButton.setStateIdle();
          this.$swal
            .fire("Επιτυχία", "Το αίτημα μεταφοράς καταχωρήθηκε", "success")
            .then(() => this.clearForms());
        })
        .catch((error) => {
          this.$refs.sendRequestButton.setStateIdle();

          if (error.response.status == 400)
            this.$swal.fire("Σφάλμα", error.response.data.error, "error");
          else
            this.$swal.fire(
              "Σφάλμα",
              "Το αίτημα δεν μπορεί να καταχωρηθεί",
              "error"
            );
        });
    },
  },

  computed: {
    processedGridData() {
      return process(this.gridData, {
        sort: this.gridSort,
        filter: this.gridFilter,
        take: this.gridTake,
        skip: this.gridSkip,
      });
    },

    totalSelectedRowsText() {
      if (this.totalSelectedRows == null) return null;

      return `Έχουν επιλεγεί ${this.totalSelectedRows} συμβόλαια`;
    },

    totalAgentContracts() {
      return this.gridData?.length;
    },

    totalSelectedRows() {
      return this.selectedGridRows?.length || null;
    },

    getDataUrl() {
      return new URL(
        this.$appConfig.API_GET_CONTRACTS_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    getAgentUrl() {
      return new URL(
        this.$appConfig.API_GET_AGENT_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    sendRequestUrl() {
      return new URL(
        this.$appConfig.API_NEW_CONTRACTS_TRANSFER_REQUEST_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    gridColumns() {
      return [
        {
          field: "selected",
          width: "50px",
          headerSelectionValue: this.areAllSelected,
          filterable: false,
        },
        ...this.staticGridColumns,
      ];
    },

    areAllSelected() {
      return this.gridData
        ? this.gridData.findIndex((item) => item.selected === false) === -1 &&
            this.gridData.length > 0
        : false;
    },

    selectedGridRows() {
      return this.gridData
        ? this.gridData.filter((item) => item.selected === true)
        : [];
    },

    serviceTransferToInactiveAgentGuard() {
      return (
        this.contractType.value == "SERVICE" && this.verifiedNewAgent.isDisabled
      );
    },

    transferToSameAgent() {
      return this.verifiedNewAgent.code == this.verifiedAgent.code;
    },

    canSendRequest() {
      let formFilled =
        this.selectedGridRows.length > 0 &&
        this.contractType != null &&
        this.verifiedNewAgent != null &&
        this.verifiedAgent != null;

      if (!formFilled) return false;

      if (this.serviceTransferToInactiveAgentGuard) return false;

      if (this.transferToSameAgent) return false;

      return true;
    },

    canLoadData() {
      return this.contractType != null && this.verifiedAgent != null;
    },
  },
};
</script>

<style scoped>
.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.m-fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}
</style>
