import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/LoginView";
import RegisterView from "@/views/RegisterView";
import UserAccountView from "@/views/UserAccountView";
import UsersView from "@/views/UsersView";
import ReportsView from "@/views/ReportsView";
import PortfolioTransfersView from "@/views/PortfolioTransfersView";
import NewPortfolioTransferView from "@/views/NewPortfolioTransferView";
import ApplicationsView from "@/views/ApplicationsView";
import TreeViewDemo from "@/views/TreeViewDemo";

import NewTravelQuote from "@/views/NewTravelQuoteView";

import NewPropertyQuote from "@/views/NewPropertyQuoteView";

import ProductionReportView from "@/views/ProductionReportView";
import CancelledReportView from "@/views/CancelledReportView";
import PaidReportView from "@/views/PaidReportView";
import UnpaidReportView from "@/views/UnpaidReportView";
import ToBeExpiredReportView from "@/views/ToBeExpiredReportView";

import FileUploadsView from "@/views/FileUploadsView";

import NewTravelPolicyRequestView from "@/views/NewTravelPolicyRequestView";
import SavedTravelOffersView from "@/views/SavedTravelOffersView";
import TravelPolicyRequestsView from "@/views/TravelPolicyRequestsView";

import PropertyPolicyRequestsView from "@/views/PropertyPolicyRequestsView";
import SavedPropertyOffersView from "@/views/SavedPropertyOffersView";
import NewPropertyPolicyRequestView from "@/views/NewPropertyPolicyRequestView";

import ForgotPasswordView from "@/views/ForgotPasswordView";

import Intergrations from "@/views/Intergrations";

import store from "@/store";

function isRouteUnprotected(name) {
  const unprotectedRouteNames = ["login", "register", "forgot"];

  if (unprotectedRouteNames.includes(name)) return true;

  return false;
}

function authenticationCheck(to, from, next) {
  const loginRouteName = "login";

  if (
    store.state.token != null ||
    isRouteUnprotected(to.name) ||
    to.name == loginRouteName
  )
    next();
  else next({ name: loginRouteName });
}

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "",
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/useraccount",
    name: "useraccount",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UserAccountView.vue"),
    meta: {
      title: "Ρυθμίσεις λογαριασμού",
    },
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: {
      title: "Διαχερίση χρηστών",
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: ReportsView,
  },

  {
    path: "/reports/production",
    name: "productionreport",
    component: ProductionReportView,
    meta: {
      title: "Αναφορά παραγωγής",
    },
  },

  {
    path: "/reports/cancelled",
    name: "cancelledreportreport",
    component: CancelledReportView,
    meta: {
      title: "Αναφορά ακυρωμένων",
    },
  },
  {
    path: "/reports/paid",
    name: "paidreport",
    component: PaidReportView,
    meta: {
      title: "Αναφορά εξοφλημένων",
    },
  },
  {
    path: "/reports/unpaid",
    name: "unpaidreport",
    component: UnpaidReportView,
    meta: {
      title: "Αναφορά ανεξόφλητων",
    },
  },
  {
    path: "/reports/to-be-expired",
    name: "tobeexpiredrepord",
    component: ToBeExpiredReportView,
    meta: {
      title: "Αναφορά ληξιαρίων",
    },
  },
  {
    path: "/reports/intergrations",
    name: "intergrations",
    component: Intergrations,
    meta: {
      title: "Αποστολή Γεφυρών",
    },
  },

  {
    path: "/portfolio-transfers",
    name: "portfoliotransfers",
    component: PortfolioTransfersView,
    meta: {
      title: "Αναζήτηση μεταφορών συμβολαίων",
    },
  },
  {
    path: "/portfolio-transfers/new",
    name: "newportfoliotransfer",
    component: NewPortfolioTransferView,
    meta: {
      title: "Νεα μεταφορά συμβολαίων",
    },
  },
  {
    path: "/applications",
    name: "applications",
    component: ApplicationsView,
    meta: {
      title: "Αιτήσεις ασφάλισης",
    },
  },
  {
    path: "/treeview",
    name: "treeview",
    component: TreeViewDemo,
  },

  {
    path: "/uploads",
    name: "uploads",
    component: FileUploadsView,
  },

  {
    path: "/travel/newquote",
    name: "newtravelquote",
    component: NewTravelQuote,
  },

  {
    path: "/travel/saved-offers",
    name: "savedtraveloffers",
    component: SavedTravelOffersView,
  },

  {
    path: "/travel/request-issue",
    name: "newtravelpolicyrequestview",
    component: NewTravelPolicyRequestView,
  },

  {
    path: "/travel/policy-requests",
    name: "travelpolicyrequests",
    component: TravelPolicyRequestsView,
  },

  {
    path: "/property/newpropertyquote",
    name: "NewPropertyQuote",
    component: NewPropertyQuote,
  },

  {
    path: "/property/saved-offers",
    name: "savedpropertyoffers",
    component: SavedPropertyOffersView,
  },

  {
    path: "/property/request-issue",
    name: "newpropertypolicyrequestview",
    component: NewPropertyPolicyRequestView,
  },

  {
    path: "/property/policy-requests",
    name: "propertypolicyrequests",
    component: PropertyPolicyRequestsView,
  },

  {
    path: "/forgot",
    name: "forgot",
    component: ForgotPasswordView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("clearPageTitle");

  if (to.meta.title != null) {
    store.dispatch("switchPageTitle", to.meta.title);
  }

  authenticationCheck(to, from, next);

  if (store.state.timeout.running) store.dispatch("timeoutTimerRestart");
});

export default router;
