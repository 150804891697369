<template>
  <k-form-element @change="onChange">
    <fieldset class="k-form-fieldset">
      <!---------------------//v1.0.2.6 Στοίχιση κελιών στο Step 2------------------>
      <div class="flex flex-col justify-center">
        <div class="flex flex-row justify-center my-4">
          <div class="flex flex-col justify-center text-center">
            <div class="font-semibold">
              Γενικά στοιχεία ταξιδιού και ταξιδιωτών
            </div>
            <div class="flex flex-row justify-center my-4">
              <!---------------------//v1.0.2.6 Στοίχιση κελιών στο Step 2------------------>
              <!-- <div class="mx-8">
                                <k-form-field
                                    class="w-60"
                                    :id="'travelStartDate'"
                                    :name="'travelStartDate'"
                                    :label="'Αναχώριση'"
                                    :hint="'Please select a departure date'"
                                    :layout="'horizontal'"
                                    :component="'myTemplate'"
                                    :min="today"
                                    :format="'dd/MM/yyyy'"
                                    :ref="'travelStartDatePicker'"
                                    :placeholder="''"
                                    :readonly="true"
                                >
                                    <template v-slot:myTemplate="{props}">
                                        <k-datepicker v-bind="props" readonly />
                                    </template>
                                </k-form-field>
                            </div> -->

              <div class="mx-8">
                <k-form-field
                  class="w-60"
                  :id="'travelStartDate'"
                  :name="'travelStartDate'"
                  :label="'Αναχώριση'"
                  :layout="'horizontal'"
                  :component="'myTemplate'"
                  :min="today"
                  :format="'dd/MM/yyyy'"
                  :ref="'travelStartDatePicker'"
                  :placeholder="''"
                  @valueChange="startDateChanged"
                >
                  <template v-slot:myTemplate="{ props }">
                    <form-date-input v-bind="props" />
                  </template>
                </k-form-field>
              </div>

              <div class="mx-8">
                <k-form-field
                  class="w-60"
                  :id="'travelEndDate'"
                  :name="'travelEndDate'"
                  :label="'Επιστροφή'"
                  :layout="'horizontal'"
                  :component="'myTemplate'"
                  :min="minimumTravelEndDate"
                  :format="'dd/MM/yyyy'"
                  :ref="'travelEndDatePicker'"
                  :placeholder="''"
                  @valueChange="endDateChanged"
                >
                  <template v-slot:myTemplate="{ props }">
                    <form-date-input v-bind="props" />
                  </template>
                </k-form-field>
              </div>
            </div>

            <!---------------------//v1.0.2.6 Στοίχιση κελιών στο Step 2------------------>
            <div class="flex flex-row my-4">
              <div class="ml-10">
                <!---------------------//v1.0.2.6 Στοίχιση κελιών στο Step 2------------------>
                <k-form-field
                  class="w-60"
                  :id="'residenceCountry'"
                  :name="'residenceCountry'"
                  :label="'Χώρα μόνιμης κατοικίας'"
                  :data-items="residenceCountries"
                  :text-field="'label'"
                  :data-item-key="'value'"
                  :hint="'Please select a country'"
                  :layout="'horizontal'"
                  :component="'myTemplate'"
                  :validator="requiredValidator"
                >
                  <template v-slot:myTemplate="{ props }">
                    <k-dropdownlist
                      :style="{ width: '230px' }"
                      v-bind="props"
                      @change="onChange($event)"
                    />
                  </template>
                </k-form-field>
              </div>

              <!---------------------//v1.0.2.6 Στοίχιση κελιών στο Step 2------------------>
              <div class="ml-16 pl-2">
                <!---------------------//v1.0.2.6 Στοίχιση κελιών στο Step 2------------------>
                <k-form-field
                  class="w-60"
                  :id="'destination'"
                  :name="'destination'"
                  :label="'Προορισμός'"
                  :hint="'Please select a destination'"
                  :data-items="destinations"
                  :text-field="'label'"
                  :data-item-key="'value'"
                  :layout="'horizontal'"
                  :component="'myTemplate'"
                  :validator="requiredValidator"
                >
                  <template v-slot:myTemplate="{ props }">
                    <k-dropdownlist
                      :style="{ width: '230px' }"
                      v-bind="props"
                      @change="onChange($event)"
                    />
                  </template>
                </k-form-field>
              </div>

              <!---------------------//v1.0.2.6 Στοίχιση κελιών στο Step 2------------------>
              <div class="py-6 ml-4">
                <!---------------------//v1.0.2.6 Στοίχιση κελιών στο Step 2------------------>
                <a v-bind:href="destinationsHelpUrl" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                  </svg>
                </a>
              </div>
              <!-- <div class="ml-16 pl-2"> -->
              <!---------------------//v1.0.2.6 Στοίχιση κελιών στο Step 2------------------>
              <!-- <k-form-field
                  class="w-60"
                  :id="'sobo'"
                  :name="'sobo'"
                  :label="'Sell on behalf of'"
                  :hint="'Please select a destination'"
                  :data-items="sobo"
                  :text-field="'label'"
                  :data-item-key="'value'"
                  :layout="'horizontal'"
                  :component="'myTemplate'"
                  :validator="requiredValidator"
                >
                  <template v-slot:myTemplate="{ props }">
                    <k-dropdownlist
                      :style="{ width: '450px' }"
                      v-bind="props"
                      @change="onChange($event)"
                    />
                  </template>
                </k-form-field> -->
              <!-- </div> -->
              <!-- <div v-if="isNN == true" class="shrink-0">
                <k-form-field
                  id="nnAgentCode"
                  class="w-60"
                  :label="'NN Agent Code'"
                  :name="`nnAgentCode`"
                  :type="'text'"
                  :layout="'horizontal'"
                  :component="'myTemplate'"
                >
                  <template v-slot:myTemplate="{ props }">
                    <k-input v-bind="props" />
                  </template>
                </k-form-field>
              </div> -->
              <!-- <span>{{ this.kendoForm.values }}</span> -->
            </div>
          </div>
        </div>

        <!-- 5th row -->
        <div class="flex flex-row justify-center my-4">
          <div class="flex flex-col justify-center text-center">
            <div class="font-semibold">Ταξιδιώτες ανά ηλικιακό group</div>
            <div class="flex flex-row justify-center my-4">
              <div
                class="grid gap-4 grid-cols-3"
                :class="{
                  'grid-cols-2': ageBands.length % 2 == 0,
                  'grid-cols-4': ageBands.length % 4 == 0,
                }"
              >
                <div
                  v-for="ageBand in ageBands"
                  v-bind:key="ageBand.age_band_id"
                  class="shrink-0"
                >
                  <!-- //v1.0.3.1	Προσθήκη div elemnt στο label των agebands ώστε να εμφαβίζονται πάνω απο το πεδίο  -->
                  <div class="text-left ml-1">
                    {{ `Ηλικίας από ${ageBand.minAge} έως ${ageBand.maxAge}` }}
                  </div>
                  <!-- //v1.0.3.1	Προσθήκη div elemnt στο label των agebands ώστε να εμφαβίζονται πάνω απο το πεδίο  -->
                  <k-form-field
                    id="test"
                    class="w-60"
                    :name="`age_bands.${ageBand.age_band_id}.${ageBand.minAge}-${ageBand.maxAge}`"
                    :type="'text'"
                    :layout="'horizontal'"
                    :component="'myTemplate'"
                    @change="validateAgeBandInputs($event)"
                  >
                    <template v-slot:myTemplate="{ props }">
                      <k-input v-bind="props" @keydown="verifyNumericInput" />
                    </template>
                  </k-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <!-- {{ kendoForm.values }}  -->
  </k-form-element>
</template>

<script>
import { Field, FormElement } from "@progress/kendo-vue-form";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Input } from "@progress/kendo-vue-inputs";

import { requiredValidator } from "./validators";

import apiClient from "@/apiClient";

import FormDateInput from "../FormDateInput.vue";
export default {
  components: {
    "k-form-field": Field,
    "k-form-element": FormElement,
    "k-dropdownlist": DropDownList,
    "k-datepicker": DatePicker,
    "k-input": Input,
    FormDateInput,
  },

  props: ["activeStepNumber", "myStepNumber", "lastChangedStepNumber"],

  watch: {
    activeStepNumber: function (val) {
      if (val == this.myStepNumber) {
        this.stepActivated();
        this.onChange(null, false);
      }
    },
  },

  inject: {
    kendoForm: { default: {} },
  },

  data: function () {
    return {
      destinationsHelpUrl: null,
      today: new Date(),
      requiredValidator,
      apiClient,

      residenceCountries: [],
      destinations: [],
      possibleTravelTypes: [],

      maxDepartureDate: null,
      maxReturnDate: null,

      ageBands: [],
      // isNN: false,
      // sobo: [],

      ageBandInputValid: false,
    };
  },

  computed: {
    minimumTravelEndDate() {
      let travelStartDate = this.kendoForm.values.travelStartDate;
      return travelStartDate ?? this.today;
    },

    maximumTravelStartDate() {
      let travelEndDate = this.kendoForm.values.travelEndDate;

      return travelEndDate;
    },
    // isNNAgent() {
    //   let soboo = this.kendoForm.values.sobo;
    //   console.log(soboo);
    //   return soboo;
    // },
  },

  methods: {
    isStepValid() {
      const errors = this.kendoForm.errors;
      const values = this.kendoForm.values;

      // const values = this.kendoForm.values
      // console.log(errors)

      if (errors.destination != "" && values.destination != null)
        errors.destination = "";

      if (errors.residenceCountries != "" && values.residenceCountry != null)
        errors.residenceCountry = "";

      if (errors.sobo != "" && values.sobo != null) errors.sobo = "";
      if (
        false ||
        // || (values.travelStartDate == null)
        // || (values.travelEndDate == null)
        errors.residenceCountry != "" ||
        errors.destination != "" ||
        this.ageBandInputValid == false
        // ||
        // errors.sobo != ""
      )
        return false;

      if (
        values.residenceCountry == null ||
        values.destination == null ||
        values.travelStartDate == null ||
        values.travelEndDate == null
        // ||
        // values.sobo == null
      )
        return false;

      // if (values.sobo.value == "P.00522") {
      //   this.isNN = true;
      //   if (errors.nnAgentCode != "" && values.nnAgentCode != null)
      //     errors.nnAgentCode = "";
      //   if (errors.nnAgentCode != "") return false;
      //   if (values.nnAgentCode == null) return false;
      // } else {
      //   this.isNN = false;
      // }
      const travelStartDate = values.travelStartDate.getTime();
      const travelEndDate = values.travelEndDate.getTime();

      if (travelEndDate < travelStartDate) return false;

      // console.log(values.sobo.value);

      return true;
    },

    validateAgeBandInputs($event) {
      const values = this.kendoForm.values;

      let totalPax = Object.keys(values)
        .filter((x) => x.startsWith("age_band"))
        .map((x) => Number(values[x]))
        .reduce((current, aggregate) => (aggregate = aggregate + current), 0);

      let ageBandFormFieldNames = Object.keys(values).filter((x) =>
        x.startsWith("age_band")
      );

      let adultPax = 0;

      ageBandFormFieldNames.forEach((fieldName) => {
        let ageBandId = Number(fieldName.split(".")[1]);
        let pax = Number(this.kendoForm.values[fieldName]);

        let ageBand = this.ageBands.find((x) => x.age_band_id == ageBandId);

        if (ageBand.minAge >= 18) adultPax += pax;
      });

      if (totalPax > 0 == false || adultPax > 0 == false)
        this.ageBandInputValid = false;
      else this.ageBandInputValid = true;

      this.onChange($event, true);
    },

    stepActivated() {
      // console.log(
      //   `running stepactivated (last changed step: ${this.lastChangedStepNumber})`
      // );

      if (this.lastChangedStepNumber < this.myStepNumber) {
        this.stepReset();
      }

      if (
        this.residenceCountries.length == 0 ||
        this.destinations.length == 0 ||
        this.ageBands.length == 0
      )
        this.getAdditionalFields();
      // this.getSoboFields();
    },

    stepReset() {
      // console.log("resetting step");

      this.residenceCountries = [];
      this.destinations = [];
      this.ageBands = [];

      this.kendoForm.values.travelStartDate = null;
      this.kendoForm.values.travelEndDate = null;
      this.kendoForm.values.residenceCountry = null;
      this.kendoForm.values.destination = null;

      const ageBandFieldNames = Object.keys(this.kendoForm.values).filter((x) =>
        x.startsWith("age_band")
      );

      ageBandFieldNames.forEach((name) => {
        delete this.kendoForm.values[name];
      });
    },
    /// sell on behalf of query
    // async getSoboFields() {
    //   const token = this.kendoForm.values.apiToken;

    //   const response = await apiClient.getSoboDetails(token);
    //   this.sobo = response.map(function (x) {
    //     return { value: x.SAP_partner_code, label: x.full_name };
    //   });
    //   // console.log(this.sobo);
    // },

    async getAdditionalFields() {
      const token = this.kendoForm.values.apiToken;
      const response = await apiClient.getTravelDetails(
        token,
        this.kendoForm.values.travelCategory,
        this.kendoForm.values.travelType,

        ////1.0.2.2 add value of insuredCategory in function getAdditionalFields()
        this.kendoForm.values.insuredCategory
        ////1.0.2.2 add value of insuredCategory in function getAdditionalFields()
      );

      //console.log(response)

      this.residenceCountries = response.Residences.map(function (x) {
        return { value: x.residence_id, label: x.country_en };
      });
      this.destinations = response.Destinations.AvailableAreas.map(function (
        x
      ) {
        return { value: x.DestinationName, label: x.DestinationName };
      });
      this.ageBands = response.AgeBands;

      this.destinationsHelpUrl =
        response.Destinations.ListOfCountries[0].ListOfCountries;

      // this.ageBands.forEach((ageBand) => {
      //     this.kendoForm.values[`age_bands.${ageBand.age_band_id}.${ageBand.minAge}-${ageBand.maxAge}`] = 0
      // });
    },

    verifyNumericInput(evt) {
      if (evt.keyCode <= 32) return;

      if (evt.keyCode == 46) return;

      let numbers = "0123456789".split("");

      if (numbers.includes(evt.key) == false) evt.preventDefault();
    },

    startDateChanged(event) {
      // console.log(e)

      const tgtValue = event.target.valueAsDate;

      // console.log(`travel start date changed, new value ${tgtValue}`);

      // fix
      const currentEndDate = parseInt(
        this.kendoForm.values.travelEndDate?.getTime() ?? tgtValue.getTime()
      );
      const minimumEndDate = parseInt(this.minimumTravelEndDate.getTime());

      if (currentEndDate < minimumEndDate)
        this.kendoForm.values.travelEndDate = null;

      if (this.kendoForm.values.travelType == "Annual" && tgtValue != null) {
        const msInDay = 86400000;
        const daysToAdd = 365;
        var milliseconds = tgtValue.getTime();
        var newMillisecods = milliseconds + msInDay * daysToAdd;
        const newEndDate = new Date(newMillisecods);

        // console.log(`Setting new end date to ${newEndDate}`)

        this.kendoForm.values.travelEndDate = newEndDate;
      }

      this.onChange(event, true);
    },

    endDateChanged(event) {
      this.onChange(event, true);
    },

    onChange(event, stepChanged = true) {
      // console.log(event)
      // console.log('step two changed')

      const validationState = this.isStepValid();
      this.$emit("onChange", {
        stepNumber: this.myStepNumber,
        stepValid: validationState,
        stepActuallyChanged: stepChanged,
      });

      // console.log(validationState)
    },
  },
};
</script>
