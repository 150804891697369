<template>
    <div>
        <new-policy-request-wizard v-bind:policy-details-prop="quote"/>
        
    </div>
</template>

<script>
import NewPolicyRequestWizard from '@/components/travel/NewPolicyRequest/NewPolicyRequestWizard.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'NewTravelPolicyRequestView',

    data: function () {
        return {
            quote: null
        }
    },

    components: {
        NewPolicyRequestWizard
    },

    methods: {
        ...mapGetters(['getCurrentQuote']),
        ...mapActions(['clearCurrentQuoteForPolicyCreation'])
    },

    created() {
        this.quote = this.getCurrentQuote()
        this.clearCurrentQuoteForPolicyCreation()

        // console.log(this.quote)
    }
}
</script>>