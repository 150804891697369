<template>

<div class="text-white table-wrp" @keydown="closeMe">
        <table class="table-auto relative">
            <thead class="top-0 sticky border-b bg-gray-700">
                <tr class="bg-[#D01E2F]">
                    <th class="w-60">Πακέτο</th>
                    <template v-for="header in policies.horizontalHeaders" v-bind:key="header">
                        <th colspan="2" class="text-center text-xl bg-[#D01E2F]">{{header}}</th>
                    </template>
                </tr>
                <tr class="border-b bg-[#D01E2F] b-2">
                    <th>Κάλυψη</th>
                    <template v-for="header in policies.horizontalHeaders" v-bind:key="header">
                        
                        <th class="text-sm w-60">Insured</th>
                        <th class="text-sm w-60">Excess</th>
                        
                    </template>
                </tr>
            </thead>
            <!-- //v1.0.0.9 Στο βήμα 3 στη Σύγκριση καλύψεων στο popup έγινε προσθήκη διαχωριστικής γραμμής ανάμεσα στα insured και excess και διαχωρισμός τους με τα υπόλοιπα με μία συνεχόμενη γραμμή -->
            <tbody class="overflow-y-auto divide-y">
                <tr v-for="(header, rowid) in policies.verticalHeaders" v-bind:key="rowid" class="border-b border-t">
                    <th scope="row" class="text-center border-r border-l bg-[#D01E2F]">
                        <h3 class="py-3 w-60 whitespace-normal">
                            {{ header }}
                        </h3>
                    </th>
                    <template v-for="cell in policies.valuesByRowCol[rowid]" v-bind:key="cell">
                        <td class="pl-4 border-r-2 border-dashed">
                            <span class="block text-sm divide-0">
                            {{ cell.sums_insured }}
                            </span>
                        </td>
                        <td class="pr-4 border-r-2 border-solid">
                            <span class="block text-sm">
                            {{ cell.excess }}
                            </span>
                        </td>
                    </template> 
                </tr>
            </tbody>
            <!-- //v1.0.0.9 Στο βήμα 3 στη Σύγκριση καλύψεων στο popup έγινε προσθήκη διαχωριστικής γραμμής ανάμεσα στα insured και excess και διαχωρισμός τους με τα υπόλοιπα με μία συνεχόμενη γραμμή -->
        </table>
        
    </div>
</template>

<style scoped>
.table-wrp  {
  justify-content: center;
  max-height: 75vh;
  overflow-y: auto;
  display: block;
}

</style>

<script>

export default {
    name: 'PolicyComparator',
    components: {
    },

    props: [ 'policies' ],

    emits: [ 'requestClose' ],

    methods: {
        closeMe() {
            // console.log('requesting close')
            this.$emit('requestClose')
        }
    }
}
</script>