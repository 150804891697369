<template>
  <!--------------------//v1.0.0.2 Aύξηση του width της Σύνοψης----------------------->
  <custom-card title="Σύνοψη" :extraClass="'w-1/4 h-full'">
    <!--------------------//v1.0.0.2 Aύξηση του width της Σύνοψης----------------------->
    <div>
      <table>
        <body>
          <!----------------------//v1.0.0.3  Φτιάξιμο του στησίματος των στοιχείων της καρτέλας Σύνοψη(στοίχηση) tailwind-----------------------> 
          <tr>
            <td class="tlabel px-2 pt-2">Εταιρία</td>
            <td class="tvalue pt-2">{{ getValue("insuranceCompany") }}</td>
          </tr>
          <tr>
            <td class="tlabel px-2 pt-2">Κατηγορία</td>
            <td class="tvalue pt-2 w-96">{{ getValue("insuredCategory") }}</td>
          </tr>
          <tr>
            <td class="tlabel px-2 pt-2">Είδος</td>
            <td class="tvalue pt-2">{{ getValue("travelCategory") }}</td>
          </tr>
          <tr>
            <td class="tlabel px-2 pt-2">Τύπος</td>
            <td class="tvalue pt-2">{{ getValue("travelType") }}</td>
          </tr>

          <hr class="my-4" />
          <tr>
            <td class="tlabel px-2 pt-2">Αναχώριση</td>
            <td class="tvalue pt-2">{{ getValue("travelStartDate") }}</td>
          </tr>
          <tr>
            <td class="tlabel px-2 pt-2">Επιστροφή</td>
            <td class="tvalue pt-2">{{ getValue("travelEndDate") }}</td>
          </tr>
          <tr>
            <td class="tlabel px-2 pt-2">Κατοικία</td>
            <td class="tvalue pt-2">{{ getValue("residenceCountry") }}</td>
          </tr>
          <tr>
            <td class="tlabel px-2 pt-2">Προορισμός</td>
            <td class="tvalue w-96 pt-2">{{ getValue("destination") }}</td>
          </tr>

          <div v-if="ageBands.length > 0">
            <hr class="" />

            <tr>
              <td class="font-bold p-4" colspan="2">Ταξιδιώτες</td>
            </tr>

            <tr v-for="ageBand in ageBands" v-bind:key="ageBand">
              <td class="tlabel w-40 px-2 pt-2 shrink-0">{{ ageBand.name }}</td>
              <td class="tvalue w-96">{{ ageBand.people }}</td>
            </tr>
          </div>

          <div v-if="extraCoverages.length > 0">
            <hr class="my-4" />

            <tr>
              <td class="font-bold text-center p-4" colspan="2">Extras</td>
            </tr>

            <tr
              v-for="extraCoverage in extraCoverages"
              v-bind:key="extraCoverage"
            >
              <td class="tlabel w-40 py-2 shrink-0">
                {{ extraCoverage.name }}
              </td>
              <td class="tvalue w-40 py-2">{{ extraCoverage.value }}</td>
            </tr>
          </div>
        </body>
      </table>
    </div>
  </custom-card>
  <!----------------------//v1.0.0.3  Φτιάξιμο του στησίματος των στοιχείων της καρτέλας Σύνοψη(στοίχηση) tailwind-----------------------> 
</template>

<style scoped>
/*--------------------//v1.0.0.3  Φτιάξιμο του στησίματος των στοιχείων της καρτέλας Σύνοψη(στοίχηση)----------------------->*/
.tlabel {
  text-align: left;
  vertical-align: middle;
  font-weight: bolder;
}
.tvalue {
  text-align: right;
  vertical-align: middle;
}

/*--------------------//v1.0.0.3  Φτιάξιμο του στησίματος των στοιχείων της καρτέλας Σύνοψη(στοίχηση)----------------------->*/
</style>

<script>
import CustomCard from "@/components/CustomCard.vue";

export default {
  name: "NewQuoteSummary",

  components: {
    CustomCard,
  },

  inject: ["kendoForm"],

  props: ["formValues"],

  methods: {
    getValue(name) {
      const val = this.kendoForm.values[name];

      if (val == null || val == "") return "-";

      //v1.0.0.4  NewQuoteSummary.vue : Αλλαγή στο format της ημερομηνίας
      if (val instanceof Date)
        return val.toLocaleDateString("el-GR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
     //v1.0.0.4  NewQuoteSummary.vue : Αλλαγή στο format της ημερομηνίας

      if (val.value && val.label) return val.label;

      return val;
    },
  },

  computed: {
    ageBands() {
      const bandsWithPeople = Object.keys(this.kendoForm.values)
        .filter((x) => x.startsWith("age_band"))
        .map((x) => {
          return {
            name: `Ηλικίας ${x.split(".")[2]}`,
            people: Number(this.kendoForm.values[x]),
          };
        });

      return bandsWithPeople.filter((x) => x.people > 0);
    },

    extraCoverages() {
      const values = this.kendoForm.values;

      const booleanExtras = Object.keys(values)
        .filter((x) => x.startsWith("extra_coverage_boolean"))
        .filter((x) => values[x] == true)
        .map((x) => ({
          name: x.split(".")[2].replaceAll("-", " "),
          value: "Selected",
        }));

      const selectionExtras = Object.keys(values)
        .filter((x) => x.startsWith("extra_coverage_selection"))
        .filter((x) => values[x].coverage_id != 0)
        .map((x) => ({
          name: x.split(".")[1].replaceAll("-", " "),
          value: values[x].Description,
        }));

      const allSelections = [booleanExtras, selectionExtras].flat();

      return allSelections;
    },
  },
};
</script>
