<template>
  <!--------------------//v1.0.0.2 Aύξηση του width της Σύνοψης----------------------->
  <custom-card title="Σύνοψη" :extraClass="'w-1/4 h-full'">
    <!--------------------//v1.0.0.2 Aύξηση του width της Σύνοψης----------------------->
    <div>
      <table>
        <body>
          <!----------------------//v1.0.0.3  Φτιάξιμο του στησίματος των στοιχείων της καρτέλας Σύνοψη(στοίχηση) tailwind----------------------->
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Είδος κατοικίας</td>
            <td class="tvalue pt-2 w-1/2 h-full">{{ this.$props.policyDetailsProp.ResidenceType }}</td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full ">Τύπος οροφής</td>
            <td class="tvalue pt-2 w-96 h-full">{{ this.$props.policyDetailsProp.roofType  }}</td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Ποιότητα κατασκευής</td>
            <td class="tvalue pt-2 w-1/2 h-full">{{ this.$props.policyDetailsProp.cnstrctnData  }}</td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Εμβαδόν</td>
            <td class="tvalue pt-2 w-1/2 h-full">{{ this.$props.policyDetailsProp.emvadon  }}</td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Ταχυδρομικός Κωδικός</td>
            <td class="tvalue pt-2 w-1/2 h-full">{{ this.$props.policyDetailsProp.ZipCode.label  }}</td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Έτος κατασκευής</td>
            <td class="tvalue pt-2 w-1/2 h-full">{{ this.$props.policyDetailsProp.ConstructionYear  }}</td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Μέτρα Ασφαλείας</td>
            <td class="tvalue pt-2 w-1/2 h-full">{{ this.$props.policyDetailsProp.MetraAsfaleias }}</td>
          </tr>
          <tr  >
            <td class="tlabel pl-2 pt-2 w-1/2 h-full">Ενυπόθηκο</td>
            <td class="tvalue pt-2 w-1/2 h-full">{{ this.$props.policyDetailsProp.Enypothiko  }}</td>
          </tr>
          <tr style="border-bottom: 1px solid rgba(194, 189, 189, 0.932) ;">
            <td class="tlabel pl-2 pt-4 w-1/2 h-full"></td>
          </tr>
          <tr>
            <td class="tlabel pl-2 pt-2 w-1/2 h-full"></td>
          </tr>
          <tr>
              <td class="tlabel pl-2 pt-2 w-1/2 h-full">Αριθμός Προσφοράς</td>
              <td class="tvalue  pt-2 w-1/2 h-full">
                {{ this.$props.policyDetailsProp.quote_id }}
              </td>
            </tr>
            <tr>
              <td class="tlabel pl-2 pt-2 w-1/2 h-full">Πακέτο</td>
              <td class="tvalue  pt-2 w-1/2 h-full">
                {{ this.$props.policyDetailsProp.package_name }}
              </td>
            </tr>
            <tr>
              <td class="tlabel pl-2 pt-2 w-1/2 h-full">Σύνολο</td>
              <td class="tvalue  pt-2 w-1/2 h-full">
                {{ this.$props.policyDetailsProp.total_premium }}
              </td>
            </tr> 
        </body>
      </table>  
    
    </div>
  </custom-card>
  <!----------------------//v1.0.0.3  Φτιάξιμο του στησίματος των στοιχείων της καρτέλας Σύνοψη(στοίχηση) tailwind----------------------->
</template>

<style scoped>
/*--------------------//v1.0.0.3  Φτιάξιμο του στησίματος των στοιχείων της καρτέλας Σύνοψη(στοίχηση)----------------------->*/
.tlabel {
  text-align: left;
  vertical-align: top;
  font-weight: bolder;
}
.tvalue {
  text-align: right;
  vertical-align: top;
}

/*--------------------//v1.0.0.3  Φτιάξιμο του στησίματος των στοιχείων της καρτέλας Σύνοψη(στοίχηση)----------------------->*/
</style>

<script>
import CustomCard from "@/components/CustomCard.vue";
export default {
  name: "Property_NewPolicyRequestSummary", 
  props: ["policyDetailsProp"], 
  emits: ["onChange"], 
  inject: ["kendoForm"], 
  components: {  
    "custom-card": CustomCard,
  },

  data() {
    return {
      questions: [],
      gettingQuestions: false, 
      radioGroupItems: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
      rawHtml: `<br>`,
    };
  },

  async mounted() { 

    this.gettingQuestions = true;
 
  },

  computed: { 
  },

  methods: { 
    getValue(name) {
          const val = this.kendoForm.values[name];

          if (val == null || val == "") return "-";

          //v1.0.0.4  NewQuoteSummary.vue : Αλλαγή στο format της ημερομηνίας
          if (val instanceof Date)
            return val.toLocaleDateString("el-GR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
        //v1.0.0.4  NewQuoteSummary.vue : Αλλαγή στο format της ημερομηνίας

          if (val.value && val.label) return val.label;

          return val;
        },
    checkFormValidation() {
      if (this.questions.filter((x) => x.accepted == false).length > 0)
        //|| this.questions.length == 0)
        this.$emit("onChange", { stepValid: false });
      else this.$emit("onChange", { stepValid: true });
    },
  },
};
// export default {
//   name: "Property_NewPolicyRequestSummary",
//   props: [ "policyDetailsProp"],
//   components: {
//     CustomCard,
//   },

//   inject: ["kendoForm"],

//   //props: ["formValues", "policyDetailsProp"],

//   methods: {
//     getValue(name) {
//       const val = this.kendoForm.values[name];

//       if (val == null || val == "") return "-";

//       //v1.0.0.4  NewQuoteSummary.vue : Αλλαγή στο format της ημερομηνίας
//       if (val instanceof Date)
//         return val.toLocaleDateString("el-GR", {
//           day: "2-digit",
//           month: "2-digit",
//           year: "numeric",
//         });
//      //v1.0.0.4  NewQuoteSummary.vue : Αλλαγή στο format της ημερομηνίας

//       if (val.value && val.label) return val.label;

//       return val;
//     },
//   },

//   computed: {


//   },
// };
</script>
