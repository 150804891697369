<template>
  <div
    class="bg-gray-200 text-black text-lg h-full overflow-y-auto pt-2 divide-y divide-red-500"
    id="sidenavSecExample"
  >
  <div ref="upperPart" class="items-center justify-center sm:items-stretch sm:justify-start">
      <div ref="logoWrapper" class="pt-4 pb-4 px-6">
        <div class="flex items-center justify-center">
          <div>
            <!-- class="w-full" -->
            <router-link to="/">
              <img src="/kualogo.png" class="w-40" alt="Avatar" />
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <span class="pt-24 font-semibold text-sm" :style="{fontSize:10+'px'}">{{ companyName }}</span>
      </div>
    <div>

      <!-- <div ref="nnLogoWrapper" class="mt-2">
        <div class="flex flex-col items-center justify-center">
          <div><img src="/nnlogo.png" class="w-28" alt="Avatar" /></div>
          <div class="text-sm font-bold">
            <span>Portal ΝΝ Πρακτορειακής</span>
          </div>
        </div>
      </div> -->

      <div ref="userWrapper" class="my-2">
        <ul class="relative px-1">
          <li class="relative">
            <span class="text-sm"
              >{{ greeting }},
              <span class="text font-bold">{{ userFullName }}</span></span
            >
          </li>
          <li class="relative">
            <span class="text-sm"
              ><span class="text font-bold">({{ user }})</span></span
            >
          </li>
      <li class="relative">
            <router-link
              to="/useraccount"
              class="text-xs hover:text-red-500 hover:bg-red-50 hover:underline transition duration-300 ease-in-out"
            >
              <div class="flex flex-row items-center justify-center">
      
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
             
      <span class="mx-2">Ρυθμίσεις λογαριασμού</span>
              </div>
            </router-link>
          </li>
      </ul>
      </div>  
    </div>    
    </div>
    <div >
      <div ref="menuWrapper">
        <ul class="relative px-1">
          <li class="relative" v-if="canAccessUsers">
            <div
              class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
              href="/users"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3 h-3 mr-3"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <router-link to="/users">Διαχείριση χρηστών</router-link>
            </div>
          </li>
          <li class="relative" v-show="false">
            <div
              class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
              href="/users"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3 h-3 mr-3"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <router-link to="/intergrations"
                >Αποστολή αρχείων παραγωγής</router-link
              >
            </div>
            
          </li>
          <li class="relative" id="sidenavSecEx2" v-if="canAccessReports">
            <a
              class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out cursor-pointer"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSidenavSecEx2"
              aria-expanded="false"
              aria-controls="collapseSidenavSecEx2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3 h-3 mr-3"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M3 3v18h18" />
                <path d="M18.7 8l-5.1 5.2-2.8-2.7L7 14.3" />
              </svg>
              <span>Αναφορές</span>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                class="w-3 h-3 ml-auto"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                ></path>
              </svg>
            </a>
            <ul
              class="relative accordion-collapse collapse"
              id="collapseSidenavSecEx2"
              aria-labelledby="sidenavSecEx2"
              data-bs-parent="#sidenavSecExample"
            >
              <li class="relative" v-if="canAccessProductionReport">
                <router-link
                  to="/reports/production"
                  class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  data-mdb-ripple-color="primary"
                  >Παραγωγή</router-link
                >
              </li>
              <li class="relative" v-if="canAccessToBeExpiredReport">
                <router-link
                  to="/reports/to-be-expired"
                  class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  data-mdb-ripple-color="primary"
                  >Ληξιάρια</router-link
                >
              </li>
              <li class="relative" v-if="canAccessUnpaidReport">
                <router-link
                  to="/reports/unpaid"
                  class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  data-mdb-ripple-color="primary"
                  >Ανεξόφλητα</router-link
                >
              </li>
              <li class="relative" v-if="canAccessPaidReport">
                <router-link
                  to="/reports/paid"
                  class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  data-mdb-ripple-color="primary"
                  >Εξοφλημένα</router-link
                >
              </li>
              <li class="relative" v-if="canAccessCancelledReport">
                <router-link
                  to="/reports/cancelled"
                  class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  data-mdb-ripple-color="primary"
                  >Ακυρωμένα</router-link
                >
              </li>
              <li class="relative" v-if="canAccessIntergration">
                <router-link
                  to="/reports/intergrations"
                  class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  data-mdb-ripple-color="primary"
                  >Αποστολή Γεφυρών</router-link
                >
              </li>
            </ul>
          </li>

          <li class="relative" id="sidenavSecEx4" v-if="canAccessDocuments">
            <a
              class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out cursor-pointer"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSidenavSecEx4"
              aria-expanded="false"
              aria-controls="collapseSidenavSecEx4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3 h-3 mr-3"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
                />
                <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
              </svg>
              <span>Αιτήσεις ασφάλισης</span>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                class="w-3 h-3 ml-auto"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                ></path>
              </svg>
            </a>
            <ul
              class="relative accordion-collapse collapse"
              id="collapseSidenavSecEx4"
              aria-labelledby="sidenavSecEx4"
              data-bs-parent="#sidenavSecExample"
            >
              <li class="relative">
                <div
                  class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/applications"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Έντυπα αιτήσεων</router-link
                  >
                </div>
              </li>
              <li class="relative" v-if="true">
                <div
                  class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/uploads"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Αποστολή αιτήσεων</router-link
                  >
                </div>
              </li>
            </ul>
          </li>

          <!-- Ταξιδιωτικος κλαδος!!!!!!!!!!!!!!!! -->

          <li class="relative" id="sidenavSecEx5" v-if="canAccessTravel">
            <a
              class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out cursor-pointer"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSidenavSecEx5"
              aria-expanded="false"
              aria-controls="collapseSidenavSecEx5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3 h-3 mr-3"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="m 3.326 21.15 c 0.002 -0.806 0.46 -1.594 1.206 -2.476 l -4.314 -2.728 c -0.272 -0.118 -0.266 -0.284 -0.108 -0.48 l 0.914 -0.78 c 0.166 -0.102 0.342 -0.146 0.532 -0.094 l 5.324 0.9 l 4.436 -4.804 l -10.356 -7.006 c -0.262 -0.154 -0.284 -0.328 -0.014 -0.53 l 1.494 -1.192 l 13.5 3.794 l 3.988 -4.264 c 1.338 -1.158 2.638 -1.676 3.636 -1.43 c 0.55 0.136 0.744 0.3 0.914 0.816 c 0.33 1.012 -0.182 2.372 -1.392 3.772 l -4.264 3.988 l 3.794 13.5 l -1.192 1.494 c -0.202 0.268 -0.376 0.246 -0.53 -0.014 l -7.008 -10.354 l -4.804 4.434 l 0.9 5.324 c 0.052 0.188 0.01 0.364 -0.094 0.532 l -0.78 0.914 c -0.194 0.158 -0.362 0.164 -0.48 -0.108 l -2.728 -4.314 c -0.886 0.748 -1.674 1.206 -2.484 1.206 c -0.074 -0.002 -0.09 -0.028 -0.09 -0.1 l 0 0 z"
                />
              </svg>
              <span>Ταξιδιωτικός κλάδος</span>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                class="w-3 h-3 ml-auto"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                ></path>
              </svg>
            </a>
            <ul
              class="relative accordion-collapse collapse"
              id="collapseSidenavSecEx5"
              aria-labelledby="sidenavSecEx5"
              data-bs-parent="#sidenavSecExample"
            >
              <li class="relative">
                <div
                  class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/travel/newquote"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Νέα προσφορά</router-link
                  >
                </div>
              </li>
              <li class="relative">
                <div
                  class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/travel/saved-offers"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Αποθηκευμένες προσφορές</router-link
                  >
                </div>
              </li>
              <li class="relative">
                <div
                  class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/travel/policy-requests"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Απεσταλμένες αιτήσεις</router-link
                  >
                </div>
              </li>
            </ul>
          </li>
          <!-- Ασφάλιση Κατοικίας -->

          <li class="relative" id="sidenavSecEx6" v-if="canAccessProperty">
            <a
              class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out cursor-pointer"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSidenavSecEx6"
              aria-expanded="false"
              aria-controls="collapseSidenavSecEx6"
            >
              <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3 h-3 mr-3"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="m 3.326 21.15 c 0.002 -0.806 0.46 -1.594 1.206 -2.476 l -4.314 -2.728 c -0.272 -0.118 -0.266 -0.284 -0.108 -0.48 l 0.914 -0.78 c 0.166 -0.102 0.342 -0.146 0.532 -0.094 l 5.324 0.9 l 4.436 -4.804 l -10.356 -7.006 c -0.262 -0.154 -0.284 -0.328 -0.014 -0.53 l 1.494 -1.192 l 13.5 3.794 l 3.988 -4.264 c 1.338 -1.158 2.638 -1.676 3.636 -1.43 c 0.55 0.136 0.744 0.3 0.914 0.816 c 0.33 1.012 -0.182 2.372 -1.392 3.772 l -4.264 3.988 l 3.794 13.5 l -1.192 1.494 c -0.202 0.268 -0.376 0.246 -0.53 -0.014 l -7.008 -10.354 l -4.804 4.434 l 0.9 5.324 c 0.052 0.188 0.01 0.364 -0.094 0.532 l -0.78 0.914 c -0.194 0.158 -0.362 0.164 -0.48 -0.108 l -2.728 -4.314 c -0.886 0.748 -1.674 1.206 -2.484 1.206 c -0.074 -0.002 -0.09 -0.028 -0.09 -0.1 l 0 0 z"
                />
              </svg> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 mr-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>

              <span>Ασφάλιση Κατοικίας</span>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                class="w-3 h-3 ml-auto"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                ></path>
              </svg>
            </a>
            <ul
              class="relative accordion-collapse collapse"
              id="collapseSidenavSecEx6"
              aria-labelledby="sidenavSecEx6"
              data-bs-parent="#sidenavSecExample"
            >
              <li class="relative">
                <div
                  class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/property/NewPropertyQuote"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Νέα προσφορά</router-link
                  >
                </div>
              </li>
              <li class="relative">
                <div
                  class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/property/saved-offers"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Αποθηκευμένες προσφορές</router-link
                  >
                </div>
              </li>
              <!-- <li class="relative">
                <div
                  class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/travel/policy-requests"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Απεσταλμένες αιτήσεις</router-link
                  >
                </div>
              </li> -->
            </ul>
          </li>

          <!-- <li class="relative">
          <div class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out" href="#!">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 mr-3" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
            <router-link to="/travel/quote">Ταξιδιωτικές</router-link>
          </div>
        </li> -->

          <li
            class="relative"
            v-if="canAccessUsers && showDevFeatures"
            v-show="false"
          >
            <div
              class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
              href="#!"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3 h-3 mr-3"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <router-link to="/treeview">Treeview</router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--
  <hr class="my-2">
  <ul class="relative px-1">
    <li class="relative">
      <a class="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out" href="#!"  data-mdb-ripple-color="primary">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-3 h-3 mr-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z"></path>
        </svg>
        <span>Non-collapsible link</span>
      </a>
    </li>
    <li class="relative" id="sidenavXxEx2">
      <a class="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out cursor-pointer"  data-bs-toggle="collapse" data-bs-target="#collapseSidenavXxEx2" aria-expanded="false" aria-controls="collapseSidenavXxEx2">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="comments" class="w-3 h-3 mr-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
          <path fill="currentColor" d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92-13.4 30.2-35.5 54.2-35.8 54.5-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25 32.2 15.7 70.3 25 111.3 25 114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92 0-66.9-53.5-124.2-129.3-148.1.9 6.6 1.3 13.3 1.3 20.1 0 105.9-107.7 192-240 192-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25 21.8 12.7 52.1 25 88.7 25 3.2 0 6.1-1.9 7.3-4.8 1.3-2.9.7-6.3-1.5-8.7-.3-.3-22.4-24.2-35.8-54.5z"></path>
        </svg>
        <span>Collapsible item 3</span>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-3 h-3 ml-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
        </svg>
      </a>
      <ul class="relative accordion-collapse collapse" id="collapseSidenavXxEx2" aria-labelledby="sidenavXxEx2" data-bs-parent="#sidenavSecExample">
        <li class="relative">
          <a href="#!" class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"  data-mdb-ripple-color="primary">Link 5</a>
        </li>
        <li class="relative">
          <a href="#!" class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"  data-mdb-ripple-color="primary">Link 6</a>
        </li>
      </ul>
    </li>
    <li class="relative" id="sidenavXxEx3">
      <a class="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out cursor-pointer"  data-bs-toggle="collapse" data-bs-target="#collapseSidenavXxEx3" aria-expanded="false" aria-controls="collapseSidenavXxEx3">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-3 h-3 mr-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z"></path>
        </svg>
        <span>Collapsible item 4</span>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-3 h-3 ml-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
        </svg>
      </a>
      <ul class="relative accordion-collapse collapse" id="collapseSidenavXxEx3" aria-labelledby="sidenavXxEx3" data-bs-parent="#sidenavSecExample">
        <li class="relative">
          <a href="#!" class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"  data-mdb-ripple-color="primary">Link 7</a>
        </li>
        <li class="relative">
          <a href="#!" class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"  data-mdb-ripple-color="primary">Link 8</a>
        </li>
      </ul>
    </li>
  </ul>
  -->

    <div ref="bottomPart" class="mt-4">
      <a href="/logout" class="py-2" @click.prevent="performLogOut">
    <div class="flex flex-row items-center justify-center mt-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M16 17l5-5-5-5M19.8 12H9M10 3H4v18h6" />
          </svg>
          <span class="text-base text-bold mx-2"
            >Αποσύνδεση
            {{
              new Date(autoLogoutSecondsValue * 1000)
                .toISOString()
                .substr(14, 5)
            }}
          </span>
        </div>
    </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import jwt_decode from "jwt-decode";

export default {
  data() {
    return {
      greeting: null,
      isVisible: true,
      widthSize: "240px",
      companyName: this.$appConfig.APP_COMPANY_NAME
    };
  },

  inject: ["$appConfig"],

  mounted() {
    // console.log(this.$appConfig)
    const now = new Date();

    const currentHour = now.getHours();

    if (currentHour >= 5 && currentHour <= 12) this.greeting = "Καλημέρα";
    else if (currentHour >= 2 && currentHour <= 4) this.greeting = "Γερμανικό";
    else this.greeting = "Καλησπέρα";
  },

  props: ["autoLogoutSeconds"],
  computed: {
    canAccessUsers() {
      return this.userHasRole("KUA_ADMIN");
    },

    canAccessPortfolios() {
      return (
        this.userHasRole("KUA_ADMIN") || this.userHasRole("NN_SALESSUPPORT")
      );
    },

    canAccessReports() {
      return (
        this.userHasRole("VIEW_ONLY") ||
        this.userHasRole("KUA_ADMIN") ||
        this.userHasRole("KUA_USER") ||
        this.userHasRole("PARTNER")
      );
    },

    canAccessProductionReport() {
      return this.canAccessReports;
    },

    canAccessTravel() {
      return this.userHasRole("KUA_ADMIN") || this.userHasRole("KUA_USER");
    },
    canAccessProperty() {
      return this.userHasRole("KUA_ADMIN");
    },

    canAccessDocuments() {
      return (
        this.userHasRole("PARTNER") ||
        this.userHasRole("KUA_ADMIN") ||
        this.userHasRole("KUA_USER")
      );
    },

    canAccessToBeExpiredReport() {
      return this.canAccessReports;
    },

    canAccessPaidReport() {
      return this.canAccessReports;
    },

    canAccessUnpaidReport() {
      return this.canAccessReports;
    },

    canAccessCancelledReport() {
      return this.canAccessReports;
    },
    canAccessIntergration() {
      return this.canAccessReports;
    },
    user() {
      return this.$store.state.user;
    },

    userFullName() {
      return this.$store.state.userFullName;
    },

    autoLogoutSecondsValue() {
      let seconds = this.autoLogoutSeconds;
      return seconds;
    },

    showDevFeatures() {
      return (
        this.$appConfig.SHOW_DEV_FEATURES == "true" ||
        this.$appConfig.SHOW_DEV_FEATURES == true
      );
    },
  },
  methods: {
    switchVisibility() {
      this.isVisible = !this.isVisible;
      if (this.isVisible == true) {
        this.widthSize = "240px";
      } else {
        this.widthSize = "30px";
      }
    },
    async performLogOut() {
      this.logOut();
    },

    ...mapActions(["logOut"]),
    userHasRole(roleName) {
      const rolePropertyName =
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
      let jwtDecode = jwt_decode(this.$store.state.token);
      // console.log(roleName);
      let role = jwtDecode[rolePropertyName];
      // console.log(role);
      return role === roleName;
    },
  },
};
</script>
