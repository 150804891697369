<template>
        <new-policy-request-form :policy-details-prop="policyDetailsProp" />
</template>

<script>

import NewPolicyRequestForm from './NewPolicyRequestForm.vue';

export default {
    name: 'StepTwo',
    props: [ 'policyDetailsProp' ],

    components: {
        NewPolicyRequestForm
    }
}
</script>
