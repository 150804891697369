<template>
    <transition
        enter-active-class="enter-active"
        leave-active-class="leave-active"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave">

    </transition>
    <slot></slot>
</template>

<style scoped>
.enter-active,
.leave-active {
  overflow: hidden;
  transition: height 1s linear;
}
</style>

<script>
export default {
    name: 'TransitionCollapseHeight',

    data() {
        return {

        }
    },

    mounted() {
    },

    methods: {
        /**
         * @param {HTMLElement} element
         */
        beforeEnter(element) {
            // console.log('before enter')
            requestAnimationFrame(() => {
                if (!element.style.height) {
                element.style.height = '0px';
                }

                element.style.display = null;
            });
        },
        /**
         * @param {HTMLElement} element
         */
        enter(element) {
            // console.log('enter')
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                element.style.height = `${element.scrollHeight}px`;
                });
            });
        },
        /**
         * @param {HTMLElement} element
         */
        afterEnter(element) {
            // console.log('after')
            element.style.height = null;
        },
        /**
         * @param {HTMLElement} element
         */
        beforeLeave(element) {
            // console.log('before leave')
            requestAnimationFrame(() => {
                if (!element.style.height) {
                element.style.height = `${element.offsetHeight}px`;
                }
            });
        },
        /**
         * @param {HTMLElement} element
         */
        leave(element) {
            // console.log('leave')
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                element.style.height = '0px';
                });
            });
        },
        /**
         * @param {HTMLElement} element
         */
        afterLeave(element) {
            // console.log('after leave')
            element.style.height = null;
        },
    }
}

</script>
