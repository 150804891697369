<template>
  <div class="flex flex-col w-full">
    <div class="px-6 py-4 bg-gray-300 whitespace-nowrap text-black">
      <div class="justify-center text-left">
        <p class="font-bold">{{ pageTitle }}</p>
      </div>

      <div class="py-4 justify-center text-right">
        <router-link
          to="/useraccount"
          class="hover:underline text-nowrap mx-2"
          >{{ userFullName }}</router-link
        >
        <span
          ><a
            href="/logout"
            class="hover:underline mx-2"
            @click.prevent="performLogOut"
            >Αποσύνδεση σε {{ new Date(1200).toISOString().substr(14, 5) }}
          </a></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TopBar",

  data() {
    return {};
  },

  props: ["autoLogoutSeconds"],
  methods: {
    async performLogOut() {
      // console.log('loggingout')
      this.logOut();
      // console.log('loggeddddout')
    },
    ...mapActions(["logOut"]),
  },

  computed: {
    autoLogout() {
      let seconds = this.autoLogoutSeconds ?? 0;
      return seconds;
    },

    user() {
      return this.$store.state.user;
    },

    userFullName() {
      return this.$store.state.userFullName;
    },

    pageTitle() {
      return this.$store.state.currentPageTitle;
    },
  },
};
</script>
