<template>
    <fieldwrapper>
        <div class="k-form-field-wrap">
            <datepicker
                :style="{ width: '230px' }" 
                :valid="valid"
                :id="id"
                :min="min"
                :max="max"
                :format="format"
                :value="value"
                :disabled="disabled"
                :placeholder="placeholder"
                @change="handleChange"
                @blur="handleBlur"
                @focus="handleFocus"
                :label="label"
                :ref="ref"
                />
            <error v-if="showValidationMessage">
                {{validationMessage}}
            </error>
            <hint v-else>{{hint}}</hint>
        </div>
    </fieldwrapper> 
</template>

<script>
import { FieldWrapper } from "@progress/kendo-vue-form";
import { Error, Hint, Label } from "@progress/kendo-vue-labels";
import { DatePicker } from '@progress/kendo-vue-dateinputs';

export default {
    props: {
        min: Date,
        max: Date,
        optional: Boolean,
        disabled: Boolean,
        placeholder: String,
        touched: Boolean,
        label: String,
        validationMessage: String,
        hint: String,
        ref: String,
        id: String,
        valid: Boolean,
        format: String,
        value: {
            type: Date,
            default: null
        }
    },
    components: {
      fieldwrapper: FieldWrapper,
      error: Error,
      hint: Hint,
      klabel: Label,
      datepicker: DatePicker,
      
    },
    computed: {
        showValidationMessage() {
            return this.$props.touched && this.$props.validationMessage;
        },
        showHint() {
            return !this.showValidationMessage && this.$props.hint;
        },
        hintId() {
            return this.showHint ? `${this.$props.id}_hint` : "";
        },
        errorId() {
            return this.showValidationMessage ? `${this.$props.id}_error` : "";
        }
    },
    emits: {
        input: null,
        change: null,
        blur: null,
        focus: null,
        valueChange: null
    },
    methods: {
        handleChange(event){
            // this.$emit('change', e);
            //console.log(e.target)

            // console.log(event.validity)

            // const date = event.value
            
            // const eventValidity = event.validity.valid
            // const dateValidity = 
            //     (date == null) == false 
            //     && event.validity.rangeUnderflow == false
            //     && event.validity.rangeOverflow == false;
            
            
            
            this.$emit('change', event);
            this.$emit('valueChange', event)
        },

        handleBlur(e){
            this.$emit('blur', e);
        },
        
        handleFocus(e){
            this.$emit('focus', e);
        }
    }
}
</script>

