export const dummyQuotes = {
    "Essential Single Trip": {
      "QuoteID": 192631,
      "price": 56.3,
      "sell_on_behalf_of": null,
      "SI": "https://asua.terracottatest.com/documents/rclas/TOB - ESSENTIAL TRAVEL INSURANCE - COMBINED - KUA - 15-09-2021.pdf",
      "PW": "https://asua.terracottatest.com/documents/rclas/EN - Globelink Greek  Policy Wording - COMBINED - KUA - V3 22-11-2021.pdf",
      "KF": "https://asua.terracottatest.com/documents/rclas/TOB - ESSENTIAL TRAVEL INSURANCE - COMBINED - KUA - 15-09-2021.pdf",
      "IPID": "https://asua.terracottatest.com/documents/rclas/IPID - Essential Cover COMBINED V2 29-07-2021 - KUA.pdf",
      "coverages": [
        {
          "coverage_id": "135",
          "cover_description": "Καταστροφή",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "27"
        },
        {
          "coverage_id": "134",
          "cover_description": "Διακοπή Παροχής Υπηρεσιών",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "26"
        },
        {
          "coverage_id": "133",
          "cover_description": "Κάλυψη Πειρατείας/Αεροπειρατείας",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "25"
        },
        {
          "coverage_id": "132",
          "cover_description": "Κάλυψη Ληστείας",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "24"
        },
        {
          "coverage_id": "131",
          "cover_description": "Νομικές Δαπάνες και Συνδρομή",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "23"
        },
        {
          "coverage_id": "130",
          "cover_description": "Όριο για Ενοικιαζόμενο Κατάλυμα",
          "sums_insured": "Μέχρι €120.000 στο σύνολο",
          "excess": "",
          "PolicyTypeID": "1859",
          "visOrder": "22"
        },
        {
          "coverage_id": "129",
          "cover_description": "Προσωπική Αστική Ευθύνη ",
          "sums_insured": "Μέχρι € 2.000.000 στο σύνολο",
          "excess": "€ 400",
          "PolicyTypeID": "1859",
          "visOrder": "21"
        },
        {
          "coverage_id": "128",
          "cover_description": "Χρεώσεις Παράτασης Φύλαξης Κατοικίδιων",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "20"
        },
        {
          "coverage_id": "127",
          "cover_description": "Όριο για Διαβατήριο & Έγγραφα",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "19"
        },
        {
          "coverage_id": "126",
          "cover_description": "Προσωπικά χρήματα, διαβατήριο & έγγραφα ",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "18"
        },
        {
          "coverage_id": "125",
          "cover_description": "Καθυστέρηση Αποσκευών (ελάχιστη καθυστέρηση 12 ώρες)",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "17"
        },
        {
          "coverage_id": "124",
          "cover_description": "Όριο για Γυαλιά οράσεως/Ηλίου ",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "16"
        },
        {
          "coverage_id": "123",
          "cover_description": "Συνολικό Όριο για Τιμαλφή ",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "15"
        },
        {
          "coverage_id": "122",
          "cover_description": "Όριο για Μονό Αντικείμενο/Ζεύγος/Σετ ",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "14"
        },
        {
          "coverage_id": "121",
          "cover_description": "Αποσκευές ",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "13"
        },
        {
          "coverage_id": "120",
          "cover_description": "Απώλεια αναχώρησης",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "12"
        },
        {
          "coverage_id": "119",
          "cover_description": "Εγκατάλειψη μετά από ένα πλήρες 24ωρο καθυστέρησης",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "11"
        },
        {
          "coverage_id": "118",
          "cover_description": "Καθυστέρηση Αναχώρησης",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "10"
        },
        {
          "coverage_id": "117",
          "cover_description": "Στοιχείο 1 – Ταξιδιωτικό Ατύχημα - Επιβαίνοντας σε δημόσια συγκοινωνία μόνο",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "9"
        },
        {
          "coverage_id": "116",
          "cover_description": " Στοιχείο 3 – μόνιμη ολική ανικανότητα",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "8"
        },
        {
          "coverage_id": "115",
          "cover_description": " Στοιχείο 2 – απώλεια άκρου/ων ή απώλεια όρασης",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "7"
        },
        {
          "coverage_id": "114",
          "cover_description": "Στοιχείο 1 – θάνατος από ατύχημα",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "6"
        },
        {
          "coverage_id": "113",
          "cover_description": "Προσωπικό Ατύχημα",
          "sums_insured": "",
          "excess": "",
          "PolicyTypeID": "1859",
          "visOrder": "5"
        },
        {
          "coverage_id": "112",
          "cover_description": "Κάλυψη Νοσοκομειακής Περίθαλψης",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1859",
          "visOrder": "4"
        },
        {
          "coverage_id": "111",
          "cover_description": "Συμπεριλαμβανομένης Οδοντιατρικής Αγωγής",
          "sums_insured": "€ 250",
          "excess": "",
          "PolicyTypeID": "1859",
          "visOrder": "3"
        },
        {
          "coverage_id": "110",
          "cover_description": "Επείγοντα Ιατρικά Έξοδα, Έξοδα Επαναπατρισμού και Άλλα Έξοδα",
          "sums_insured": "Μέχρι € 250.000 στο σύνολο",
          "excess": "€ 65",
          "PolicyTypeID": "1859",
          "visOrder": "2"
        },
        {
          "coverage_id": "109",
          "cover_description": "Χρεώσεις Ακύρωσης ή Διακοπής",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "€ 65",
          "PolicyTypeID": "1859",
          "visOrder": "1"
        }
      ]
    },
    "Gold Single Trip": {
      "QuoteID": 192632,
      "price": 112.6,
      "sell_on_behalf_of": null,
      "SI": "https://asua.terracottatest.com/documents/rclas/TOB - GOLD TRAVEL INSURANCE COMBINED - KUA - 15-09-2021.pdf",
      "PW": "https://asua.terracottatest.com/documents/rclas/EN - Globelink Greek  Policy Wording - COMBINED - KUA - V3 22-11-2021.pdf",
      "KF": "https://asua.terracottatest.com/documents/rclas/TOB - GOLD TRAVEL INSURANCE COMBINED - KUA - 15-09-2021.pdf",
      "IPID": "https://asua.terracottatest.com/documents/rclas/IPID - Gold Cover COMBINED V2 29-07-2021 - KUA.pdf",
      "coverages": [
        {
          "coverage_id": "217",
          "cover_description": "Χρεώσεις Ακύρωσης ή Διακοπής",
          "sums_insured": "Μέχρι € 7.000 στο σύνολο",
          "excess": "€ 65",
          "PolicyTypeID": "1861",
          "visOrder": "1"
        },
        {
          "coverage_id": "218",
          "cover_description": "Επείγοντα Ιατρικά Έξοδα, Έξοδα Επαναπατρισμού και Άλλα Έξοδα",
          "sums_insured": "€ 10.000.000",
          "excess": "€ 65",
          "PolicyTypeID": "1861",
          "visOrder": "2"
        },
        {
          "coverage_id": "219",
          "cover_description": "Συμπεριλαμβανομένης Οδοντιατρικής Αγωγής",
          "sums_insured": "€ 250",
          "excess": "",
          "PolicyTypeID": "1861",
          "visOrder": "3"
        },
        {
          "coverage_id": "220",
          "cover_description": "Κάλυψη Νοσοκομειακής Περίθαλψης",
          "sums_insured": "€25 ανά πλήρες 24ωρο έως €2.500 συνολικά",
          "excess": "0",
          "PolicyTypeID": "1861",
          "visOrder": "4"
        },
        {
          "coverage_id": "221",
          "cover_description": "Προσωπικό Ατύχημα",
          "sums_insured": "",
          "excess": "",
          "PolicyTypeID": "1861",
          "visOrder": "5"
        },
        {
          "coverage_id": "222",
          "cover_description": "Στοιχείο 1 – θάνατος από ατύχημα",
          "sums_insured": "€ 64,00",
          "excess": "0",
          "PolicyTypeID": "1861",
          "visOrder": "6"
        },
        {
          "coverage_id": "223",
          "cover_description": " Στοιχείο 2 – απώλεια άκρου/ων ή απώλεια όρασης",
          "sums_insured": "€ 64,00",
          "excess": "",
          "PolicyTypeID": "1861",
          "visOrder": "7"
        },
        {
          "coverage_id": "224",
          "cover_description": " Στοιχείο 3 – μόνιμη ολική ανικανότητα",
          "sums_insured": "€ 64,00",
          "excess": "",
          "PolicyTypeID": "1861",
          "visOrder": "8"
        },
        {
          "coverage_id": "225",
          "cover_description": "Στοιχείο 1 – Ταξιδιωτικό Ατύχημα - Επιβαίνοντας σε δημόσια συγκοινωνία μόνο",
          "sums_insured": "€ 100.000,00",
          "excess": "",
          "PolicyTypeID": "1861",
          "visOrder": "9"
        },
        {
          "coverage_id": "226",
          "cover_description": "Καθυστέρηση Αναχώρησης",
          "sums_insured": "€50 για το πρώτο πλήρες 12ωρο καθυστέρησης και μετά €25 για κάθε επιπλέον πλήρες 12ωρο έως €600 συνολικά",
          "excess": "0",
          "PolicyTypeID": "1861",
          "visOrder": "10"
        },
        {
          "coverage_id": "227",
          "cover_description": "Εγκατάλειψη μετά από ένα πλήρες 24ωρο καθυστέρησης",
          "sums_insured": "€ 7.000",
          "excess": "€ 65",
          "PolicyTypeID": "1861",
          "visOrder": "11"
        },
        {
          "coverage_id": "228",
          "cover_description": "Απώλεια αναχώρησης",
          "sums_insured": "€ 1.800",
          "excess": "€ 65",
          "PolicyTypeID": "1861",
          "visOrder": "12"
        },
        {
          "coverage_id": "229",
          "cover_description": "Αποσκευές ",
          "sums_insured": "Μέχρι € 3.000 στο σύνολο",
          "excess": "€ 65",
          "PolicyTypeID": "1861",
          "visOrder": "13"
        },
        {
          "coverage_id": "230",
          "cover_description": "Όριο για Μονό Αντικείμενο/Ζεύγος/Σετ ",
          "sums_insured": "€ 500",
          "excess": "",
          "PolicyTypeID": "1861",
          "visOrder": "14"
        },
        {
          "coverage_id": "231",
          "cover_description": "Συνολικό Όριο για Τιμαλφή ",
          "sums_insured": "€ 500",
          "excess": "",
          "PolicyTypeID": "1861",
          "visOrder": "15"
        },
        {
          "coverage_id": "232",
          "cover_description": "Όριο για Γυαλιά οράσεως/Ηλίου ",
          "sums_insured": "€ 300",
          "excess": "",
          "PolicyTypeID": "1861",
          "visOrder": "16"
        },
        {
          "coverage_id": "233",
          "cover_description": "Καθυστέρηση Αποσκευών (ελάχιστη καθυστέρηση 12 ώρες)",
          "sums_insured": "€ 250",
          "excess": "",
          "PolicyTypeID": "1861",
          "visOrder": "17"
        },
        {
          "coverage_id": "234",
          "cover_description": "Προσωπικά χρήματα, διαβατήριο & έγγραφα ",
          "sums_insured": "€ 900",
          "excess": "€ 65",
          "PolicyTypeID": "1861",
          "visOrder": "18"
        },
        {
          "coverage_id": "235",
          "cover_description": "Όριο για Διαβατήριο & Έγγραφα",
          "sums_insured": "€ 300",
          "excess": "",
          "PolicyTypeID": "1861",
          "visOrder": "19"
        },
        {
          "coverage_id": "236",
          "cover_description": "Χρεώσεις Παράτασης Φύλαξης Κατοικίδιων",
          "sums_insured": "€35 ανά ημέρα έως €350 συνολικά",
          "excess": "",
          "PolicyTypeID": "1861",
          "visOrder": "20"
        },
        {
          "coverage_id": "237",
          "cover_description": "Προσωπική Αστική Ευθύνη ",
          "sums_insured": "Μέχρι € 2.000.000 στο σύνολο",
          "excess": "€ 400",
          "PolicyTypeID": "1861",
          "visOrder": "21"
        },
        {
          "coverage_id": "238",
          "cover_description": "Όριο για Ενοικιαζόμενο Κατάλυμα",
          "sums_insured": "Μέχρι € 120.000 στο σύνολο",
          "excess": "",
          "PolicyTypeID": "1861",
          "visOrder": "22"
        },
        {
          "coverage_id": "239",
          "cover_description": "Νομικές Δαπάνες και Συνδρομή",
          "sums_insured": "Μέχρι € 19.000 στο σύνολο",
          "excess": "€ 400",
          "PolicyTypeID": "1861",
          "visOrder": "23"
        },
        {
          "coverage_id": "240",
          "cover_description": "Κάλυψη Ληστείας",
          "sums_insured": "€60 για κάθε πλήρες 24ωρο νοσηλείας έως €1.200 συνολικά",
          "excess": "0",
          "PolicyTypeID": "1861",
          "visOrder": "24"
        },
        {
          "coverage_id": "241",
          "cover_description": "Κάλυψη Πειρατείας/Αεροπειρατείας",
          "sums_insured": "€60 για κάθε πλήρες 24ωρο έως €600 συνολικά",
          "excess": "€ 0",
          "PolicyTypeID": "1861",
          "visOrder": "25"
        },
        {
          "coverage_id": "242",
          "cover_description": "Διακοπή Παροχής Υπηρεσιών",
          "sums_insured": "€25 μετά από ένα πλήρες 24ωρο και μετά €25 για κάθε επιπλέον πλήρες 24ωρο έως €250 συνολικά",
          "excess": "Δ/Υ",
          "PolicyTypeID": "1861",
          "visOrder": "26"
        },
        {
          "coverage_id": "243",
          "cover_description": "Καταστροφή",
          "sums_insured": "€ 650",
          "excess": "Δ/Υ",
          "PolicyTypeID": "1861",
          "visOrder": "27"
        }
      ]
    },
    "Silver Single Trip": {
      "QuoteID": 192633,
      "price": 53.54,
      "sell_on_behalf_of": null,
      "SI": "https://asua.terracottatest.com/documents/rclas/TOB - SILVER TRAVEL INSURANCE COMBINED - KUA - 15-09-2021.pdf",
      "PW": "https://asua.terracottatest.com/documents/rclas/EN - Globelink Greek  Policy Wording - COMBINED - KUA - V3 22-11-2021.pdf",
      "KF": "https://asua.terracottatest.com/documents/rclas/TOB - SILVER TRAVEL INSURANCE COMBINED - KUA - 15-09-2021.pdf",
      "IPID": "https://asua.terracottatest.com/documents/rclas/IPID - Silver Cover COMBINED V2 29-07-2021 - KUA.pdf",
      "coverages": [
        {
          "coverage_id": "163",
          "cover_description": "Χρεώσεις Ακύρωσης ή Διακοπής",
          "sums_insured": "Μέχρι € 3.500 στο σύνολο",
          "excess": "€ 65",
          "PolicyTypeID": "1855",
          "visOrder": "1"
        },
        {
          "coverage_id": "164",
          "cover_description": "Επείγοντα Ιατρικά Έξοδα, Έξοδα Επαναπατρισμού και Άλλα Έξοδα",
          "sums_insured": "Μέχρι € 6.000.000 στο σύνολο",
          "excess": "€ 65",
          "PolicyTypeID": "1855",
          "visOrder": "2"
        },
        {
          "coverage_id": "165",
          "cover_description": "Συμπεριλαμβανομένης Οδοντιατρικής Αγωγής",
          "sums_insured": "€ 250",
          "excess": "",
          "PolicyTypeID": "1855",
          "visOrder": "3"
        },
        {
          "coverage_id": "166",
          "cover_description": "Κάλυψη Νοσοκομειακής Περίθαλψης",
          "sums_insured": "€ 25 ανά πλήρες 24ωρο έως €2.500 συνολικά",
          "excess": "0",
          "PolicyTypeID": "1855",
          "visOrder": "4"
        },
        {
          "coverage_id": "167",
          "cover_description": "Προσωπικό Ατύχημα",
          "sums_insured": "",
          "excess": "",
          "PolicyTypeID": "1855",
          "visOrder": "5"
        },
        {
          "coverage_id": "168",
          "cover_description": "Στοιχείο 1 – θάνατος από ατύχημα",
          "sums_insured": "€ 32.000",
          "excess": "0",
          "PolicyTypeID": "1855",
          "visOrder": "6"
        },
        {
          "coverage_id": "169",
          "cover_description": " Στοιχείο 2 – απώλεια άκρου/ων ή απώλεια όρασης",
          "sums_insured": "€ 32.000",
          "excess": "",
          "PolicyTypeID": "1855",
          "visOrder": "7"
        },
        {
          "coverage_id": "170",
          "cover_description": " Στοιχείο 3 – μόνιμη ολική ανικανότητα",
          "sums_insured": "€ 32.000",
          "excess": "",
          "PolicyTypeID": "1855",
          "visOrder": "8"
        },
        {
          "coverage_id": "171",
          "cover_description": "Στοιχείο 1 – Ταξιδιωτικό Ατύχημα - Επιβαίνοντας σε δημόσια συγκοινωνία μόνο",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1855",
          "visOrder": "9"
        },
        {
          "coverage_id": "172",
          "cover_description": "Καθυστέρηση Αναχώρησης",
          "sums_insured": "€ 50 για το πρώτο πλήρες 12ωρο καθυστέρησης και μετά € 25 για κάθε επιπλέον πλήρες 12ωρο έως € 600 συνολικά",
          "excess": "0",
          "PolicyTypeID": "1855",
          "visOrder": "10"
        },
        {
          "coverage_id": "173",
          "cover_description": "Εγκατάλειψη μετά από ένα πλήρες 24ωρο καθυστέρησης",
          "sums_insured": "€ 3.500",
          "excess": "€ 65",
          "PolicyTypeID": "1855",
          "visOrder": "11"
        },
        {
          "coverage_id": "174",
          "cover_description": "Απώλεια αναχώρησης",
          "sums_insured": "Μέχρι € 1.200 στο σύνολο",
          "excess": "€ 65",
          "PolicyTypeID": "1855",
          "visOrder": "12"
        },
        {
          "coverage_id": "175",
          "cover_description": "Αποσκευές ",
          "sums_insured": "Μέχρι € 1.800 στο σύνολο",
          "excess": "€ 65",
          "PolicyTypeID": "1855",
          "visOrder": "13"
        },
        {
          "coverage_id": "176",
          "cover_description": "Όριο για Μονό Αντικείμενο/Ζεύγος/Σετ ",
          "sums_insured": "€ 250",
          "excess": "",
          "PolicyTypeID": "1855",
          "visOrder": "14"
        },
        {
          "coverage_id": "177",
          "cover_description": "Συνολικό Όριο για Τιμαλφή ",
          "sums_insured": "€ 250",
          "excess": "",
          "PolicyTypeID": "1855",
          "visOrder": "15"
        },
        {
          "coverage_id": "178",
          "cover_description": "Όριο για Γυαλιά οράσεως/Ηλίου ",
          "sums_insured": "€ 190",
          "excess": "",
          "PolicyTypeID": "1855",
          "visOrder": "16"
        },
        {
          "coverage_id": "179",
          "cover_description": "Καθυστέρηση Αποσκευών (ελάχιστη καθυστέρηση 12 ώρες)",
          "sums_insured": "€ 150",
          "excess": "",
          "PolicyTypeID": "1855",
          "visOrder": "17"
        },
        {
          "coverage_id": "180",
          "cover_description": "Προσωπικά χρήματα, διαβατήριο & έγγραφα ",
          "sums_insured": "Μέχρι € 900 στο σύνολο",
          "excess": "€ 65",
          "PolicyTypeID": "1855",
          "visOrder": "18"
        },
        {
          "coverage_id": "181",
          "cover_description": "Όριο για Διαβατήριο & Έγγραφα",
          "sums_insured": "€ 300",
          "excess": "",
          "PolicyTypeID": "1855",
          "visOrder": "19"
        },
        {
          "coverage_id": "182",
          "cover_description": "Χρεώσεις Παράτασης Φύλαξης Κατοικίδιων",
          "sums_insured": "€35 ανά ημέρα έως €350 συνολικά",
          "excess": "",
          "PolicyTypeID": "1855",
          "visOrder": "20"
        },
        {
          "coverage_id": "183",
          "cover_description": "Προσωπική Αστική Ευθύνη ",
          "sums_insured": "€ 2.000.000",
          "excess": "€ 400",
          "PolicyTypeID": "1855",
          "visOrder": "21"
        },
        {
          "coverage_id": "184",
          "cover_description": "Όριο για Ενοικιαζόμενο Κατάλυμα",
          "sums_insured": "€ 120.000",
          "excess": "",
          "PolicyTypeID": "1855",
          "visOrder": "22"
        },
        {
          "coverage_id": "185",
          "cover_description": "Νομικές Δαπάνες και Συνδρομή",
          "sums_insured": "Μέχρι € 19.000",
          "excess": "€ 400",
          "PolicyTypeID": "1855",
          "visOrder": "23"
        },
        {
          "coverage_id": "186",
          "cover_description": "Κάλυψη Ληστείας",
          "sums_insured": "€60 για κάθε πλήρες 24ωρο νοσηλείας έως €1.200 συνολικά",
          "excess": "0",
          "PolicyTypeID": "1855",
          "visOrder": "24"
        },
        {
          "coverage_id": "187",
          "cover_description": "Κάλυψη Πειρατείας/Αεροπειρατείας",
          "sums_insured": "€60 για κάθε πλήρες 24ωρο έως €600 συνολικά",
          "excess": "€ 0",
          "PolicyTypeID": "1855",
          "visOrder": "25"
        },
        {
          "coverage_id": "188",
          "cover_description": "Διακοπή Παροχής Υπηρεσιών",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1855",
          "visOrder": "26"
        },
        {
          "coverage_id": "189",
          "cover_description": "Καταστροφή",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1855",
          "visOrder": "27"
        }
      ]
    },
    "Value Single Trip": {
      "QuoteID": 192634,
      "price": 35.86,
      "sell_on_behalf_of": null,
      "SI": "https://asua.terracottatest.com/documents/rclas/TOB - VALUE TRAVEL INSURANCE COMBINED - KUA - 15-09-2021.pdf",
      "PW": "https://asua.terracottatest.com/documents/rclas/EN - Globelink Greek  Policy Wording - COMBINED - KUA - V3 22-11-2021.pdf",
      "KF": "https://asua.terracottatest.com/documents/rclas/TOB - VALUE TRAVEL INSURANCE COMBINED - KUA - 15-09-2021.pdf",
      "IPID": "https://asua.terracottatest.com/documents/rclas/IPID - Value Cover COMBINED V2 29-07-2021 - KUA.pdf",
      "coverages": [
        {
          "coverage_id": "1",
          "cover_description": "Χρεώσεις Ακύρωσης ή Διακοπής",
          "sums_insured": "Μέχρι € 500 στο σύνολο",
          "excess": "€ 65",
          "PolicyTypeID": "1857",
          "visOrder": "1"
        },
        {
          "coverage_id": "2",
          "cover_description": "Επείγοντα Ιατρικά Έξοδα, Έξοδα Επαναπατρισμού και Άλλα Έξοδα",
          "sums_insured": "Μέχρι € 150.000 στο σύνολο",
          "excess": "€ 65 για ηλικία 66 ετών και άνω €250",
          "PolicyTypeID": "1857",
          "visOrder": "2"
        },
        {
          "coverage_id": "3",
          "cover_description": "Συμπεριλαμβανομένης Οδοντιατρικής Αγωγής",
          "sums_insured": "€ 100",
          "excess": "",
          "PolicyTypeID": "1857",
          "visOrder": "3"
        },
        {
          "coverage_id": "4",
          "cover_description": "Κάλυψη Νοσοκομειακής Περίθαλψης",
          "sums_insured": "€20 ανά πλήρες 24ωρο έως €2.000 συνολικά",
          "excess": "0",
          "PolicyTypeID": "1857",
          "visOrder": "4"
        },
        {
          "coverage_id": "5",
          "cover_description": "Προσωπικό Ατύχημα",
          "sums_insured": "",
          "excess": "",
          "PolicyTypeID": "1857",
          "visOrder": "5"
        },
        {
          "coverage_id": "6",
          "cover_description": "Στοιχείο 1 – θάνατος από ατύχημα",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1857",
          "visOrder": "6"
        },
        {
          "coverage_id": "7",
          "cover_description": " Στοιχείο 2 – απώλεια άκρου/ων ή απώλεια όρασης",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1857",
          "visOrder": "7"
        },
        {
          "coverage_id": "8",
          "cover_description": " Στοιχείο 3 – μόνιμη ολική ανικανότητα",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1857",
          "visOrder": "8"
        },
        {
          "coverage_id": "9",
          "cover_description": "Στοιχείο 1 – Ταξιδιωτικό Ατύχημα - Επιβαίνοντας σε δημόσια συγκοινωνία μόνο",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1857",
          "visOrder": "9"
        },
        {
          "coverage_id": "10",
          "cover_description": "Καθυστέρηση Αναχώρησης",
          "sums_insured": "€20 για το πρώτο πλήρες 12ωρο καθυστέρησης και μετά €20 για κάθε επιπλέον πλήρες 12ωρο έως €400 συνολικά",
          "excess": "0",
          "PolicyTypeID": "1857",
          "visOrder": "10"
        },
        {
          "coverage_id": "11",
          "cover_description": "Εγκατάλειψη μετά από ένα πλήρες 24ωρο καθυστέρησης",
          "sums_insured": "€ 500",
          "excess": "€ 65",
          "PolicyTypeID": "1857",
          "visOrder": "11"
        },
        {
          "coverage_id": "12",
          "cover_description": "Απώλεια αναχώρησης",
          "sums_insured": "€ 200",
          "excess": "€ 65",
          "PolicyTypeID": "1857",
          "visOrder": "12"
        },
        {
          "coverage_id": "13",
          "cover_description": "Αποσκευές ",
          "sums_insured": "Μέχρι € 1.000 στο σύνολο",
          "excess": "€ 65",
          "PolicyTypeID": "1857",
          "visOrder": "13"
        },
        {
          "coverage_id": "14",
          "cover_description": "Όριο για Μονό Αντικείμενο/Ζεύγος/Σετ ",
          "sums_insured": "€ 150",
          "excess": "",
          "PolicyTypeID": "1857",
          "visOrder": "14"
        },
        {
          "coverage_id": "15",
          "cover_description": "Συνολικό Όριο για Τιμαλφή ",
          "sums_insured": "€ 150",
          "excess": "",
          "PolicyTypeID": "1857",
          "visOrder": "15"
        },
        {
          "coverage_id": "16",
          "cover_description": "Όριο για Γυαλιά οράσεως/Ηλίου ",
          "sums_insured": "€ 50",
          "excess": "",
          "PolicyTypeID": "1857",
          "visOrder": "16"
        },
        {
          "coverage_id": "17",
          "cover_description": "Καθυστέρηση Αποσκευών (ελάχιστη καθυστέρηση 12 ώρες)",
          "sums_insured": "€ 250",
          "excess": "",
          "PolicyTypeID": "1857",
          "visOrder": "17"
        },
        {
          "coverage_id": "18",
          "cover_description": "Προσωπικά χρήματα, διαβατήριο & έγγραφα ",
          "sums_insured": "Μέχρι € 250 στο σύνολο",
          "excess": "€ 65",
          "PolicyTypeID": "1857",
          "visOrder": "18"
        },
        {
          "coverage_id": "19",
          "cover_description": "Όριο για Διαβατήριο & Έγγραφα",
          "sums_insured": "",
          "excess": "",
          "PolicyTypeID": "1857",
          "visOrder": "19"
        },
        {
          "coverage_id": "20",
          "cover_description": "Χρεώσεις Παράτασης Φύλαξης Κατοικίδιων",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1857",
          "visOrder": "20"
        },
        {
          "coverage_id": "21",
          "cover_description": "Προσωπική Αστική Ευθύνη ",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1857",
          "visOrder": "21"
        },
        {
          "coverage_id": "22",
          "cover_description": "Όριο για Ενοικιαζόμενο Κατάλυμα",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1857",
          "visOrder": "22"
        },
        {
          "coverage_id": "23",
          "cover_description": "Νομικές Δαπάνες και Συνδρομή",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1857",
          "visOrder": "23"
        },
        {
          "coverage_id": "24",
          "cover_description": "Κάλυψη Ληστείας",
          "sums_insured": "€50 για κάθε πλήρες 24ωρο νοσηλείας έως €1.000 συνολικά",
          "excess": "0",
          "PolicyTypeID": "1857",
          "visOrder": "24"
        },
        {
          "coverage_id": "25",
          "cover_description": "Κάλυψη Πειρατείας/Αεροπειρατείας",
          "sums_insured": "€50 για κάθε πλήρες 24ωρο έως €500 συνολικά",
          "excess": "€ 0",
          "PolicyTypeID": "1857",
          "visOrder": "25"
        },
        {
          "coverage_id": "26",
          "cover_description": "Διακοπή Παροχής Υπηρεσιών",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1857",
          "visOrder": "26"
        },
        {
          "coverage_id": "27",
          "cover_description": "Καταστροφή",
          "sums_insured": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "excess": "ΔΕΝ ΚΑΛΥΠΤΕΤΑΙ",
          "PolicyTypeID": "1857",
          "visOrder": "27"
        }
      ]
    }
  }