<template>

</template>

<script>

import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button } from '@progress/kendo-vue-buttons';
import { process } from '@progress/kendo-data-query';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
import { DropDownList } from '@progress/kendo-vue-dropdowns';
import { toRaw } from 'vue'

import '@progress/kendo-theme-default/dist/all.css'


export default {
    name: 'ReportsView',
    inject: [ '$appConfig' ],
    components: {
        'k-grid': Grid,
        'k-grid-toolbar': GridToolbar,
        'k-button': Button,
        'k-dialog': Dialog,
        'k-dialog-actions-bar': DialogActionsBar,  
        'k-dropdownlist': DropDownList
    },
    mounted() {
        this.loadData()
    },

    methods : {
        loadData() {
            const url = this.productionReportUrl.href;

            this.axios.get(
                url,
                {
                    headers: { Authorization: 'Bearer ' + this.$store.state.token }
                }
            )
        }
    },

    computed: {
        productionReportUrl() {
            return new URL(this.$appConfig.API_PRODUCTION_REPORT_URL, this.$appConfig.API_BASE_URL);
        }
    }
}

</script>