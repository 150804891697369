<template>
  <!--------------------//v1.0.0.1 Μείωση του width της Νέας Προσφοράς Ταξιδιωτικής ασφάλισης ----------------------->
  <div class="flex flex-col">
    <div class="flex flex-row p-4">
      <k-form
        ref="form"
        class="form-element"
        :initial-values="initialValues"
        :horizontal="true"
      >
        <custom-card
          title="Νέο Συμβόλαιο Ασφάλισης Κατοικίας"
          extraClass="w-3/4 h-full"
        >
          <div>
    <!--------------------//v1.0.0.1 Μείωση του width της Νέας Προσφοράς Ταξιδιωτικής ασφάλισης ----------------------->
            <k-stepper
              :class="'my-8'"
              :value="step"
              :items="steps"
              @change="stepperChange"
            />
		   <step-one
			  v-if="step === 0"
			  @onChange="stepContentChanged"
			  :token="token"
			  :policy-details-prop="policyDetailsProp"
			/>
			<step-two v-if="step === 1" :policy-details-prop="policyDetailsProp" />
           

            <span class="k-form-separator mt-8"></span>
            <div
              class="text-center content-center justify-center k-form-buttons"
            >
              <span>
				  <k-button
					class="w-20"
					:theme-color="'primary'"
					@click="nextSubmitButtonClick"
					:disabled="disableNextStep"
					v-if="isLastStep == false"
				  >
					{{ isLastStep ? "Αποστολή" : "Επόμενο" }}
				  </k-button>
              </span>
            </div>
            <!--------------------//v1.0.0.1 Μείωση του width της Νέας Προσφοράς Ταξιδιωτικής ασφάλισης ----------------------->
          </div>
        </custom-card>
        <Property_NewPolicyRequestSummary  :policy-details-prop="policyDetailsProp"/>
      </k-form>
    </div>
  </div>
  <!--------------------//v1.0.0.1 Μείωση του width της Νέας Προσφοράς Ταξιδιωτικής ασφάλισης ----------------------->
</template>
<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Stepper } from "@progress/kendo-vue-layout";
import { Form } from "@progress/kendo-vue-form";

import StepOne from "@/components/property/NewPolicyRequest/NewPolicyRequestStepOne.vue";
import StepTwo from "@/components/property/NewPolicyRequest/NewPolicyRequestStepTwo.vue";
import Property_NewPolicyRequestSummary from "@/components/property/NewPolicyRequest/NewPropertyPolicyRequestSummary.vue";
import CustomCard from "../../CustomCard.vue";
import { mapActions } from "vuex";

export default {
  components: {
    "k-button": Button,
    "k-stepper": Stepper,
    "k-form": Form,
    CustomCard,

    StepOne,
    StepTwo,
    "Property_NewPolicyRequestSummary": Property_NewPolicyRequestSummary,
  },

  props: ["policyDetailsProp"],

  data: function () {
    return {
      step: 0,

      initialValues: {
        apiToken: "1a784a71a160ff4ab12ac7d323293522",
      },

      disableNextStep: false,
    };
  },
  ...mapActions(["timeoutTimerRestart"]),

  mounted() {
   //   console.log("New Policy Request Wizard this.policyDetailsProp")
   //   console.log(this.policyDetailsProp)
  },

  computed: {
    token() {
      return this.initialValues.apiToken;
    },

    steps() {
      return [
        {
          label: "Αποδοχή όρων",
          componentName: "StepOne",
        },
        {
          label: "Αναλυτικά στοιχεία",
          componentName: "StepOne",
        },
      ];
    },

    isLastStep() {
      return this.step == this.steps.length - 1;
    },
  },

  methods: {
    stepperChange(event) {
      const nextStep = event.value;

      if (nextStep > this.step && this.disableNextStep) return;

      this.step = nextStep;
    },

    nextSubmitButtonClick() {
      this.formErrors = this.$refs.form.form.errors;
      if (this.isLastStep) {
        this.showErrors = false;
        const formValues = this.$refs.form.form.values;
        for (const property in formValues) {
          if (formValues[property] === null || formValues[property] === "") {
            this.showErrors = true;
          }
        }
        if (!this.showErrors) {
          alert(JSON.stringify(this.$refs.form.form.values, null, 2));
        }
      } else {
        this.step += 1;
        this.disableNextStep = true;
      }
    },

    stepContentChanged(event) {
      // console.log('caught it')
      this.disableNextStep = !event.stepValid;
    },
  },
};
</script>
