<template>
  <nav class="bg-gray-300">
    <div>
      <div class="relative flex h-10 items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <button
            type="button"
            class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <!--
            Icon when menu is closed.

            Menu open: "hidden", Menu closed: "block"
          -->
            <svg
              class="block h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
            <!--
            Icon when menu is open.

            Menu open: "block", Menu closed: "hidden"
          -->
            <svg
              class="hidden h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div
          class="items-center justify-center sm:items-stretch sm:justify-start"
        >
          <div class="flex flex-shrink-0 items-center">
            <router-link to="/">
              <img src="/kualogo.png" class="w-40" alt="Avatar" />
            </router-link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8 ml-4 mt-11"
              @click="$emit('hideit')"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
            <span class="pt-12 pl-2 font-semibold"
              >ΚΑΡΑΒΙΑΣ ΑΣΦΑΛΙΣΤΙΚΟΙ ΠΡΑΚΤΟΡΕΣ ΙΚΕ</span
            >
            <!-- <span class="pt-12 pl-2 font-semibold"
              >{{ process.env.COMPANY_NAME }}</span
            > -->

            <!-- <svg
              v-if="isClicked == true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8 ml-4 mt-11"
              @click="
                $emit('showit');
                test();
              "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg> -->

            <!-- <img
              class="block h-8 w-auto lg:hidden"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
              alt="Your Company"
            />
            <img
              class="hidden h-8 w-auto lg:block"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
              alt="Your Company"
            /> -->
          </div>

          <!-- <div class="hidden sm:ml-6 sm:block">
            <div class="flex space-x-4">
              Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white"
              <a
                href="#"
                class="bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium"
                aria-current="page"
                >Dashboard</a
              >
              <a
                href="#"
                class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >Team</a
              >
              <a
                href="#"
                class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >Projects</a
              >
              <a
                href="#"
                class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >Calendar</a
              >
            </div>
          </div> -->
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <!-- <button
            type="button"
            class="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
          >
            <span class="sr-only">View notifications</span>
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
              />
            </svg>
          </button> -->

          <!-- Profile dropdown -->
          <div ref="userWrapper" class="pr-10">
            <ul class="relative px-1">
              <li class="relative">
                <span class="text-sm"
                  >{{ greeting }},
                  <span class="text font-bold">{{ userFullName }}</span></span
                >
              </li>
              <li class="relative">
                <span class="text-sm"
                  ><span class="text font-bold">({{ user }})</span></span
                >
              </li>
            </ul>
          </div>
          <div class="relative pr-3">
            <div>
              <button
                type="button"
                class="flex rounded-full text-sm focus:outline-none"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                @click.prevent="switchVisibility"
                @blur="isVisible = false"
              >
                <span class="sr-only">Open user menu</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-8 h-8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            </div>

            <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->
            <div
              class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
              v-show="isVisible"
            >
              <ul>
                <li class="relative">
                  <a
                    href="/useraccount"
                    role="menuitem"
                    tabindex="-1"
                    @mousedown="goToUser"
                  >
                    Ρυθμίσεις Λογαριασμού
                  </a>
                  <!-- <div href="#!">
                    <router-link to="/useraccount"
                      >Ρυθμίσεις Λογαριασμού</router-link
                    >
                  </div> -->
                </li>
                <!-- <button @click="goToUser()">Ρυθμίσεις λογαριασμού</button> -->
                <!-- <a href="/useraccount">Ρυθμίσεις λογαριασμού</a> -->

                <!-- <router-link to="/useraccount" class=""> -->

                <!--
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
              -->

                <!-- </router-link> -->

                <li>
                  <a
                    href="/logout"
                    class="block px-4 py-2 text-sm"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-2"
                    @mousedown="performLogOut"
                  >
                    <span class="text-sm">
                      Αποσύνδεση
                      {{
                        new Date(autoLogoutSecondsValue * 1000)
                          .toISOString()
                          .substr(14, 5)
                      }}
                    </span></a
                  >
                </li>
              </ul>

              <!-- Active: "bg-gray-100", Not Active: "" -->

              <!-- <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                >Ρυθμίσεις Λογαριασμού
              </a> -->
              <!-- <a
                href="/logout"
                class="block px-4 py-2 text-sm text-gray-700"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-2"
                @click.prevent="performLogOut"
              >
                <span class="text-sm text-bold">
                  Αποσύνδεση
                  {{
                    new Date(autoLogoutSecondsValue * 1000)
                      .toISOString()
                      .substr(14, 5)
                  }}
                </span></a
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref } from "vue";
import { mapActions } from "vuex";
import jwt_decode from "jwt-decode";

export default {
  mounted() {
    // console.log(this.$appConfig)
    const now = new Date();

    const currentHour = now.getHours();

    if (currentHour >= 5 && currentHour <= 12) this.greeting = "Καλημέρα";
    else if (currentHour >= 2 && currentHour <= 4) this.greeting = "Γερμανικό";
    else this.greeting = "Καλησπέρα";
  },
  data() {
    return {
      greeting: null,
      isVisible: false,
      isClicked: true,
      test1: "test",
    };
  },
  inject: ["$appConfig"],
  // setup() {
  //   let showMenu = ref(false);
  //   let show = ref(false);
  //   const toggleNav = () => (showMenu.value = !showMenu.value);
  //   return { showMenu, show, toggleNav };
  // },

  props: ["autoLogoutSeconds"],
  computed: {
    autoLogoutSecondsValue() {
      let seconds = this.autoLogoutSeconds;
      return seconds;
    },

    user() {
      return this.$store.state.user;
    },

    userFullName() {
      return this.$store.state.userFullName;
    },
  },
  methods: {
    goToUser() {
      // console.log(this.test1);
      // alert("test");
      this.$router.push("/useraccount");
    },
    async performLogOut() {
      this.logOut();
    },
    test() {
      this.isClicked = !this.isClicked;
      // console.log(this.isClicked);
    },

    ...mapActions(["logOut"]),

    switchVisibility() {
      this.isVisible = !this.isVisible;
      // if (this.isVisible == true) {
      //   this.widthSize = "240px";
      // } else {
      //   this.widthSize = "30px";
      // }
    },
    //   async performLogOut() {
    //     // console.log('loggingout')
    //     this.logOut();
    //     // console.log('loggeddddout')
    //   },
    //   ...mapActions(["logOut"]),
    //   userHasRole(roleName) {
    //     const rolePropertyName =
    //       "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
    //     let jwtDecode = jwt_decode(this.$store.state.token);
    //     let role = jwtDecode[rolePropertyName];
    //     return role === roleName;
    //   },
    // },
    // computed: {
    //   canAccessUsers() {
    //     return this.userHasRole("KUA_ADMIN");
    //   },
    //   canAccessPortfolios() {
    //     return this.userHasRole("NN_ADMIN");
    //   },
    //   canAccessReports() {
    //     return this.userHasRole("SIMPLE_USER") || this.userHasRole("NN_ADMIN");
    //   },
    //   user() {
    //     return this.$store.state.user;
    //   },
    //   autoLogout() {
    //     let seconds = this.autoLogoutSeconds;
    //     return seconds;
    //   },
  },
};
</script>
